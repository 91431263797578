import React, {useEffect, useState} from "react";
import {Button, Card, Checkbox, Form, Input, message, Select, Skeleton, Space} from "antd";
import {Link} from "react-router-dom";
import axios from "../../conf/axios";
import URI from "urijs";
import App from "../../libs/App";

export default props => {
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState({});
  const [companys, setCompanys] = useState([]);
  const [roles, setRoles] = useState([]);
  const [auths, setAuths] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    let params = URI().search(true);

    axios.get('/userRole/getUserRoles')
      .then(response => {
        let roles = response.data.data.userRoles.map(role => ({
          id: role.id,
          value: role.name,
          authId: role.authId,
        }));
        setRoles(roles);
      })
      .then(response => {
        return axios.get('/userAuth/getUserAuths',{params: {userId: App.user.id}})
          .then(response => {
            let userAuths = response.data.data.userAuths;
            userAuths = userAuths.map(userAuth => {
              return userAuth;
            });
            console.log(userAuths);
            setAuths(userAuths);
          });
      })
      .then(response => {
        if (params.id) {
          return axios.get('/user/getUser', {params: {id: params.id}})
            .then(response => {
              let record = response.data.data.user;
              setRecord(record);
              form.setFieldsValue(record);
            });
        } else {
          return Promise.resolve();
        }
      })
      .then(response => {
        if (App.user.isAdmin) {
          return axios.get('/company/getCompanys')
            .then(response => {
              let companys = response.data.data.companys.map(company => ({
                id: company.id,
                value: company.name,
              }));
              setCompanys(companys);
            });
        } else {
          return Promise.resolve();
        }
      })
      .then(response => {
        setLoading(false);
      })
      .catch(error => {
        message.error(error.message);
      });
  }, []);

  useEffect(() => {
    setAuths(auths.map(item => {
      if (item.id == 'company') {
        item.disabled = record.roleId != 'admin'
      }
      return item;
    }));
    roles.forEach(role => {
      if (role.id == record.roleId) {
        setAuths(resolve(role.authId));
      }
    });
  }, [record.roleId]);

  useEffect(() => {
    setAuths(resolve(record.authId));
  }, [record.authId]);

  const resolve = authId => {
    return auths.map(auth => {
      auth.checked = authId.indexOf(auth.id) != -1;
      if (auth.subs.length > 0) {
        let checkedCount = 0;
        auth.subs = auth.subs.map((auth2) => {
          auth2.checked = authId.indexOf(auth2.id) != -1;
          if (auth2.checked) {
            checkedCount++;
          }
          return auth2;
        });
        let checked = checkedCount == auth.subs.length;
        if (checked) {
          auth.checked = true;
          auth.indeterminate = false;
        } else if (checkedCount || auth.checked) {
          auth.checked = true;
          auth.indeterminate = true;
        } else {
          auth.checked = false;
          auth.indeterminate = null;
        }
      }
      return auth;
    });
  }

  const save = values => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    axios.post(values.id ? '/user/setUser' : '/user/addUser', values)
      .finally(() => {
        setSubmitting(false);
      })
      .then(response => {
        message.success('保存成功');
        props.history.push('/user');
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  return (
    <Card title="人员信息" extra={<Button type="primary"><Link to="/user">返回列表</Link></Button>}>
      {
        loading ? <Skeleton active/> : (
          <div style={{width: 768}}>
            <Form
              form={form}
              labelCol={{span: 2}}
              onValuesChange={changedValues => {
                setRecord(Object.assign({}, record, changedValues));
              }}
              onFinish={values => {
                values.authId = [];
                auths.forEach(auth => {
                  if (auth.checked) {
                    values.authId.push(auth.id);
                  }
                  auth.subs.forEach((auth2) => {
                    if (auth2.checked) {
                      values.authId.push(auth2.id);
                    }
                  });
                });
                save(values);
              }}>
              <Form.Item name="id" label="id" hidden={true}>
                <Input placeholder="id"/>
              </Form.Item>
              <Form.Item name="name" label="姓名" rules={[{required: true}]}>
                <Input placeholder="姓名" style={{width: 250}}/>
              </Form.Item>
              <Form.Item name="phone" label="手机" rules={[{required: true}]}>
                <Input placeholder="手机" style={{width: 250}}/>
              </Form.Item>
              <Form.Item name="password" label="密码" rules={[{required: !record.id}]}>
                <Input placeholder="密码" style={{width: 250}}/>
              </Form.Item>
              {
                App.user.isAdmin ? (
                  <>
                    <Form.Item name="companyId" label="单位">
                      <Select style={{width: 300}} placeholder="选择单位">
                        {companys.map(value => <Select.Option value={value.id}>{value.value}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </>
                ) : null
              }
              <Form.Item name="roleId" label="角色" rules={[{required: true}]}>
                <Select style={{width: 200}} placeholder="选择角色">
                  {roles.map(value => <Select.Option value={value.id}>{value.value}</Select.Option>)}
                </Select>
              </Form.Item>
              {
               /* App.user.isAdmin ? (*/
                  <>
                    <Form.Item name="roleId" label="权限">
                      {
                        auths.map(auth => {
                          return auth.disabled ? null : (
                            <div style={{margin: 10}}>
                              <Checkbox
                                name="authId[]"
                                value={auth.id}
                                checked={auth.checked}
                                indeterminate={auth.indeterminate}
                                onChange={e => {
                                  let tmp = auths.map(item => {
                                    if (item.id == auth.id) {
                                      if (item.indeterminate === false) {
                                        item.checked = false;
                                        item.indeterminate = null;
                                      } else {
                                        item.checked = true;
                                        item.indeterminate = false;
                                      }
                                      item.subs = item.subs.map((item2) => {
                                        item2.checked = item.checked && item.indeterminate === false;
                                        return item2;
                                      });
                                    }
                                    return item;
                                  });
                                  setAuths(tmp);
                                }}
                              >
                                {auth.name}
                              </Checkbox>
                              {
                                auth.subs.map((auth2) => {
                                  return (
                                    <>
                                      <Checkbox
                                        name="authId[]"
                                        value={auth2.id} checked={auth2.checked}
                                        onChange={e => {
                                          let tmp = auths.map(item => {
                                            if (item.id == auth.id) {
                                              let checkedCount = 0;
                                              item.subs = item.subs.map((item2) => {
                                                if (item2.id == auth2.id) {
                                                  item2.checked = e.target.checked;
                                                }
                                                if (item2.checked) {
                                                  checkedCount++;
                                                }
                                                return item2;
                                              });
                                              let checked = checkedCount == item.subs.length;
                                              if (checked) {
                                                item.checked = true;
                                                item.indeterminate = false;
                                              } else if (checkedCount) {
                                                item.checked = true;
                                                item.indeterminate = true;
                                              } else {
                                                item.checked = false;
                                                item.indeterminate = null;
                                              }
                                            }
                                            return item;
                                          });
                                          setAuths(tmp);
                                        }}
                                      >
                                        {auth2.name}
                                      </Checkbox>
                                    </>
                                  )
                                })
                              }
                            </div>
                          )
                        })
                      }
                    </Form.Item>
                  </>
                /*) : null*/
              }
              <Form.Item wrapperCol={{offset: 2}}>
                <Button type="primary" size="large" htmlType="submit" loading={submitting}>保存</Button>
              </Form.Item>
            </Form>
          </div>
        )
      }
    </Card>
  );
};