import axios from "axios";
import Token from "../libs/Auth";
import Qs from "qs";
import {apiUrl} from "../env";

const createAxios = axios.create({
  baseURL: apiUrl,
});

createAxios.interceptors.request.use(
  config => {
    config.paramsSerializer = params => {
      return Qs.stringify(params, {arrayFormat: 'indices'})
    }
    let token = Token.getToken();
    if (token) {
      config.headers = {
        'Token': token,
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

createAxios.interceptors.response.use(
  response => {
    let data = response.data;
    if (data) {
      if (data.status === 0) {
        return response;
      }
    }
    let error = new Error(data.msg);
    error.code = data.status;
    return Promise.reject(error);
  }
);

export default createAxios;