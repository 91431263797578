import Login from "../pages/Login";
import Index from "../pages/Index";
import Index2 from "../pages/index/Index";
import Test from "../pages/Test";
import Company from "../pages/company/Index";
import User from "../pages/user/Index";
import UserEdit from "../pages/user/Edit";
import Device from "../pages/device/Index";
import DeviceModel from "../pages/deviceModel/Index";
import DeviceFault from "../pages/deviceFault/Index";
import Project from "../pages/project/Index";
import ProjectEdit from "../pages/project/Edit";
import ProjectDetail from "../pages/project/Detail";
import ProjectBase from "../pages/project/Base";
import ProjectMap from "../pages/project/Map";
import ProjectDetailPipeline from "../pages/project/Pipeline";
import ProjectDetailScheme from "../pages/project/Scheme";
import ProjectDetailCalibrat from "../pages/project/Calibrate";
import DataChartPreview from "../pages/project/DataChartPreview";
import RawData from "../pages/project/RawData";
import Report from "../pages/project/Report";
import ProjectDetailDataChart from "../pages/project/DataChart";
import ProjectDetailMisspoint from "../pages/project/Misspoint";
import ProjectDetailImg from "../pages/project/Img";
import ProjectDetailVideo from "../pages/project/Video";
import ProjectScheme from "../pages/projectScheme/Index";
import Statistics from "../pages/statistics/Index";
import StatisticsDefault from "../pages/statistics/Default";
import StatisticsUser from "../pages/statistics/User";
import AppVersion from "../pages/appVersion/Index";
import AppConfig from "../pages/appConfig/Index";
import Ad from "../pages/ad/Index";
import Banner from "../pages/banner/Index";
import Feedback from "../pages/feedback/Index";
import Password from "../pages/my/Password";
import Profile from "../pages/my/Profile";

export const routes = [
  {
    path: '/login',
    component: Login,
    title: '登录'
  },
  {
    path: '/',
    component: Index,
    title: 'pipehome'
  },
];

export const routes2 = [
  {
    path: '/',
    component: Index2,
    title: '首页',
    exact: true,
  },
  {
    path: '/test',
    component: Test,
    title: 'test'
  },
  {
    path: '/company',
    component: Company,
    title: '单位信息'
  },
  {
    path: '/user',
    component: User,
    title: '人员信息',
    exact: true,
  },
  {
    path: '/user/add',
    component: UserEdit,
    title: '添加人员'
  },
  {
    path: '/user/edit',
    component: UserEdit,
    title: '编辑人员'
  },
  {
    path: '/device',
    component: Device,
    title: '设备管理'
  },
  {
    path: '/project',
    component: Project,
    title: '工作管理',
    exact: true,
  },
  {
    path: '/project/add',
    component: ProjectEdit,
    title: '添加工作'
  },
  {
    path: '/project/edit',
    component: ProjectEdit,
    title: '编辑工作'
  },
  {
    path: '/project/detail',
    component: ProjectDetail,
    title: '工作详情'
  },
  {
    path: '/statistics',
    component: Statistics,
    title: '统计'
  },
  {
    path: '/deviceFault',
    component: DeviceFault,
    title: '设备型号'
  },
  {
    path: '/deviceModel',
    component: DeviceModel,
    title: '设备型号'
  },
  {
    path: '/projectScheme',
    component: ProjectScheme,
    title: '方案模版'
  },
  {
    path: '/appVersion',
    component: AppVersion,
    title: 'app版本'
  },
  {
    path: '/appConfig',
    component: AppConfig,
    title: 'app配置'
  },
  {
    path: '/ad',
    component: Ad,
    title: '广告图'
  },
  {
    path: '/banner',
    component: Banner,
    title: '轮播图'
  },
  {
    path: '/feedback',
    component: Feedback,
    title: '反馈'
  },
  {
    path: '/password',
    component: Password,
    title: '修改密码'
  },
  {
    path: '/profile',
    component: Profile,
    title: '基本资料'
  },
];

export const projectDetailRoutes = [
  {
    path: '/project/detail',
    component: ProjectBase,
    title: '基本信息',
    exact: true,
  },
  {
    path: '/project/detail/map',
    component: ProjectMap,
    title: '地图'
  },
  {
    path: '/project/detail/pipeline',
    component: ProjectDetailPipeline,
    title: '管线信息'
  },
  {
    path: '/project/detail/scheme',
    component: ProjectDetailScheme,
    title: '方案信息'
  },
  {
    path: '/project/detail/calibrate',
    component: ProjectDetailCalibrat,
    title: '标定信息'
  },
  {
    path: '/project/detail/dataChartPreview',
    component: DataChartPreview,
    title: '数据图表预览'
  },
  {
    path: '/project/detail/rawData',
    component: RawData,
    title: '原始数据'
  },
   {
    path: '/project/detail/report',
    component: Report,
    title: '检测报告'
  },
  {
    path: '/project/detail/dataChart',
    component: ProjectDetailDataChart,
    title: '数据图表'
  },
  {
    path: '/project/detail/misspoint',
    component: ProjectDetailMisspoint,
    title: '漏点'
  },
  {
    path: '/project/detail/img',
    component: ProjectDetailImg,
    title: '图片'
  },
  {
    path: '/project/detail/video',
    component: ProjectDetailVideo,
    title: '视频'
  }
];

export const statisticsRoutes = [
  {
    path: '/statistics',
    component: StatisticsDefault,
    title: '设备统计',
    exact: true,
  },
  {
    path: '/statistics/user',
    component: StatisticsUser,
    title: '用户统计',
  }
]