import {createStore} from 'redux';

const defaultState = {}
const reducer = (state = defaultState, action) => {
  if (action.data) {
    return Object.assign(state, action.data);
  }
  return state;
}

export default createStore(reducer);