import React, {useEffect, useState} from "react";
import {Button, Form, Image, Input, InputNumber, message, Skeleton, Space} from "antd";
import axios from "../../conf/axios";
import {imgPlaceholder} from "../../conf/general";
import FileInput from "../../components/ant/FileInput";
import App from "../../libs/App";

export default ({project}) => {
  const [loading, setLoading] = useState(true);
  const [calibrate, setCalibrate] = useState({});
  const load = () => {
    setLoading(true);
    axios.post('/project/getCalibrate', {id: project.id})
      .then(response => {
        let {calibrate} = response.data.data;
        setCalibrate(calibrate);
        setLoading(false);
      })
      .catch(error => {
        message.error(error.message);
      });
  }
  useEffect(() => {
    load();
  }, []);

  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const save = values => {
    if (saving) {
      return true;
    }
    setSaving(true);
    var formData = new FormData();
    for (let key in values) {
      let value = values[key];
      if (value != undefined) {
        formData.append(key, value);
      }
    }
    axios.post('/project/setCalibrate', formData)
      .finally(() => {
        setSaving(false);
      })
      .then(response => {
        let {calibrate} = response.data.data;
        setCalibrate(calibrate);
        form.resetFields();
        form.setFieldsValue(calibrate);
        message.success('保存成功');
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  return (
    <div className="projectCalibrate" style={{width: 1024}}>
      {
        loading ? <Skeleton active/> : (
          <Form className="ant-form-table" form={form} onFinish={save}>
            <Form.Item name="id" label="id" hidden={true}>
              <Input placeholder="id"/>
            </Form.Item>
            <table className="table table-bordered">
              <tr>
                <td width="125">电压：</td>
                <td>
                  {
                    !editing ? calibrate.voltage : (
                      <Form.Item name="voltage">
                        <Input placeholder="电压" style={{width: 300}}/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>频率：</td>
                <td>
                  {
                    !editing ? calibrate.frequency : (
                      <Form.Item name="frequency">
                        <InputNumber placeholder="频率" style={{width: 125}}/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>系数：</td>
                <td>
                  {
                    !editing ? calibrate.coefficient : (
                      <Form.Item name="coefficient">
                        <InputNumber placeholder="系数" style={{width: 125}}/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>采样率：</td>
                <td>
                  {
                    !editing ? calibrate.coefficient : (
                      <Form.Item name="samplingRate">
                        <InputNumber placeholder="采样率" style={{width: 125}}/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>初始化：</td>
                <td>
                  {
                    !editing ? calibrate.initialization : (
                      <Form.Item name="initialization">
                        <Input placeholder="初始化" style={{width: 300}}/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>放大增益：</td>
                <td>
                  {
                    !editing ? calibrate.amplifyGain : (
                      <Form.Item name="amplifyGain">
                        <Input placeholder="放大增益" style={{width: 300}}/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>最大腐蚀深度：</td>
                <td>
                  {
                    !editing ? calibrate.maxCorrosionDepth : (
                      <Form.Item name="maxCorrosionDepth">
                        <Input placeholder="最大腐蚀深度" style={{width: 300}}/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>图片：</td>
                <td>
                  <div className="imgs clearfix">
                    {
                      [0, 1, 2, 3, 4, 5].map(index => (
                        <div className={'imgItem' + (editing ? ' editing' : ' ')}>
                          <div className="imgContainer">
                            {
                              calibrate.imgs && calibrate.imgs[index] ? (
                                <Image src={calibrate.imgs[index]} fallback={imgPlaceholder}/>
                              ) : null
                            }
                          </div>
                          {
                            editing ? (
                              <Form.Item name={'img' + index} style={{marginTop: 10}}>
                                <FileInput/>
                              </Form.Item>
                            ) : null
                          }
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
            </table>
            <div style={{marginTop: 10, textAlign: 'center'}}>
              {
                !editing && App.hasAuth('project.setProject') ? (
                  <Button type="primary" onClick={() => {
                    form.resetFields();
                    form.setFieldsValue(calibrate);
                    setEditing(true);
                  }}>编辑</Button>
                ) : null
              }
              {
                editing ? (
                  <Space>
                    <Button type="primary" htmlType="submit" loading={saving}>保存</Button>
                    <Button type="ghost" onClick={() => setEditing(false)}>返回</Button>
                  </Space>
                ) : null
              }
            </div>
          </Form>
        )
      }
    </div>
  );
};