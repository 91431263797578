import {Radio, Badge, Button, Form, Input, InputNumber, message, Modal, Pagination, Select, Space, Spin, Switch, Dropdown, Menu, Progress, Result, Card, Empty, Tag, Tooltip, Alert} from "antd";
import React, {useEffect, useRef, useState} from "react";
import axios from "../../conf/axios";
import html2canvas from "html2canvas";
import FormItemTriggers from "./../../components/ant/Form";
import redux from "./redux";
import App from "../../libs/App";
import {useLocalStorageState, useSize} from "ahooks";
import {useUpdateEffect} from "react-use";
import {LoadingOutlined, SearchOutlined} from '@ant-design/icons';
import echarts from "echarts";

export default ({project}) => {
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [calibrate, setCalibrate] = useState({});
  const [inspect, setInspect] = useState({});
  const [form] = Form.useForm();
  const load = () => {
    if (fetching) {
      return;
    }
    setFetching(true);
    axios.post('/project/getDataChart', {
      id: project.id,
      pageSize: pagination.pageSize,
      currentPage: pagination.currentPage,
    })
      .finally(() => {
        setLoading(false);
      })
      .then(response => {
        let {pagination, datas: datas, inspect, calibrate, start, end} = response.data.data;
        setInspect(inspect);
        setCalibrate(calibrate);
        let tmpDatas = [];
        datas.forEach((data, index) => {
          tmpDatas.push({
            index: index + start,
            a: data[0],
            b: data[1],
          });
        });
        let tmpData = {
          wallThicknessRatio: data.wallThicknessRatio ? data.wallThicknessRatio : inspect.wallThicknessRatio,
          timeRatio: data.timeRatio ? data.timeRatio : inspect.timeRatio,
          max: data.max ? data.max : calibrate.frequency,
          min: data.min ? data.min : -calibrate.frequency,
          start: start,
          end: end,
        };
        setDatas(tmpDatas);
        setData(data => {
          return {...data, ...tmpData};
        });
        form.setFieldsValue(tmpData);
        setPagination((oldPagination) => {
          return {
            ...oldPagination,
            currentPage: pagination.currentPage,
            pageSize: pagination.perPage,
            totalCount: pagination.totalCount,
          }
        });
        setFetching(false);
      })
      .catch(error => {
        setFetching(false);
        message.error(error.message);
      });
  }
  useEffect(() => {
    load();
  }, []);

  const view = useRef();
  const viewSize = useSize(view);

  const [datas, setDatas] = useState([]);
  const [data, setData] = useState({});
  const [mainChart, setMainChart] = useState();
  const [rendering, setRendering] = useState(false);
  useEffect(() => {
    setMainChart(echarts.init(document.getElementById('mainChart')));
  }, []);
  const filter = data => {
    if (data.length == 0) {
      return data;
    }
    let newData = [];
    for (let i = 0; i < data.length; i++) {
      if (i >= data.length - 1 - 2) {
        newData.push(data[i]);
      } else {
        let tmp = [data[i], data[i + 1], data[i + 2]];
        tmp.sort((a, b) => {
          return a - b;
        });
        newData.push(tmp[1]);
      }
    }
    return newData;
  }
  const chartRender = (args = {}) => {
    if (Object.keys(data).length == 0) {
      return;
    }
    setRendering(true);
    let currentChart = args.chart ? args.chart : mainChart;
    let currentDatas = args.datas ? args.datas : datas;
    let data1 = currentDatas.map(value => {
      return (value.a * data.wallThicknessRatio).toFixed(3) * 1;
    });
    let data2 = currentDatas.map(value => {
      return (value.b * data.wallThicknessRatio).toFixed(3) * 1;
    });
    if (data.filter) {
      data1 = filter(data1);
      data2 = filter(data2);
    }
    const option = {
      title: {
        text: '数据曲线图' + (args.index ? args.index : pagination.currentPage),
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['壁厚一', '壁厚二']
      },
      grid: {
        left: '50px',
        right: '25px',
        bottom: '75px'
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: currentDatas.map(value => {
          return (value.index * data.timeRatio).toFixed(3) * 1;
        }),
      },
      yAxis: {
        type: 'value',
        max: data.max * data.wallThicknessRatio,
        min: data.min * data.wallThicknessRatio,
      },
      series: [
        {
          name: '壁厚一',
          type: 'line',
          data: data1,
        },
        {
          name: '壁厚二',
          type: 'line',
          data: data2,
        },
      ],
      dataZoom: [
        {
          type: 'inside',
          startValue: currentDatas.length > 0 ? currentDatas[0].index : 0,
          endValue: currentDatas.length > 0 ? ((currentDatas[currentDatas.length - 1].index * data.timeRatio).toFixed(3) * 1) : 0,
        },
        {
          show: true,
          type: 'slider',
        }
      ],
      animation: false
    }
    currentChart.setOption(option);
    setRendering(false);
  }
  useUpdateEffect(() => {
    chartRender();
  }, [datas, data.wallThicknessRatio, data.timeRatio, data.filter, data.max, data.min]);
  useUpdateEffect(() => {
    mainChart.resize();
  }, [viewSize]);

  const defaultPagination = {
    currentPage: 1,
    pageSize: 5000,
    totalCount: 0,
  }
  const [pagination, setPagination] = useState(defaultPagination);
  useUpdateEffect(() => {
    load();
  }, [pagination]);

  const [markers, setMarkers] = useState([]);
  const [hasMarker, setHasMarker] = useState(false);
  const onDoubleClick = e => {
    setMarkers(markers => [
      ...markers,
      {x: e.nativeEvent.offsetX - 10, y: e.nativeEvent.offsetY - 10}
    ]);
  }
  const clearMarkers = () => {
    setMarkers([]);
  }
  useUpdateEffect(() => {
    setHasMarker(markers.length > 0);
  }, [markers]);

  const [reporting, setReporting] = useState(false);
  const [reportForming, setReportForming] = useState(false);
  const [reportForm] = Form.useForm();
  const report = values => {
    redux.dispatch({
      type: 'type',
      data: {isReport: false}
    });
    setReporting(true);
    axios.post('/project/report', {...values, id: project.id})
      .finally(() => {
        setReporting(false);
      })
      .then(response => {
        message.success('生成成功');
        let {reportFile} = response.data.data;
        redux.dispatch({
          type: 'type',
          data: {isReport: true, reportFile: reportFile}
        });
        setReportForming(false);
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  const [charting, setCharting] = useState(false);
  const [chartProcess, setChartProcess] = useState(false);
  const [chartProgress, setChartProgress] = useState({});
  const [tmpChart, setTmpChart] = useState();
  useEffect(() => {
    setTmpChart(echarts.init(document.getElementById('tmpChart')));
  }, []);
  const chart = () => {
    let polling = () => {
      return axios.get('/project/chart', {params: {id: project.id, limit: pagination.pageSize}})
        .then(response => {
          let task = response.data.data;
          setChartProgress(task);
          if (task.status == 'process') {
            let timeout = 512;
            if (task.progress > 10) {
              timeout = 1024;
            }
            if (task.progress >= 100) {
              timeout = 2048;
            }
            setTimeout(polling, timeout);
          }
          if (task.status == 'success') {
            redux.dispatch({
              type: 'type',
              data: {isChart: true, chartFile: task.chartFile}
            });
          }
          if (task.status != 'process') {
            setChartProcess(false);
          }
        })
        .catch(e => {
          message.error(e.toString());
          setCharting(false);
          setChartProcess(false);
        });
    }
    if (chartProcess) {
      setCharting(true);
    } else {
      const confirm = Modal.confirm({
        title: '确定生成图表吗？',
        onOk: async () => {
          redux.dispatch({
            type: 'type',
            data: {isChart: false}
          });
          setChartProcess(true);
          setChartProgress({});
          confirm.destroy();
          setCharting(true);
          return polling();
        }
      });
    }
  }

  const [changes, setChanges] = useState([]);
  const [hasChange, setHasChange] = useState(false);
  useUpdateEffect(() => {
    if (
      data.wallThicknessRatio != inspect.wallThicknessRatio
      || data.timeRatio != inspect.timeRatio
      || changes.length > 0
    ) {
      setHasChange(true);
    } else {
      setHasChange(false);
    }
  }, [data, changes]);
  const [saving, setSaving] = useState(false);
  const save = () => {
    if (saving) {
      return true;
    }
    Modal.confirm({
      title: '确定保存修改吗？',
      onOk: () => {
        setSaving(true);
        axios.post('/project/setDataChart', {
          ...data,
          changes,
          id: project.id,
        })
          .finally(() => {
            setSaving(false);
          })
          .then(response => {
            message.success('保存成功');
            setInspect(inspect => {
              return {...inspect, wallThicknessRatio: data.wallThicknessRatio, timeRatio: data.timeRatio};
            });
            setChanges([]);
            setHasChange(false);
          })
          .catch(error => {
            message.error(error.message);
          });
      }
    });
  }

  const [misspointing, setMisspointing] = useState(false);
  const [misspointSaving, setMisspointSaving] = useState(false);
  const [misspointForm] = Form.useForm();
  const misspointSave = () => {
    setMisspointSaving(true);
    let values = misspointForm.getFieldsValue();
    let element = document.getElementById('mainChart');
    html2canvas(element, {
      useCORS: true,
      width: element.clientWidth,
      height: element.clientHeight,
      scrollY: 0,
      scrollX: 0,
    })
      .then(canvas => {
        values.id = project.id;
        values.img = canvas.toDataURL();
        axios.post('/project/addMisspoint', values)
          .finally(() => {
            setMisspointSaving(false);
          })
          .then(response => {
            misspointForm.resetFields();
            message.success('保存成功');
            setMisspointing(false);
          })
          .catch(error => {
            message.error(error.message);
          });
      });
  }

  const [modifying, setModifying] = useState(false);
  const [modifyApplying, setModifyApplying] = useState(false);
  const [modifyForm] = Form.useForm();
  const modifyApply = values => {
    setModifyApplying(true);
    setChanges(changes => [...changes, {
      command: 'update',
      which: values.which - 1,
      start: values.start,
      end: values.end,
      value: values.value,
    }]);
    setDatas(datas => {
      datas = datas.map(value => {
        if (value.index >= values.start && value.index <= values.end) {
          if (values.which == 1) {
            value.a = values.value;
          } else {
            value.b = values.value;
          }
        }
        return value;
      });
      return datas;
    });
    setModifyApplying(false);
    modifyForm.resetFields();
    setModifying(false);
  }

  const [deleting, setDeleting] = useState(false);
  const [deleteApplying, setDeleteApplying] = useState(false);
  const [deleteForm] = Form.useForm();
  const deleteApply = values => {
    setDeleteApplying(true);
    setChanges(changes => [...changes, {
      command: 'delete',
      start: values.start,
      end: values.end,
    }]);
    setDatas(datas => {
      datas = datas.filter(value => {
        return !(value.index >= values.start && value.index <= values.end);
      });
      return datas;
    });
    setData({...data, start: datas[0].index, end: datas[datas.length - 1].index});
    setDeleteApplying(false);
    deleteForm.resetFields();
    setDeleting(false);
  }

  const defaultSearchPagination = {
    pageSize: 10,
    current: 1,
    total: 0,
  }
  const [searchable, setSearchable] = useLocalStorageState('project-data-searchable', false);
  const [searchForm] = Form.useForm();
  const [searchError, setSearchError] = useState(false);
  const [searchWhere, setSearchWhere] = useState();
  const [searching, setSearching] = useState(false);
  const [searchDatas, setSearchDatas] = useState(null);
  const [searchDataInfo, setSearchDataInfo] = useState({});
  const [searchPagination, setSearchPagination] = useState(defaultSearchPagination);
  const searchLoad = (values) => {
    let _searchPagination = {...searchPagination};
    let _searchWhere = {...searchWhere};
    if (values) {
      let tmp = {};
      for (let key in values) {
        if (values[key] !== null && values[key] !== undefined && values[key] !== '') {
          tmp[key] = values[key];
        }
      }
      values = tmp;
      if (Object.keys(values).length <= 1) {
        values = null;
      }
      _searchWhere = values;
      setSearchWhere(values);
      if (values) {
        _searchPagination.current = 1;
      } else {
        setSearchError(true);
      }
    }
    if (searching || !_searchWhere) {
      return;
    }
    setSearching(true);
    setSearchError(false);
    axios.post('/project/searchDataChart', {
      ..._searchWhere,
      id: project.id,
      pageSize: _searchPagination.pageSize,
      currentPage: _searchPagination.current,
      page: pagination.pageSize,
      filter: data.filter,
    })
      .then(response => {
        let {datas, dataInfo, pagination} = response.data.data;
        setSearchDatas(datas);
        setSearchPagination(oldPagination => {
          oldPagination.pageSize = pagination.perPage;
          oldPagination.current = pagination.currentPage;
          oldPagination.total = pagination.totalCount;
          return oldPagination;
        });
        setSearchDataInfo(dataInfo);
        setSearching(false);
      })
      .catch(error => {
        setSearching(false);
        message.error(error.message);
      });
  }
  const [searchTagVisible, setSearchTagVisible] = useLocalStorageState('project-data-search-tips', true);
  useUpdateEffect(() => {
    searchLoad();
  }, [searchPagination]);

  return (
    <div className="projectDataChart">
      <div className="dataChartMain" ref={view}>
        <Spin size="large" tip="loading……" spinning={loading}>
          <Form
            form={form}
            onValuesChange={values => setData(data => {
              return {...data, ...values}
            })}
          >
            <div className="dataChartWraper">
              <div className="dataChartTop">
                <div className="toolBtn">
                  <Space>
                    {
                      project.isReport ? (
                        App.hasAuth('project.setProject') ? (
                          <Dropdown.Button
                            type="primary"
                            overlay={(
                              <Menu className="ant-dropdown-button-menu">
                                <Menu.Item>
                                  <Button type="primary" loading={reporting} onClick={() => setReportForming(true)}>重新生成</Button>
                                </Menu.Item>
                              </Menu>
                            )}
                          >
                            <a href={project.reportFile} target="_blank">查看报表</a>
                          </Dropdown.Button>
                        ) : (
                          <Button type="primary">
                            <a href={project.reportFile} target="_blank">查看报表</a>
                          </Button>
                        )
                      ) : (App.hasAuth('project.setProject') ? <Button type="primary" loading={reporting} onClick={() => setReportForming(true)}>生成报表</Button> : null)
                    }
                    {
                      project.isChart ? (
                        App.hasAuth('project.setProject') ? (
                          <Dropdown.Button
                            type="primary"
                            overlay={(
                              <Menu className="ant-dropdown-button-menu">
                                <Menu.Item>
                                  <Button type="primary" loading={charting} onClick={chart}>重新生成</Button>
                                </Menu.Item>
                              </Menu>
                            )}
                          >
                            <a href={project.chartFile} target="_blank">查看图表</a>
                          </Dropdown.Button>
                        ) : (
                          <Button type="primary">
                            <a href={project.chartFile} target="_blank">查看图表</a>
                          </Button>
                        )
                      ) : (App.hasAuth('project.setProject') ? <Button type="primary" loading={charting} onClick={chart}>生成图表</Button> : null)
                    }
                    {
                      App.hasAuth('project.setProject') ? (
                        <Badge count={hasChange ? 1 : 0} dot>
                          <Button type="primary" disabled={!hasChange} loading={saving} onClick={save}>保存修改</Button>
                        </Badge>
                      ) : null
                    }
                  </Space>
                </div>
                <div className="centCtrl">
                  <Space size={10}>
                    <Form.Item name="filter" valuePropName="checked" noStyle>
                      <Switch disabled={fetching} checkedChildren="关闭滤波" unCheckedChildren="开启滤波"/>
                    </Form.Item>
                    <Badge count={hasMarker ? 1 : 0} dot>
                      <Button type="primary" size="small" disabled={!hasMarker} onClick={clearMarkers}>清除标识</Button>
                    </Badge>
                  </Space>
                </div>
                <div className="ratioSetting">
                  <div className="topItem">
                    <Form.Item name="wallThicknessRatio" label="壁厚系数">
                      <FormItemTriggers>
                        <InputNumber disabled={fetching} min={0} max={1} step={0.1} placeholder="壁厚系数"/>
                      </FormItemTriggers>
                    </Form.Item>
                  </div>
                  <div className="topItem">
                    <Form.Item name="timeRatio" label="长度系数">
                      <FormItemTriggers>
                        <InputNumber disabled={fetching} min={0} max={1} step={0.1} placeholder="长度系数"/>
                      </FormItemTriggers>
                    </Form.Item>
                  </div>
                  {
                    !searchable ? (
                      <div className="topItem">
                        <Tooltip title="search">
                          <Button shape="circle" icon={<SearchOutlined/>} onClick={() => setSearchable(true)}/>
                        </Tooltip>
                      </div>
                    ) : null
                  }
                </div>
              </div>
              <div className="dataChartContainer">
                <Spin size="large" tip="loading……" spinning={!loading && (fetching || fetching)}>
                  <div id="mainChart" onDoubleClick={onDoubleClick}>
                    {
                      markers.map(marker => (
                        <div className="marker" style={{left: marker.x, top: marker.y}}></div>
                      ))
                    }
                  </div>
                  <div style={{display: "none"}}>
                    <div style={{width: 1024, height: 450}} id="tmpChart"/>
                  </div>
                </Spin>
              </div>
              <div className="dataChartBottom">
                <div className="dataChartBottomLeft">
                  <div className="bottomItem">
                    <Pagination
                      size="small"
                      showQuickJumper
                      showSizeChanger
                      showLessItems
                      disabled={loading}
                      current={pagination.currentPage}
                      pageSize={pagination.pageSize}
                      total={pagination.totalCount}
                      pageSizeOptions={[100, 500, 1000, 5000, 10000, 20000, 30000]}
                      onChange={(page, pageSize) => setPagination((pagination) => {
                        return {...pagination, currentPage: page, pageSize: pageSize};
                      })}
                    />
                  </div>
                </div>
                <div className="dataChartBottomRight">
                  <div className="bottomItem">
                    <Form.Item name="max" label="最大值">
                      <FormItemTriggers>
                        <InputNumber disabled={fetching} placeholder="最大值"/>
                      </FormItemTriggers>
                    </Form.Item>
                  </div>
                  <div className="bottomItem">
                    <Form.Item name="min" label="最小值">
                      <FormItemTriggers>
                        <InputNumber disabled={fetching} placeholder="最小值"/>
                      </FormItemTriggers>
                    </Form.Item>
                  </div>
                  {
                    App.hasAuth('project.setProject') ? (
                      <>
                        <div className="bottomItem">
                          <Button type="primary" disabled={fetching} onClick={() => setModifying(true)}>修改数据</Button>
                        </div>
                        <div className="bottomItem">
                          <Button type="primary" disabled={fetching} onClick={() => setDeleting(true)}>删除数据</Button>
                        </div>
                        <div className="bottomItem">
                          <Button type="primary" disabled={fetching} onClick={() => setMisspointing(true)}>添加漏点</Button>
                        </div>
                      </>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </Form>
        </Spin>
        <Modal
          title="生成报告"
          maskClosable={false}
          visible={reportForming}
          confirmLoading={reporting}
          onOk={reportForm.submit}
          onCancel={() => setReportForming(false)}
          cancelButtonProps={{disabled: reporting}}
        >
          <Form form={reportForm} layout="vertical" onFinish={report} initialValues={project}>
            <Form.Item name="monitorConclu" label="检测结论">
              <Input.TextArea rows={4} placeholder="检测结论" disabled={reporting}/>
            </Form.Item>
            <Form.Item name="monitorOtherInfo" label="其他情况说明">
              <Input.TextArea rows={4} placeholder="其他情况说明" disabled={reporting}/>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="后台生成图表中……"
          maskClosable={false}
          visible={charting}
          footer={null}
          onCancel={() => setCharting(false)}
        >
          {
            chartProgress.status == 'process' || !chartProgress.status ? (
              chartProgress.progress >= 100
                ? <>
                  <Result title="图表pdf生成中…………" subTitle="可能需要一段时间" icon=<LoadingOutlined/>/>
                </>
                : <Progress percent={chartProgress.progress} status="active"/>
            ) : <>
              {
                chartProgress.status == 'success'
                  ? <Result
                    status="success"
                    title="生成成功"
                    subTitle={'用时' + (chartProgress.time / 1000) + '秒'}
                    extra={[<Button type="link" size="large" href={project.chartFile} target="_blank">点击查看</Button>]}
                  />
                  : <Result status="error" title="生成失败"/>
              }
            </>
          }
        </Modal>
        <Modal
          title="添加漏点"
          maskClosable={false}
          visible={misspointing}
          confirmLoading={misspointSaving}
          onOk={misspointForm.submit}
          onCancel={() => setMisspointing(false)}
          cancelButtonProps={{disabled: misspointSaving}}
        >
          <Form form={misspointForm} layout="vertical" onFinish={misspointSave}>
            <Form.Item name="explain" label="漏点说明" rules={[{required: true}]}>
              <Input.TextArea rows={4} placeholder="漏点说明" disabled={misspointSaving}/>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="报表信息区间修改"
          maskClosable={false}
          width={400}
          visible={modifying}
          confirmLoading={modifyApplying}
          onOk={modifyForm.submit}
          onCancel={() => setModifying(false)}
        >
          <Form
            form={modifyForm}
            layout="horizontal"
            labelCol={{span: 6}}
            onFinish={modifyApply}
            initialValues={{...data, which: '1', value: 0}}
          >
            <Form.Item name="which" label="壁厚" rules={[{required: true}]}>
              <Select style={{width: 200}} placeholder="选择壁厚">
                <Select.Option value="1">壁厚一</Select.Option>
                <Select.Option value="2">壁厚二</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="start" label="开始值" rules={[{required: true}]}>
              <InputNumber min={data.start} max={data.end} style={{width: 200}} placeholder="开始值"/>
            </Form.Item>
            <Form.Item name="end" label="结束值" rules={[{required: true}]}>
              <InputNumber min={data.start} max={data.end} style={{width: 200}} placeholder="结束值"/>
            </Form.Item>
            <Form.Item name="value" label="修改值" rules={[{required: true}]}>
              <InputNumber style={{width: 200}} placeholder="修改值"/>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="报表信息区间删除"
          maskClosable={false}
          visible={deleting}
          confirmLoading={deleteApplying}
          onOk={deleteForm.submit}
          onCancel={() => setDeleting(false)}
        >
          <Form
            form={deleteForm}
            layout="horizontal"
            labelCol={{span: 6}}
            onFinish={deleteApply}
            initialValues={{...data}}
          >
            <Form.Item name="start" label="开始值" rules={[{required: true}]}>
              <InputNumber min={data.start} max={data.end} style={{width: 200}} placeholder="开始值"/>
            </Form.Item>
            <Form.Item name="end" label="结束值" rules={[{required: true}]}>
              <InputNumber min={data.start} max={data.end} style={{width: 200}} placeholder="结束值"/>
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <div className={'dataChartSearch' + (searchable ? ' active' : '')}>
        <Card title="搜索" extra={<a style={{cursor: "pointer", color: "blue"}} onClick={() => setSearchable(false)}>收起→</a>}>
          <div className="dataChartSearchContainer">
            <div className="dataChartSearchHeader">
              <Form form={searchForm} onFinish={searchLoad}>
                {searchError ? <Alert message="至少一个条件" type="error"/> : null}
                <div className="formItems">
                  <div className="formItem">壁厚1：</div>
                  <div className="formItem">
                    <Form.Item name="gt1" label="大于">
                      <InputNumber placeholder="大于"/>
                    </Form.Item>
                  </div>
                  <div className="formItem">
                    <Form.Item name="lt1" label="小于">
                      <InputNumber placeholder="小于"/>
                    </Form.Item>
                  </div>
                </div>
                <div className="formItems">
                  <div className="formItem">壁厚2：</div>
                  <div className="formItem">
                    <Form.Item name="gt2" label="大于">
                      <InputNumber placeholder="大于"/>
                    </Form.Item>
                  </div>
                  <div className="formItem">
                    <Form.Item name="lt2" label="小于">
                      <InputNumber placeholder="小于"/>
                    </Form.Item>
                  </div>
                </div>
                <div className="formCtrl">
                  <div>
                    <Form.Item name="operator" initialValue="or">
                      <Radio.Group>
                        <Radio value="or">满足任意条件</Radio>
                        <Radio value="and">满足全部条件</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Tag color="green" closable visible={searchTagVisible} onClose={() => setSearchTagVisible(false)}>
                      tips: 点击搜索结果可快速跳转
                    </Tag>
                  </div>
                  <div style={{float: "right"}}>
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined/>} loading={searching}>搜索</Button>
                  </div>
                </div>
              </Form>
            </div>
            <div className="dataChartSearchMain">
              <Spin spinning={searching} tip="searching">
                <div className="dataChartSearchMainContainer">
                  {
                    searchDatas ? (
                      searchDatas.length > 0 ? (
                        <div className="datasContainer">
                          {
                            searchDatas.map(data => {
                              return (
                                <div className="pageItem" title="点击查看" onClick={() => setPagination(pagination => {
                                  return {...pagination, currentPage: data.page}
                                })}>
                                  <div className="pageItemInfo">
                                    第<span className="label">{data.page}</span>页
                                    找到<span className="label">{data.count}</span>处
                                  </div>
                                  <div className="pageItemDetail">
                                    {data.datas.slice(0, 10).map(data => <span className="dataItem">{data}</span>)}
                                    {data.count > 10 ? '……' : null}
                                  </div>
                                </div>
                              );
                            })
                          }
                        </div>
                      ) : (searching ? null : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>)
                    ) : (
                      searching ? null : <div style={{textAlign: 'center', padding: '25px 0', color: '#cccccc'}}>输入条件搜索</div>
                    )
                  }
                </div>
              </Spin>
            </div>
            <div className="dataChartSearchFooter clearfix">
              <div style={{float: "left"}}>
                {
                  searchDataInfo.page || searchDataInfo.count ? <>
                    在<span className="label">{searchDataInfo.page}</span>页中
                    找到<span className="label">{searchDataInfo.count}</span>处
                  </> : null
                }
              </div>
              <div style={{float: "right"}}>
                <Pagination
                  simple
                  hideOnSinglePage
                  {...searchPagination}
                  onChange={(page, pageSize) => setSearchPagination(pagination => {
                    return {...pagination, current: page, pageSize: pageSize}
                  })}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}