import React, {useEffect, useState} from "react";
import {Form, Input, Card, Switch, Radio, Button, message, Image, Skeleton, Space, Select, DatePicker} from "antd";
import axios from "../../conf/axios";
import {imgPlaceholder} from "../../conf/general";
import FileInput from "../../components/ant/FileInput";
import redux from "./redux";
import moment from "moment";
import App from "../../libs/App";

export default ({project}) => {
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState({});

  const load = () => {
    setLoading(true);
    var data1=localStorage.getItem('report');
    if(data1){
      
      var data2=JSON.parse(data1);
      var expireTime=data2.time+600000;
      var curTime1=new Date().getTime();
       if(curTime1>expireTime){
       axios.post('/project/getReport', {id: project.id})
      .then(response => {
        let {report} = response.data.data;
        setReport(report);
        setData(report);
        setLoading(false);
      })
      .catch(error => {
        message.error(error.message);
      });
    }else{
      var data3=new Object();
      data3.report=data2.data;;
      let {report} = data3
      console.log(data3);
      setReport(report);
      setData(report);
      setLoading(false);
    }
    }else{
       axios.post('/project/getReport', {id: project.id})
      .then(response => {
        let {report} = response.data.data;
        setReport(report);
        setData(report);
        setLoading(false);
      })
      .catch(error => {
        message.error(error.message);
      });
    }
    
  }
  useEffect(() => {
    load();
  }, []);

  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const save = values => {
    if (saving) {
      return;
    }
    setSaving(true);
    var curTime=new Date().getTime();
    localStorage.setItem('report',JSON.stringify({data:values,time:curTime}));
    redux.dispatch({
          type: 'type',
          data: values
    });
    message.success('保存成功');
    setSaving(false);
    location.reload();
  }

   const handleExport = () => {
     axios.post('/project/testReport', {id: project.id,modelId:project.modelId,data:report})
      .then(response => {
         message.success('导出成功', 0.5, () => {
          setTimeout(() => {
             window.open(response.data.data.pdf);
          }, 500);
        });
      })
      .catch(error => {
        message.error(error.message);
      });
   }

  return (
    <Card className="projectDetail" title="检测报告" extra={<Button type="primary"  onClick={handleExport}>导出报告</Button>}>
    <div className="projectPipeline" style={{width: 800}}>
      {
        loading ? <Skeleton active/> : (
          <Form
            className="ant-form-table"
            form={form}
            onValuesChange={changedValues => setData(data => {
              return {...data, ...changedValues}
            })}
            onFinish={save}
          >
          <div>

          </div>
          <Form.Item name="no" label="no" hidden={true}>
                    <Input placeholder="编号"/>
           </Form.Item>
            <table className="table table-bordered">
           <tr>
              <td>产品名称</td>  
              <td colSpan="2">{
                !editing ? data.productName : (
                  <Form.Item name="productName">
                    <Input placeholder="产品名称" />
                  </Form.Item>
                )
              }</td>
              <td>规格型号</td>  
              <td colSpan="2">
                {
                !editing ? data.Specifications : (
                  <Form.Item name="Specifications">
                    <Input placeholder="规格型号" />
                  </Form.Item>
                )
              }
              </td>    
           </tr>
           <tr>
              <td>委托单位</td>  
              <td colSpan="2">
              {
                !editing ? data.entrustedUnit : (
                  <Form.Item name="entrustedUnit">
                    <Input placeholder="委托单位" />
                  </Form.Item>
                )
              }
              </td>    
              <td>生产日期</td>  
              <td colSpan="2">{
                !editing ? data.productTime : (
                  <Form.Item name="productTime">
                    <Input placeholder="生产日期" />
                  </Form.Item>
                )
              }</td>    
           </tr>
           <tr>
              <td>生产厂家</td>  
               <td colSpan="2">
              {
                !editing ? data.proCom : (
                  <Form.Item name="proCom">
                    <Input placeholder="生产厂家" />
                  </Form.Item>
                )
              }
              </td>  
              <td>检验类别</td>  
               <td colSpan="2">
              {
                !editing ? data.testType : (
                  <Form.Item name="testType">
                    <Input placeholder="检验类别" />
                  </Form.Item>
                )
              }
              </td>    
           </tr>
           <tr>
              <td>抽样地点</td>  
               <td colSpan="2">
              {
                !editing ? data.sampleLoc : (
                  <Form.Item name="sampleLoc">
                    <Input placeholder="抽样地点" />
                  </Form.Item>
                )
              }
              </td>  
              <td>产品等级</td>  
               <td colSpan="2">
              {
                !editing ? data.proLevel : (
                  <Form.Item name="proLevel">
                    <Input placeholder="产品等级" />
                  </Form.Item>
                )
              }
              </td>    
           </tr>
           <tr>
              <td>样品量</td>  
               <td colSpan="2">
              {
                !editing ? data.sampleNum : (
                  <Form.Item name="sampleNum">
                    <Input placeholder="样品量" />
                  </Form.Item>
                )
              }
              </td>  
              <td>样本量</td>  
               <td colSpan="2">
              {
                !editing ? data.sampleNum1 : (
                  <Form.Item name="sampleNum1">
                    <Input placeholder="样本量" />
                  </Form.Item>
                )
              }
              </td>    
           </tr>
           <tr>
              <td>样品状态</td>  
               <td colSpan="2">
              {
                !editing ? data.status : (
                  <Form.Item name="status">
                    <Input placeholder="样品状态" />
                  </Form.Item>
                )
              }
              </td>  
              <td>商标</td>  
               <td colSpan="2">
              {
                !editing ? data.trademark : (
                  <Form.Item name="trademark">
                    <Input placeholder="商标" />
                  </Form.Item>
                )
              }
              </td>    
           </tr>
           <tr>
              <td>抽样日期</td>  
               <td colSpan="2">
              {
                !editing ? data.sampleDate : (
                  <Form.Item name="sampleDate">
                    <Input placeholder="抽样日期" />
                  </Form.Item>
                )
              }
              </td>  
              <td>到样日期</td>  
               <td colSpan="2">
              {
                !editing ? data.arrivalDate : (
                  <Form.Item name="arrivalDate">
                    <Input placeholder="到样日期" />
                  </Form.Item>
                )
              }
              </td>    
           </tr>
           <tr>
              <td>样品编号</td>  
               <td colSpan="2">
              {
                !editing ? data.sampleNo : (
                  <Form.Item name="sampleNo">
                    <Input placeholder="样品编号" />
                  </Form.Item>
                )
              }
              </td>  
              <td>检验时间</td>  
               <td colSpan="2">
              {
                !editing ? data.testDate : (
                  <Form.Item name="testDate">
                    <Input placeholder="检验时间" />
                  </Form.Item>
                )
              }
              </td>    
           </tr>
           <tr>
              <td>送样人</td>  
               <td colSpan="2">
              {
                !editing ? data.sampSender : (
                  <Form.Item name="sampSender">
                    <Input placeholder="送样人" />
                  </Form.Item>
                )
              }
              </td>  
              <td>环境条件</td>  
               <td colSpan="2">
              {
                !editing ? data.environment : (
                  <Form.Item name="environment">
                    <Input placeholder="环境条件" />
                  </Form.Item>
                )
              }
              </td>    
           </tr>
           <tr>
              <td>依据标准<br/>编号/名称</td>  
               <td colSpan="5">
              {
                !editing ? data.nameNo : (
                  <Form.Item name="nameNo">
                    <Input placeholder="编号/名称" />
                  </Form.Item>
                )
              }
              </td>    
           </tr>
            <tr>
              <td>检验项目/<br/>参数</td>  
               <td colSpan="5">
              {
                !editing ? data.parameter : (
                  <Form.Item name="parameter">
                    <Input placeholder="检验项目/参数" />
                  </Form.Item>
                )
              }
              </td>   
           </tr>
           <tr>
              <td>检验用<br/>主要设备</td>  
               <td colSpan="5">
              {
                !editing ? data.device : (
                  <Form.Item name="device">
                    <Input placeholder="主要设备" />
                  </Form.Item>
                )
              }
              </td>    
           </tr>
           <tr>
              <td>检验结论</td>  
               <td colSpan="5">
              {
                !editing ? data.result : (
                  <Form.Item name="result">
                    <Input placeholder="检验结论" />
                  </Form.Item>
                )
              }
              </td>    
           </tr>
           <tr>
              <td>备注</td>  
               <td colSpan="5">
              {
                !editing ? data.note : (
                  <Form.Item name="note">
                    <Input placeholder="备注" />
                  </Form.Item>
                )
              }
              </td>    
           </tr>
           <tr>
              <td>主检</td>  
               <td>
              {
                !editing ? data.mainInspection : (
                  <Form.Item name="mainInspection">
                    <Input placeholder="主检" />
                  </Form.Item>
                )
              }
              </td>
              <td>审核</td>  
               <td>
              {
                !editing ? data.examine : (
                  <Form.Item name="examine">
                    <Input placeholder="审核" />
                  </Form.Item>
                )
              }
              </td>  
              <td>批准</td>  
               <td>
              {
                !editing ? data.approval : (
                  <Form.Item name="approval">
                    <Input placeholder="批准" />
                  </Form.Item>
                )
              }
              </td>   
           </tr>

          </table>
            {
              project.modelId ? (
                <>
                  {
                    project.modelId == 'SCTR' ? (
                      <>
                  <div>
                    <h4>涂层厚度检测结果</h4>
                    <p>依据{
                !editing ? data.standard : (
                  <Form.Item name="standard">
                    <Input placeholder="标准" />
                  </Form.Item>
                )
              }标准对{
                !editing ? data.coatingName : (
                  <Form.Item name="coatingName">
                    <Input placeholder="内涂层" />
                  </Form.Item>
                )
              }内涂层厚度开展在线检测，现场检测管道长度约{
                !editing ? data.length : (
                  <Form.Item name="length">
                    <Input placeholder="长度" />
                  </Form.Item>
                )
              }m，采样间隔{
                !editing ? data.interval : (
                  <Form.Item name="interval">
                    <Input placeholder="采样间隔" />
                  </Form.Item>
                )
              }，检测涂层厚度数据{
                !editing ? data.thickData : (
                  <Form.Item name="thickData">
                    <Input placeholder="检测涂层厚度数据" />
                  </Form.Item>
                )
              }点，合格涂层厚度数据{
                !editing ? data.qualifyData : (
                  <Form.Item name="qualifyData">
                    <Input placeholder="合格涂层厚度数据" />
                  </Form.Item>
                )
              },合格比例{
                !editing ? data.qualifyRate : (
                  <Form.Item name="qualifyRate">
                    <Input placeholder="合格比例" />
                  </Form.Item>
                )
              }，低于标准规定指标检测结果见表，检测曲线见图。</p>
                 </div>
                      </>
                    ) : null
                  }
                  {
                    project.modelId == 'SICR'  ? (
                      <>
                       <div>
                    <h4>外观检测结果</h4>
                    <p>依据{
                !editing ? data.standard : (
                  <Form.Item name="standard">
                    <Input placeholder="标准" />
                  </Form.Item>
                )
              }标准对{
                !editing ? data.coatingName : (
                  <Form.Item name="coatingName">
                    <Input placeholder="内涂层" />
                  </Form.Item>
                )
              }内涂层外观开展视频检测，现场检测管道长度约{
                !editing ? data.length : (
                  <Form.Item name="length">
                    <Input placeholder="长度" />
                  </Form.Item>
                )
              }m，检测结果见表及图所示。</p>
                 </div>
                      </>
                    ) : null
                  }
                  {
                    project.modelId == 'SLMR' ? (
                      <>
                      <div>
                    <h4>涂层漏点检测结果</h4>
                    <p>依据{
                !editing ? data.standard : (
                  <Form.Item name="standard">
                    <Input placeholder="标准" />
                  </Form.Item>
                )
              }标准对{
                !editing ? data.coatingName : (
                  <Form.Item name="coatingName">
                    <Input placeholder="内涂层" />
                  </Form.Item>
                )
              }内涂层开展在线漏点检测，现场检测管道长度约{
                !editing ? data.length : (
                  <Form.Item name="length">
                    <Input placeholder="长度" />
                  </Form.Item>
                )
              }m，检测出漏点{
                !editing ? data.leakPoint : (
                  <Form.Item name="leakPoint">
                    <Input placeholder="长度" />
                  </Form.Item>
                )
              }个,检测结果见表所示。</p>
                 </div>
                      </>
                    ) : null
                  }
                  
                </>
              ) : null
            }
          
       
            <div style={{marginTop: 15, textAlign: 'center'}}>
 
              {
            !editing && App.hasAuth('project.setProject') ? (
              <Button type="primary" onClick={()  => {
                    form.setFieldsValue({...report, productionTime: moment(report.productionTime * 1000)});
                    setEditing(true);
                  }}>编辑</Button>
            ) : null
          }
              {
                editing ? (
                  <Space>
                    <Button type="primary" htmlType="submit" loading={saving}>保存</Button>
                    <Button type="ghost" onClick={() => setEditing(false)}>返回</Button>
                  </Space>
                ) : null
              }
            </div>
          </Form>
        )
      }
    </div>
    </Card>
  );
};

