import {Alert, AutoComplete, Button, Card, Drawer, Form, Input, message, Popconfirm, Space, Spin, Table} from "antd";
import moment from "moment";
import React, {useEffect, useState} from "react";
import axios from "../../conf/axios";
import {SearchOutlined} from '@ant-design/icons';
import App from "../../libs/App";

export default () => {
  const where = {
    pagination: {
      /*pageSize: 2,
      currentPage: 1,*/
    }
  };

  const whereChange = values => {
    where.kw = values.name;
    fetch();
  };

  const [kwOptions, setKwOptions] = useState([]);
  const onKwSearch = value => {
    axios.post('/projectScheme/getProjectSchemes', {kw: value})
      .then(response => {
        const projectSchemes = response.data.data.projectSchemes.map(projectScheme => ({
          value: projectScheme.name,
        }));
        setKwOptions(projectSchemes);
      })
      .catch(error => {
        message.error(error.message);
      });
  };

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      render: (value, record) => {
        return <a href={record.file} target="_blank">{record.name}</a>
      }
    },
    {
      title: '大小',
      dataIndex: 'size',
      width: 150,
      render: value => {
        return value ? ((value / 1024 / 1024).toFixed(2) + ' MB') : ' ';
      }
    },
    {
      title: '添加时间',
      dataIndex: 'createTime',
      width: 200,
      render: value => {
        return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '';
      }
    },
    {
      title: '操作',
      key: 'id',
      width: 200,
      render: (text, record) => (
        <Space>
          {
            App.hasAuth('projectScheme.setProjectScheme') ? (
              <Button type="primary" size="small" onClick={() => edit(record)}>编辑</Button>
            ) : null
          }
          {
            App.hasAuth('projectScheme.deleteProjectScheme') ? (
              <Popconfirm title="确定删除此方案模版吗" onConfirm={() => adelete(record)}>
                <Button type="primary" size="small">删除</Button>
              </Popconfirm>
            ) : null
          }
        </Space>
      ),
    },
  ];
  if (!App.hasOneAuths('projectScheme.setProjectScheme', 'projectScheme.deleteProjectScheme')) {
    columns.splice(columns.length - 1, 1);
  }

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [pagination, setPagination] = useState([]);
  const tableChange = (pagination, filters, sorter) => {
    where.pagination = {
      pageSize: pagination.pageSize,
      currentPage: pagination.current,
    }
    fetch();
  };

  const fetch = (params = {}) => {
    setLoading(true);
    axios.post('/projectScheme/getProjectSchemes', where)
      .then(response => {
        let data = response.data.data;
        let pagination = data.pagination;
        setLoading(false);
        setDatas(data.projectSchemes);
        setPagination({
          current: pagination.currentPage,
          pageSize: pagination.perPage,
          total: pagination.totalCount,
        });
      })
      .catch(error => {
        message.error(error.message);
      });
  };
  useEffect(() => {
    fetch();
  }, []);

  const adelete = values => {
    axios.post('/projectScheme/deleteProjectScheme', values)
      .then(response => {
        message.success('删除成功');
        fetch();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  const [editIs, setEditIs] = useState(false);
  const [editLoading, setEditLoading] = useState(true);
  const [record, setRecord] = useState({});
  const [editSubmitting, setEditSubmitting] = useState(false);
  const [editForm] = Form.useForm();
  const edit = values => {
    setEditIs(true);
    if (values) {
      setRecord(values);
      setEditLoading(false);
      editForm.resetFields();
      editForm.setFieldsValue(values);
    } else {
      setRecord({});
      setEditLoading(false);
      editForm.resetFields();
    }
  }
  const editClose = () => {
    setEditIs(false);
    setEditLoading(false);
  }

  const save = values => {
    if (editSubmitting) {
      return;
    }
    var formData = new FormData();
    for (let key in values) {
      let value = values[key];
      if (value) {
        formData.append(key, value);
      }
    }
    let file = document.getElementById('file').files[0];
    if (file) {
      formData.append('file', file);
    }
    setEditSubmitting(true);
    axios.post(values.id ? '/projectScheme/setProjectScheme' : '/projectScheme/addProjectScheme', formData)
      .finally(() => {
        setEditSubmitting(false);
      })
      .then(response => {
        setEditIs(false);
        setEditLoading(true);
        fetch();
        message.success('保存成功');
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  return (
    <Card title="方案模版" extra={App.hasAuth('projectScheme.addProjectScheme') ? <Button type="primary" onClick={() => edit()}>添加</Button> : null}>
      <Form layout="inline" style={{marginBottom: 20}} onFinish={whereChange}>
        <Form.Item name="name" label="关键字">
          <AutoComplete
            options={kwOptions}
            style={{width: 250}}
            onSearch={onKwSearch}
            placeholder="关键字"
            allowClear={true}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit" icon={<SearchOutlined/>} loading={loading}>搜索</Button>
      </Form>
      <Table columns={columns} dataSource={datas} pagination={pagination} loading={loading} onChange={tableChange}/>
      <Drawer title="方案模版" width={444} placement="right" visible={editIs} onClose={editClose}>
        <Spin spinning={editLoading}/>
        {
          editLoading ? null : (
            <Form bordered form={editForm} layout="vertical" onFinish={save}>
              <Form.Item name="id" label="id" hidden={true}>
                <Input placeholder="id"/>
              </Form.Item>
              <Form.Item name="name" label="名称">
                <Input placeholder="名称" allowClear={true}/>
              </Form.Item>
              {
                record.file ? <Alert message="已上传的文件" description={
                  <>
                    <a href={record.file} target="_blank" style={{marginRight: 10}}>{record.name}</a>
                    <span>大小：{(record.size / 1024 / 1024).toFixed(2)} MB</span>
                  </>
                } type="success"/> : ''
              }
              <Form.Item name="afile" label="文件" rules={[{required: !record.file}]}>
                <input type="file" id="file" placeholder="文件"/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={editSubmitting}>保存</Button>
              </Form.Item>
            </Form>
          )
        }
      </Drawer>
    </Card>
  );
};