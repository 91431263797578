import React from "react";
import {v4 as uuidv4} from 'uuid';

export default ({placeholder, label, value, onChange}) => {
  let id = uuidv4();
  const triggerChange = event => {
    if (onChange) {
      let file = event.target.files[0];
      if (value != file) {
        onChange(file ? file : undefined);
      }
    }
  }
  return (
    <>
      <div className="custom-file">
        <input
          type="file"
          onChange={triggerChange}
          className="custom-file-input"
          id={id}
          placeholder={placeholder}
        />
        <label className="custom-file-label" htmlFor={id} style={{textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
          {value?.name ? value?.name : (label ? label : '选择文件')}
        </label>
      </div>
    </>
  );
};