import {useState, useEffect, useCallback} from "react";
import {useReactive} from "ahooks";

export default () => {
  const state = useReactive({
    count: 1,
    size: 1,
  });

  const inc = () => {
    state.count++;
  }

  const inc2 = () => {
    state.size++;
  }

  useEffect(() => {
    inc2();
  }, [state.count]);

  return (
    <>
      <button style={{marginTop: 20}} onClick={inc}>+</button>
      <div>count {state.count}</div>
      <div>size {state.size}</div>
    </>
  );
};