import React from "react";
import {Form, Input, Button, Card, message} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import Auth from "../libs/Auth";
import axios from "../conf/axios";
import redux from "../conf/redux";
import app from "../libs/App";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      disabled: false,
      buttonText: '登录',
    }
  }

  onFinish = values => {
    if (this.state.submitting || this.state.disabled) {
      return true;
    }
    this.setState({submitting: true, buttonText: '登录中'});
    axios.post('/login', values)
      .finally(() => {
        this.setState({submitting: false, buttonText: '登录'});
      })
      .then(response => {
        let data = response.data;
        Auth.setToken(data.data.token);
        this.setState({disabled: true, buttonText: '登录成功'});
        message.success('登录成功', 0.25, () => {
          setTimeout(() => {
            redux.dispatch({
              type: 'type',
              data: {isLogin: true}
            });
            app.user = response.data.data;
            app.user.isAdmin = !!app.user.role && app.user.role.id == 'admin';
            this.props.history.push('/');
          }, 250);
        });
      })
      .catch(error => {
        message.error(error.message);
      });
  };

  render() {
    return (
      <Card title="用户登录" style={{width: 377, position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <Form initialValues={{username: '13227737527', password: 'admin'}} onFinish={this.onFinish}>
          <Form.Item name="username" rules={[{required: true, message: '请输入用户名'}]}>
            <Input
              prefix={<UserOutlined className="site-form-item-icon"/>}
              placeholder="请输入用户名"
              disabled={this.state.disabled}
            />
          </Form.Item>
          <Form.Item name="password" rules={[{required: true, message: '请输入密码'}]} disabled={this.state.disabled}>
            <Input
              prefix={<LockOutlined className="site-form-item-icon"/>}
              type="password"
              placeholder="请输入密码"
              disabled={this.state.disabled}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block={true}
              loading={this.state.submitting}
              disabled={this.state.disabled}
            >
              {this.state.buttonText}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

export default Login;