import React, {useEffect, useState} from "react";
import {Form, Input, Switch, Radio, Button, message, Image, Skeleton, Space, Select, DatePicker} from "antd";
import axios from "../../conf/axios";
import {imgPlaceholder} from "../../conf/general";
import FileInput from "../../components/ant/FileInput";
import redux from "./redux";
import moment from "moment";
import App from "../../libs/App";

export default ({project}) => {
  const [loading, setLoading] = useState(true);
  const [pipeline, setPipeline] = useState({});
  const load = () => {
    setLoading(true);
    axios.post('/project/getPipeline', {id: project.id})
      .then(response => {
        let {pipeline} = response.data.data;
        setPipeline(pipeline);
        setData(pipeline);
        setLoading(false);
      })
      .catch(error => {
        message.error(error.message);
      });
  }
  useEffect(() => {
    load();
  }, []);

  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const save = values => {
    if (saving) {
      return;
    }
    setSaving(true);
    var formData = new FormData();
    for (let key in values) {
      let value = values[key];
      if (key == 'productionTime' && value) {
        value = value.valueOf() / 1000;
      }
      if (value !== undefined) {
        if (value === true || value === false) {
          value = value ? 1 : 0;
        }
        formData.append(key, value);
      }
    }
    axios.post('/project/setPipeline', formData)
      .finally(() => {
        setSaving(false);
      })
      .then(response => {
        message.success('保存成功');
        let {pipeline} = response.data.data;
        setPipeline(oldPipeline => {
          return {...oldPipeline, ...pipeline};
        });
        setData(data => {
          return {...data, ...pipeline};
        });
        form.resetFields();
        form.setFieldsValue({...pipeline, productionTime: moment(pipeline.productionTime * 1000)});
        redux.dispatch({
          type: 'type',
          data: {isPipeline: true}
        });
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  return (
    <div className="projectPipeline" style={{width: 1200}}>
      {
        loading ? <Skeleton active/> : (
          <Form
            className="ant-form-table"
            form={form}
            onValuesChange={changedValues => setData(data => {
              return {...data, ...changedValues}
            })}
            onFinish={save}
          >
            <Form.Item name="id" label="id" hidden={true}>
              <Input placeholder="id"/>
            </Form.Item>
            <table className="table table-bordered">
              <tr>
                <td width="25%">客户名称：</td>
                <td width="75%" colSpan="3">
                  {
                    !editing ? data.customerName : (
                      <Form.Item name="customerName">
                        <Input placeholder="客户名称"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td width="25%">联系人：</td>
                <td width="25%">
                  {
                    !editing ? data.contactPerson : (
                      <Form.Item name="contactPerson">
                        <Input placeholder="联系人"/>
                      </Form.Item>
                    )
                  }
                </td>
                <td width="25%">联系电话：</td>
                <td width="25%">
                  {
                    !editing ? data.contactPhone : (
                      <Form.Item name="contactPhone">
                        <Input placeholder="联系电话"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>管线名称：</td>
                <td>
                  {
                    !editing ? data.pipelineName : (
                      <Form.Item name="pipelineName">
                        <Input placeholder="管线名称"/>
                      </Form.Item>
                    )
                  }
                </td>
                <td>管线位置：</td>
                <td>
                  {
                    !editing ? data.pipelineLocation : (
                      <Form.Item name="pipelineLocation">
                        <Input placeholder="管线位置"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>投产时间：</td>
                <td>
                  {
                    !editing ? (data.productionTime ? moment(data.productionTime * 1000).format('YYYY-MM-DD HH:mm:ss') : '') : (
                      <Form.Item name="productionTime">
                        <DatePicker showTime placeholder="投产时间"/>
                      </Form.Item>
                    )
                  }
                </td>
                <td>管道长度：</td>
                <td>
                  {
                    !editing ? data.pipeLength : (
                      <Form.Item name="pipeLength">
                        <Input placeholder="管道长度"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>输送介质：</td>
                <td>
                  {
                    !editing ? data.transportatMedium : (
                      <Form.Item name="transportatMedium">
                        <Select>
                          <Select.Option value="">请选择</Select.Option>
                          <Select.Option value="原油">原油</Select.Option>
                          <Select.Option value="天然气">天然气</Select.Option>
                          <Select.Option value="水">水</Select.Option>
                          <Select.Option value="油水混合">油水混合</Select.Option>
                        </Select>
                      </Form.Item>
                    )
                  }
                </td>
                <td>
                  {
                    !editing ? '管径：' + data.pipeDiameter : (
                      <Form.Item label="管径" name="pipeDiameter">
                        <Input placeholder="管径"/>
                      </Form.Item>
                    )
                  }
                </td>
                <td>
                  {
                    !editing ? '壁厚：' + data.wallThickness : (
                      <Form.Item label="壁厚" name="wallThickness">
                        <Input placeholder="壁厚"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>管道材质：</td>
                <td>
                  {
                    !editing ? data.pipeMaterial : (
                      <Form.Item name="pipeMaterial">
                        <Input placeholder="管道材质"/>
                      </Form.Item>
                    )
                  }
                </td>
                <td>腐蚀介质：</td>
                <td>
                  {
                    !editing ? data.corrosiveMedium : (
                      <Form.Item name="corrosiveMedium">
                        <Input placeholder="腐蚀介质"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>三桩是否齐全：</td>
                <td>
                  {
                    !editing ? (
                      data.isThreePilesComplete
                        ? <span className="badge badge-primary">是</span>
                        : <span className="badge badge-secondary">否</span>
                    ) : (
                      <Form.Item name="isThreePilesComplete" valuePropName="checked">
                        <Switch placeholder="三桩是否齐全" checkedChildren="是" unCheckedChildren="否"/>
                      </Form.Item>
                    )
                  }
                </td>
                <td>管道最大高程差：</td>
                <td>
                  {
                    !editing ? data.pipeMaxElevatDiff : (
                      <Form.Item name="pipeMaxElevatDiff">
                        <Input placeholder="管道最大高程差"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>是否有内图层：</td>
                <td colSpan={data.hasInnerLayer ? 1 : 3}>
                  {
                    !editing ? (
                      data.hasInnerLayer
                        ? <span className="badge badge-primary">是</span>
                        : <span className="badge badge-secondary">否</span>
                    ) : (
                      <Form.Item name="hasInnerLayer" valuePropName="checked">
                        <Switch placeholder="是否有内图层" checkedChildren="是" unCheckedChildren="否"/>
                      </Form.Item>
                    )
                  }
                </td>
                {
                  data.hasInnerLayer ? (
                    <>
                      <td>内图层类型：</td>
                      <td>
                        {
                          !editing ? data.innerLayerType : (
                            <Form.Item name="innerLayerType">
                              <Input placeholder="内图层类型"/>
                            </Form.Item>
                          )
                        }
                      </td>
                    </>
                  ) : null
                }
              </tr>
              <tr>
                <td>是否有并行管道：</td>
                <td colSpan={data.hasParallelPipeline ? 1 : 3}>
                  {
                    !editing ? (
                      data.hasParallelPipeline
                        ? <span className="badge badge-primary">是</span>
                        : <span className="badge badge-secondary">否</span>
                    ) : (
                      <Form.Item name="hasParallelPipeline" valuePropName="checked">
                        <Switch placeholder="是否有并行管道" checkedChildren="是" unCheckedChildren="否"/>
                      </Form.Item>
                    )
                  }
                </td>
                {
                  data.hasParallelPipeline ? (
                    <>
                      <td>并行管道位置：</td>
                      <td>
                        {
                          !editing ? data.parallelPipelineLocation : (
                            <Form.Item name="parallelPipelineLocation">
                              <Input placeholder="并行管道位置"/>
                            </Form.Item>
                          )
                        }
                      </td>
                    </>
                  ) : null
                }
              </tr>
              <tr>
                <td>是否有交叉管道：</td>
                <td colSpan={data.hasCrossPipe ? 1 : 3}>
                  {
                    !editing ? (
                      data.hasCrossPipe
                        ? <span className="badge badge-primary">是</span>
                        : <span className="badge badge-secondary">否</span>
                    ) : (
                      <Form.Item name="hasCrossPipe" valuePropName="checked">
                        <Switch placeholder="是否有交叉管道" checkedChildren="是" unCheckedChildren="否"/>
                      </Form.Item>
                    )
                  }
                </td>
                {
                  data.hasCrossPipe ? (
                    <>
                      <td>交叉管道位置：</td>
                      <td>
                        {
                          !editing ? data.crossPipeLocation : (
                            <Form.Item name="crossPipeLocation">
                              <Input placeholder="交叉管道位置"/>
                            </Form.Item>
                          )
                        }
                      </td>
                    </>
                  ) : null
                }
              </tr>
              <tr>
                <td>管道干线有变径：</td>
                <td colSpan="3">
                  {
                    !editing ? (
                      data.hasCrossPipe
                        ? <span className="badge badge-primary">有</span>
                        : <span className="badge badge-secondary">无</span>
                    ) : (
                      <Form.Item name="pipelineHasReducedDiameter" valuePropName="checked">
                        <Switch placeholder="管道干线有变径" checkedChildren="有" unCheckedChildren="无"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>最大允许电压：</td>
                <td>
                  {
                    !editing ? data.maxAllowVoltage : (
                      <Form.Item name="maxAllowVoltage">
                        <Input placeholder="最大允许电压"/>
                      </Form.Item>
                    )
                  }
                </td>
                <td>流量范围：</td>
                <td>
                  {
                    !editing ? data.flowRangeStart : (
                      <Form.Item name="flowRangeStart">
                        <Input placeholder="流量范围" style={{width: 100, textAlign: 'center'}}/>
                      </Form.Item>
                    )
                  }
                  <div style={{display: 'inline-block', width: 25, textAlign: 'center'}}>~</div>
                  {
                    !editing ? data.flowRangeEnd : (
                      <Form.Item name="flowRangeEnd">
                        <Input placeholder="流量范围" style={{width: 100, textAlign: 'center'}}/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>是否可以输停：</td>
                <td>
                  {
                    !editing ? (
                      data.isCanStop
                        ? <span className="badge badge-primary">是</span>
                        : <span className="badge badge-secondary">否</span>
                    ) : (
                      <Form.Item name="isCanStop" valuePropName="checked">
                        <Switch placeholder="是否可以输停" checkedChildren="是" unCheckedChildren="否"/>
                      </Form.Item>
                    )
                  }
                </td>
                <td>当前运行温度：</td>
                <td>
                  {
                    !editing ? data.runTemperature : (
                      <Form.Item name="runTemperature">
                        <Input placeholder="当前运行温度"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>含蜡量：</td>
                <td>
                  {
                    !editing ? data.waxContent : (
                      <Form.Item name="waxContent">
                        <Input placeholder="含蜡量"/>
                      </Form.Item>
                    )
                  }
                </td>
                <td>含硫化氢(H2S)量：</td>
                <td>
                  {
                    !editing ? data.hydrogenSulfideContent : (
                      <Form.Item name="hydrogenSulfideContent">
                        <Input placeholder="含硫化氢(H2S)量"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>清管器类型：</td>
                <td>
                  {
                    !editing ? data.pigType : (
                      <Form.Item name="pigType">
                        <Input placeholder="清管器类型"/>
                      </Form.Item>
                    )
                  }
                </td>
                <td>清管周期：</td>
                <td>
                  {
                    !editing ? data.pigCycle : (
                      <Form.Item name="pigCycle">
                        <Input placeholder="清管周期"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>清出污物成分：</td>
                <td>
                  {
                    !editing ? data.pigIngredient : (
                      <Form.Item name="pigIngredient">
                        <Input placeholder="清出污物成分"/>
                      </Form.Item>
                    )
                  }
                </td>
                <td>近期单次清出污物重量：</td>
                <td>
                  {
                    !editing ? data.pigWeight : (
                      <Form.Item name="pigWeight">
                        <Input placeholder="近期单次清出污物重量"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>穿孔泄露次数：</td>
                <td>
                  {
                    !editing ? data.perforatLeaksCount : (
                      <Form.Item name="perforatLeaksCount">
                        <Input placeholder="穿孔泄露次数"/>
                      </Form.Item>
                    )
                  }
                </td>
                <td>是否实施过管道维修：</td>
                <td>
                  {
                    !editing ? (
                      data.hasMaintainPipeline
                        ? <span className="badge badge-primary">是</span>
                        : <span className="badge badge-secondary">否</span>
                    ) : (
                      <Form.Item name="hasMaintainPipeline" valuePropName="checked">
                        <Switch placeholder="是否实施过管道维修" checkedChildren="是" unCheckedChildren="否"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>是否发现涂层破损：</td>
                <td colSpan={data.isFindCoatWorn ? 1 : 3}>
                  {
                    !editing ? (
                      data.isFindCoatWorn
                        ? <span className="badge badge-primary">是</span>
                        : <span className="badge badge-secondary">否</span>
                    ) : (
                      <Form.Item name="isFindCoatWorn" valuePropName="checked">
                        <Switch placeholder="是否发现涂层破损" checkedChildren="是" unCheckedChildren="否"/>
                      </Form.Item>
                    )
                  }
                </td>
                {
                  data.isFindCoatWorn ? (
                    <>
                      <td>涂层破损位置：</td>
                      <td>
                        {
                          !editing ? data.coatWornLocation : (
                            <Form.Item name="coatWornLocation">
                              <Input placeholder="涂层破损位置"/>
                            </Form.Item>
                          )
                        }
                      </td>
                    </>
                  ) : null
                }
              </tr>
              <tr>
                <td>修理类型：</td>
                <td>
                  {
                    !editing ? data.repairType : (
                      <Form.Item name="repairType">
                        <Select>
                          <Select.Option value="">请选择</Select.Option>
                          <Select.Option value="熔焊">熔焊</Select.Option>
                          <Select.Option value="补丁">补丁</Select.Option>
                          <Select.Option value="套管">套管</Select.Option>
                          <Select.Option value="换管">换管</Select.Option>
                        </Select>
                      </Form.Item>
                    )
                  }
                </td>
                <td>维修区间：</td>
                <td>
                  {
                    !editing ? data.maintenanceInterval : (
                      <Form.Item name="maintenanceInterval">
                        <Input placeholder="维修区间"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>收发球装置：</td>
                <td colSpan="3">
                  {
                    !editing ? (
                      data.hasSendAndReceiveDevice
                        ? <span className="badge badge-primary">有</span>
                        : <span className="badge badge-secondary">无</span>
                    ) : (
                      <Form.Item name="hasSendAndReceiveDevice" valuePropName="checked">
                        <Switch placeholder="收发球装置" checkedChildren="有" unCheckedChildren="无"/>
                      </Form.Item>
                    )
                  }
                </td>
              </tr>
              {
                data.hasSendAndReceiveDevice ? (
                  <>
                    <tr>
                      <td>类型：</td>
                      <td colSpan="3">
                        {
                          !editing ? (() => {
                              switch (data.sendAndReceiveType) {
                                case 'valveClean':
                                  return '清阀管'
                                case 'standardPitch':
                                  return '标准投球装置'
                                default:
                                  return null
                              }
                            }
                          )() : (
                            <Form.Item name="sendAndReceiveType">
                              <Radio.Group>
                                <Radio value="valveClean">清阀管</Radio>
                                <Radio value="standardPitch">标准投球装置</Radio>
                              </Radio.Group>
                            </Form.Item>
                          )
                        }
                      </td>
                    </tr>
                    {
                      [
                        {id: 'sendDevice', name: '发球装置'},
                        {id: 'receiveDevice', name: '收球装置'},
                        {id: 'otherDevice', name: '其他装置'},
                      ].map(item => {
                        return (
                          <tr>
                            <td>{item.name}：</td>
                            {
                              [0, 1, 2].map(index => {
                                return (
                                  <td style={{textAlign: "center"}}>
                                    {
                                      pipeline[item.id] && pipeline[item.id][index] ? (
                                        <Image height={75} src={pipeline[item.id][index]} fallback={imgPlaceholder}/>
                                      ) : null
                                    }
                                    {
                                      editing ? (
                                        <Form.Item name={item.id + index} style={{marginTop: 10}}>
                                          <FileInput/>
                                        </Form.Item>
                                      ) : null
                                    }
                                  </td>
                                );
                              })
                            }
                          </tr>
                        );
                      })
                    }
                  </>
                ) : null
              }
            </table>
            <div style={{marginTop: 15, textAlign: 'center'}}>
              {
                !editing && App.hasAuth('project.setProject') ? (
                  <Button type="primary" onClick={() => {
                    form.setFieldsValue({...pipeline, productionTime: moment(pipeline.productionTime * 1000)});
                    setEditing(true);
                  }}>编辑</Button>
                ) : null
              }
              {
                editing ? (
                  <Space>
                    <Button type="primary" htmlType="submit" loading={saving}>保存</Button>
                    <Button type="ghost" onClick={() => setEditing(false)}>返回</Button>
                  </Space>
                ) : null
              }
            </div>
          </Form>
        )
      }
    </div>
  );
};