import React from 'react';

export default ({children, value, onChange}) => {
  const triggerChange = changedValue => {
    if (onChange && value != changedValue) {
      onChange(changedValue);
    }
  };
  return (
    <>
      {
        React.Children.map(children, child => {
          return React.cloneElement(child, {
            value: value,
            onBlur: e => {
              triggerChange(e.target.value);
              if (child.props.onBlur) {
                child.props.onBlur(e);
              }
            },
            onPressEnter: e => {
              triggerChange(e.target.value);
              if (child.props.onPressEnter) {
                child.props.onPressEnter(e);
              }
            },
            onStep: (value, info) => {
              triggerChange(value);
              if (child.props.onStep) {
                child.props.onStep(value, info);
              }
            },
          });
        })
      }
    </>
  );
};