import React, {useEffect, useState, useRef} from "react";
import moment from "moment";
import axios from "../../conf/axios";
import {message, Spin} from "antd";
import {useUpdateEffect} from "react-use";
import {useSize} from "ahooks";

const echarts = require('echarts');

const dateTypes = {
  deviceCount: '新增设备',
  projectCount: '新增工作',
  mileage: '新增里程',
};

export default ({modelId = null, companyId = null, userId = null, dataType, date, changeDate}) => {
  const [where, setWhere] = useState({});
  useEffect(() => {
    setWhere(where => {
      return {
        ...where,
        modelId,
        companyId,
        userId,
        dataType,
      }
    });
  }, [modelId, companyId, userId, dataType]);

  const view = useRef();
  const viewSize = useSize(view);

  const [yearChart, setYearChart] = useState();
  const [yearCharting, setYearCharting] = useState(true);
  const [year, setYear] = useState();
  const [years, setYears] = useState([]);
  useEffect(() => {
    let year = date.year();
    setYear(year);
    let years = [];
    for (let i = 0; i < 5; i++) {
      years.push(moment().year() - i);
    }
    setYears(years);
  }, [date]);
  useEffect(() => {
    let yearChart = echarts.init(document.getElementById('yearChart'));
    setYearChart(yearChart);
    yearChart.getZr().off('click');
    yearChart.getZr().on('click', params => {
      let pointInPixel = [params.offsetX, params.offsetY]
      if (yearChart.containPixel('grid', pointInPixel)) {
        let xIndex = yearChart.convertFromPixel({seriesIndex: 0}, [params.offsetX, params.offsetY])[0];
        changeDate(moment(date.year(years[xIndex])));
      }
    });
  }, [years, date]);
  const yearChartRender = () => {
    setYearCharting(true);
    axios.get('/statistics/user', {params: {...where}})
      .finally(() => {
        setYearCharting(false);
      })
      .then(response => {
        let {user, datas} = response.data.data;
        let option = {
          title: {
            text: '近年' + dateTypes[dataType],
          },
          /*color: ['#00a45a', '#e5323e'],*/
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: user
          },
          toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
              mark: {show: true},
              dataView: {show: true, readOnly: false},
              magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
              restore: {show: true},
              saveAsImage: {show: true}
            }
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {show: false},
              data: years
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: datas.map(item => {
            item.data = item[dataType];
            return {
              name: item.name,
              type: 'line',
              barGap: 0,
              label: {
                show: true,
                position: 'top',
              },
              data: item.data
            };
          })
        };
        yearChart.setOption(option, true, true);
      })
      .catch(error => {
        message.error(error.message);
      });
  }
  useUpdateEffect(() => {
    yearChartRender();
  }, [where]);
  useUpdateEffect(() => {
    yearChart.resize();
  }, [viewSize]);

  const [monthChart, setMonthChart] = useState();
  const [monthCharting, setMonthCharting] = useState(true);
  const [month, setMonth] = useState();
  useEffect(() => {
    setMonth(date.month());
  }, [date]);
  useEffect(() => {
    let monthChart = echarts.init(document.getElementById('monthChart'));
    setMonthChart(monthChart);
    monthChart.getZr().off('click');
    monthChart.getZr().on('click', params => {
      let pointInPixel = [params.offsetX, params.offsetY]
      if (monthChart.containPixel('grid', pointInPixel)) {
        let xIndex = monthChart.convertFromPixel({seriesIndex: 0}, [params.offsetX, params.offsetY])[0];
        changeDate(moment(date.month(xIndex)));
      }
    });
  }, [date]);
  const monthChartRender = () => {
    setMonthCharting(true);
    axios.get('/statistics/user', {params: {...where, date: date.year()}})
      .finally(() => {
        setMonthCharting(false);
      })
      .then(response => {
        let {user, datas} = response.data.data;
        let option = {
          title: {
            text: date.format('YYYY年') + dateTypes[dataType],
          },
          /*color: ['#00a45a', '#e5323e'],*/
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: user
          },
          toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
              mark: {show: true},
              dataView: {show: true, readOnly: false},
              magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
              restore: {show: true},
              saveAsImage: {show: true}
            }
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {show: false},
              data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: datas.map(item => {
            item.data = item[dataType];
            return {
              name: item.name,
              type: 'line',
              barGap: 0,
              label: {
                show: true,
                position: 'top',
              },
              data: item.data
            };
          })
        };
        monthChart.setOption(option, true, true);
      })
      .catch(error => {
        message.error(error.message);
      });
  }
  useUpdateEffect(() => {
    monthChartRender();
  }, [where, year]);
  useUpdateEffect(() => {
    monthChart.resize();
  }, [viewSize]);

  const [dayChart, setDayChart] = useState();
  const [dayCharting, setDayCharting] = useState(true);
  useEffect(() => {
    setDayChart(echarts.init(document.getElementById('dayChart')));
  }, []);
  const dayChartRender = () => {
    setDayCharting(true);
    let dayCount = date.daysInMonth();
    let days = [];
    for (let i = 1; i <= dayCount; i++) {
      days.push(i);
    }
    axios.get('/statistics/user', {params: {...where, date: date.format('YYYY-MM')}})
      .finally(() => {
        setDayCharting(false);
      })
      .then(response => {
        let {user, datas} = response.data.data;
        let option = {
          title: {
            text: date.format('YYYY年MM月') + dateTypes[dataType],
          },
          /*color: ['#00a45a', '#e5323e'],*/
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: user
          },
          toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
              mark: {show: true},
              dataView: {show: true, readOnly: false},
              magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
              restore: {show: true},
              saveAsImage: {show: true}
            }
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {show: false},
              data: days
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: datas.map(item => {
            item.data = item[dataType];
            return {
              name: item.name,
              type: 'line',
              barGap: 0,
              label: {
                show: true,
                position: 'top',
              },
              data: item.data
            };
          })
        };
        dayChart.setOption(option, true, true);
      })
      .catch(error => {
        message.error(error.message);
      });
  }
  useUpdateEffect(() => {
    dayChartRender();
  }, [where, year, month]);
  useUpdateEffect(() => {
    dayChart.resize();
  }, [viewSize]);

  return (
    <div className="statistics-device" ref={view}>
      <Spin spinning={yearCharting}>
        <div style={{height: 333}} id="yearChart" className="yearChart"/>
      </Spin>
      <Spin spinning={monthCharting}>
        <div style={{height: 333}} id="monthChart" className="monthChart"/>
      </Spin>
      <Spin spinning={dayCharting}>
        <div style={{height: 333}} id="dayChart" className="dayChart"/>
      </Spin>
    </div>
  );
};