import {message, Spin, Card, Image, Pagination, Empty, PageHeader} from "antd";
import React, {useEffect, useState} from "react";
import axios from "../../conf/axios";
import {imgPlaceholder} from "../../conf/general";
import moment from "moment";
import {useUpdateEffect} from "react-use";

export default ({project}) => {
  const [imgsLoading, setImgsLoading] = useState(true);
  const [misspoints, setMisspoints] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  });
  const imgsLoad = () => {
    setImgsLoading(true);
    axios.post('/project/getMisspoints', {
      id: project.id,
      pageSize: pagination.pageSize,
      currentPage: pagination.current,
    })
      .then(response => {
        let {misspoints, pagination} = response.data.data;
        setMisspoints(misspoints);
        setPagination(oldPagination => {
          oldPagination.pageSize = pagination.perPage;
          oldPagination.current = pagination.currentPage;
          oldPagination.total = pagination.totalCount;
          return oldPagination;
        });
        setImgsLoading(false);
      })
      .catch(error => {
        message.error(error.message);
      });
  }
  useEffect(() => {
    imgsLoad();
  }, []);
  useUpdateEffect(() => {
    imgsLoad();
  }, [pagination]);

  return (
    <div className="project-misspoint">
      <Spin spinning={imgsLoading} tip="loading">
        <div className="project-misspoint-list">
          {
            misspoints.length > 0 ? (
              <div className="project-misspoint-items">
                {
                  misspoints.map(misspoint => {
                    return (
                      <div className="project-misspoint-item clearfix">
                        <div className="wraper">
                          <div className="container">
                            <div className="img">
                              <Image src={misspoint.img} fallback={imgPlaceholder}/>
                            </div>
                            <div className="text">
                              <div className="time">
                                {misspoint.createTime ? moment(misspoint.createTime * 1000).format('YYYY-MM-DD HH:mm:ss') : ''}
                              </div>
                              <div className="explain">
                                {misspoint.explain}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            ) : (imgsLoading ? null : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>)
          }
          <div style={{marginTop: 17.5, textAlign: "center"}}>
            <Pagination
              {...pagination}
              hideOnSinglePage
              onChange={(page, pageSize) => setPagination({...pagination, current: page, pageSize: pageSize})}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
};