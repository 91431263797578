const App = {
  auths: [
    "ad", "ad.addAd", "ad.deleteAd", "ad.getAd", "ad.setAd", "appVersion","appConfig", "appVersion.addAppVersion", "appVersion.deleteAppVersion", "appVersion.getAppVersion", "appVersion.setAppVersion", "banner", "banner.addBanner", "banner.deleteBanner", "banner.getBanner", "banner.setBanner", "company", "company.addCompany", "company.deleteCompany", "company.getCompany", "company.setCompany", "device", "device.addDevice", "device.deleteDevice", "device.getDevice", "device.setDevice", "deviceFault", "deviceFault.addDeviceFault", "deviceFault.deleteDeviceFault", "deviceFault.getDeviceFault", "deviceFault.setDeviceFault", "deviceModel", "deviceModel.addDeviceModel", "deviceModel.deleteDeviceModel", "deviceModel.getDeviceModel", "deviceModel.setDeviceModel", "feedback", "feedback.addFeedback", "feedback.deleteFeedback", "feedback.getFeedbackList", "feedback.setFeedback", "project", "project.addProject", "project.deleteProject", "project.getProject", "project.setProject", "projectScheme", "projectScheme.addProjectScheme", "projectScheme.deleteProjectScheme", "projectScheme.getProjectScheme", "projectScheme.setProjectScheme", "statistics", "user", "user.addUser", "user.deleteUser", "user.getUser", "user.setUser"
  ],
  needAuth: function (auth) {
    let auths = auth instanceof Array ? auth : [auth];
    for (const auth of auths) {
      if (this.auths.indexOf(auth) != -1) {
        return true;
      }
    }
    return false;
  },
  hasAuth: function (auth) {
    if (this.needAuth(auth)) {
      return this.user.authId.indexOf(auth) != -1;
    }
    return true;
  },
  hasAuths: function (auth, ...args) {
    let auths = [];
    if (auth instanceof Array) {
      auths = auth;
    } else {
      auths = [auth, ...args];
    }
    if (!this.needAuth(auths)) {
      return true;
    }
    for (const auth of auths) {
      if (this.user.authId.indexOf(auth) == -1) {
        return false;
      }
    }
    return true;
  },
  hasOneAuths: function (auth, ...args) {
    let auths = [];
    if (auth instanceof Array) {
      auths = auth;
    } else {
      auths = [auth, ...args];
    }
    if (!this.needAuth(auths)) {
      return true;
    }
    for (const auth of auths) {
      if (this.user.authId.indexOf(auth) != -1) {
        return true;
      }
    }
    return false;
  }
};

export default App;