import React, {useState} from 'react';
import {Menu, Dropdown} from 'antd';
import './../assets/twitter-bootstrap/4.5.3/css/bootstrap.min.css';
import './../css/bootstrap.css';
import './../css/css.css';
import {Link, Route, Switch} from "react-router-dom";
import {routes2} from "../conf/routes";
import Auth from "../libs/Auth";
import App from "../libs/App";
import {message, Modal} from "antd";
import axios from "../conf/axios";
import {AppstoreOutlined, CodepenOutlined, CopyOutlined, CustomerServiceOutlined, DatabaseOutlined, EditOutlined, HomeOutlined, LogoutOutlined, PictureOutlined, ProjectOutlined, SettingFilled, UsergroupAddOutlined, UserOutlined, NodeCollapseOutlined, WarningOutlined, NumberOutlined, FunctionOutlined} from "@ant-design/icons";
import {v4 as uuidv4} from "uuid";

const {SubMenu} = Menu;

export default props => {
  const [routeForceRenderKey, setRouteForceRenderKey] = useState();
  const [collapsed, setCollapsed] = useState(localStorage.getItem('collapsed') == '1');

  const defaultSelectedKeys = [];

  let match = props.location.pathname.match(/^(\/.*)(\/.*)*/);
  if (match) {
    defaultSelectedKeys.push(match[1] + '/');
  }

  const switchCollapsed = () => {
    localStorage.setItem('collapsed', !collapsed ? '1' : '0');
    setCollapsed(!collapsed);
  }

  for (const key in props) {
    App[key] = props[key];
  }

  App.logout = () => {
    Modal.confirm({
      title: '确定注销吗',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        message.loading({content: '...'});
        axios.post('/logout')
            .finally(() => {
              Auth.deleteToken();
              message.destroy();
              App.history.push('/login');
            })
      }
    });
  }

  const {user} = App;

  let menus = [
    {url: '/company', auth: 'company', name: '单位信息', icon: <UsergroupAddOutlined/>},
    {url: '/user', auth: 'user', name: '人员信息', icon: <UserOutlined/>},
    {url: '/device', auth: 'device', name: '设备管理', icon: <SettingFilled/>},
    {url: '/project', auth: 'project', name: '工作管理', icon: <ProjectOutlined/>},
    {url: '/statistics', auth: 'statistics', name: '数据统计', icon: <DatabaseOutlined/>},
    {
      id: 'other',
      name: '其他管理',
      icon: <EditOutlined/>,
      subs: [
        {url: '/deviceFault', auth: 'deviceFault', name: '设备故障', icon: <WarningOutlined/>},
        {url: '/deviceModel', auth: 'deviceModel', name: '设备型号', icon: <NumberOutlined/>},
        {url: '/projectScheme', auth: 'projectScheme', name: '方案模版', icon: <FunctionOutlined/>},
        {url: '/appVersion', auth: 'appVersion', name: 'app版本', icon: <AppstoreOutlined/>},
        {url: '/appConfig', auth: 'appConfig', name: 'app配置', icon: <AppstoreOutlined/>},
        {url: '/ad', auth: 'ad', name: '广告图', icon: <PictureOutlined/>},
        {url: '/banner', auth: 'banner', name: '轮播图', icon: <PictureOutlined/>},
        {url: '/feedback', auth: 'feedback', name: '反馈', icon: <CustomerServiceOutlined/>},
      ]
    }
  ];
  menus = menus.filter(item => {
    if (item.subs) {
      item.subs = item.subs.filter(item => {
        return App.hasAuth(item.auth);
      });
      return item.subs.length > 0;
    } else {
      return App.hasAuth(item.auth);
    }
  });

  return (
      <div className={'indexContainer ' + (collapsed ? 'collapsed' : '')}>
        <div className="head">
          <div className="logo">斯耐科管家平台</div>
          <div className="options">
            <Dropdown overlay={(
                <Menu>
                  <Menu.Item>
                    <Link to="/profile">基本资料</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/password">修改密码</Link>
                  </Menu.Item>
                </Menu>
            )}>
              <Link to="/profile">
                <div className="avatar"><img src={user.avatar} alt=""/></div>
                <div className="name">{user.name}</div>
              </Link>
            </Dropdown>
            <div className="option" onClick={App.logout}>退出</div>
          </div>
        </div>
        <div className="left">
          <div className="leftContainer">
            <Menu
                mode="inline"
                defaultSelectedKeys={defaultSelectedKeys ? defaultSelectedKeys : ['/']}
                defaultOpenKeys={collapsed ? [] : ['other']}
                style={{height: '100%'}}
                inlineCollapsed={collapsed}
            >
              <Menu.Item key="/" icon={<HomeOutlined/>}>
                <Link to="/" onClick={() => setRouteForceRenderKey(uuidv4().toString())}>首页</Link>
              </Menu.Item>
              {
                menus.map(item => {
                  if (item.subs) {
                    return <SubMenu key={item.id} title={item.name} icon={item.icon}>
                      {
                        item.subs.map(item => {
                          return <Menu.Item key={item.url + '/'} icon={item.icon}>
                            <Link to={item.url} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>{item.name}</Link>
                          </Menu.Item>;
                        })
                      }
                    </SubMenu>
                  } else {
                    return <Menu.Item key={item.url + '/'} icon={item.icon}>
                      <Link to={item.url} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>{item.name}</Link>
                    </Menu.Item>;
                  }
                })
              }
              <SubMenu key="my" title="个人中心" icon={<CopyOutlined/>}>
                <Menu.Item key="/profile/" icon={<DatabaseOutlined/>}><Link to="/profile">基本资料</Link></Menu.Item>
                <Menu.Item key="/password/" icon={<CodepenOutlined/>}><Link to="/password">修改密码</Link></Menu.Item>
                <Menu.Item key="/logout/" icon={<LogoutOutlined/>} onClick={App.logout}>注销登陆</Menu.Item>
              </SubMenu>
            </Menu>
          </div>
          <div className="leftSwitch" onClick={() => switchCollapsed()}>
            <NodeCollapseOutlined/>
          </div>
        </div>
        <div className="main">
          <div className="mainContainer">
            <Switch>
              {
                routes2.map(route => {
                  return (
                      <Route key={route.path} path={route.path} exact={route.exact} render={props => {
                        document.title = route.title;
                        return <route.component {...props} key={routeForceRenderKey}/>
                      }}/>
                  );
                })
              }
            </Switch>
          </div>
        </div>
      </div>
  );
};