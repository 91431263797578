import {Button, message, Skeleton, Upload} from "antd";
import ProjectSchemeInterface from "../projectScheme/Interface";
import React, {useEffect, useState} from "react";
import axios from "../../conf/axios";
import App from "../../libs/App";

export default ({project}) => {
  const [loading, setLoading] = useState(true);
  const [inspect, setInspect] = useState({});
  const load = () => {
    setLoading(true);
    axios.post('/project/getInspect', {id: project.id})
      .then(response => {
        let {inspect} = response.data.data;
        setInspect(inspect);
        setLoading(false);
      })
      .catch(error => {
        message.error(error.message);
      });
  }
  useEffect(() => {
    load();
  }, []);
  const [projectSchemeVisible, setProjectSchemeVisible] = useState(false);

  return (
    <>
      {
        loading ? <Skeleton active/> : (
          <>
            {inspect.scheme ? (
              <a href={inspect.scheme.file} target="_blank">{inspect.scheme.name}</a>
            ) : null}
            {
              App.hasAuth('project.setProject') ? (
                <div style={{marginTop: 10}}>
                  {!inspect.isScheme ? (
                    <Button type="primary" onClick={() => {
                      setProjectSchemeVisible(true);
                    }}>选择方案</Button>
                  ) : null}
                  {inspect.isScheme ? (
                    <Button type="primary" onClick={() => {
                      setProjectSchemeVisible(true);
                    }}>重新选择</Button>
                  ) : null}
                </div>
              ) : null
            }
            <ProjectSchemeInterface
              visible={projectSchemeVisible}
              data={inspect}
              onCancel={() => setProjectSchemeVisible(false)}
              onSelect={record => {
                setInspect(inspect => {
                  return {...inspect, isScheme: true, scheme: record};
                });
              }}
            />
          </>
        )
      }
    </>
  );
}