import {Button, message, Spin, Upload, Pagination,Input, Empty, notification} from "antd";
import React, {useEffect, useState, Component} from "react";
import axios from "../../conf/axios";
import moment from "moment";
import App from "../../libs/App";
import {useUpdateEffect} from "react-use";
import Script from 'react-load-script';

import videojs from "video.js";
import "video.js/dist/video-js.css";
import './../../css/video.css';

const fs =  require('fs');
const  XLSX = require('xlsx');


export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project:props.project,
      pagination: { pageSize: 24,
        current: 1,
        total: 0,},
      loading: false,
      data: [],
      videoData:[],//视频测厚里程数据
    }
   
  }
  componentDidMount() {
    this.videosLoad();
    // instantiate Video.js
   this.xlsread();
    
  }


 videosLoad = (params = {}) => {
    this.setState({loading: true});
    let where = {
      id: this.state.project.id,
      pageSize: this.state.pagination.pageSize,
      currentPage: this.state.pagination.current,
    };
    axios.post('/project/getVideos', where)
        .then(response => {
          let data = response.data.data;
          console.log(data)
          let pagination = data.pagination;
          this.setState({
            loading: false,
            data: data.videos,
            pagination: {
              current: pagination.currentPage,
              pageSize: pagination.perPage,
              total: pagination.totalCount,
            },
            videoData:data.videoData,
          });
          this.videos();
        })
        .catch(error => {
          message.error(error.message);
        });
  };

   videos = (params = {}) => {
     const tx=this.state.videoData;
    this.state.data.forEach((item)=>{
        setTimeout(function() {
          this.player = videojs('demo-video'+item.id, {playbackRates: [0.25,0.5,1,1.5,2,3],}, function onPlayerReady() {
             var time1;
              var rate=1;
              function aa() {
                  var currentTime = document.getElementById("demo-video"+item.id).firstChild.currentTime;
                //  document.getElementById('bb'+item.id).value = currentTime;
                 var ss=Math.round(currentTime*1000);
                 //范围判断找接近的值返回
                 var minData=Math.abs(ss-tx[0].time);
                 var k=0;
                 if(minData>0){
                    tx.forEach((item1,index)=>{
                      if(index>0){
                        var t=Math.abs(ss-item1.time);
                        if(t==0){
                           minData=t;
                           k=index;
                           return false;
                        }else if(t<minData){
                           minData=t;
                           k=index;
                        }
                      }
                    })      
                 }
                document.getElementById('lc'+item.id).value = tx[k].mileage;
                document.getElementById('aa'+item.id).value = tx[k].thickness;
              }
              this.on('play', function () {
                time1 = setInterval(function () {
                   aa();
                }, 10);                               
             });  
          });
        }, 5000);
    });
  };


  xlsread = (callback) => {
//js读取excel文件
 
  }
  
  fetch() {
        return new Promise(function(resolve, reject) {
            fetch('../../test.xls').then(function (response) {
                return response.json();
            }).then(function (data) {
                 resolve(data);
            }).catch(function (e) {
                 reject(e);
                console.log("Oops, error");
            });
        });
    }


  render() {
    return (
      <div>
      <div className="projectVideo">
      <Spin spinning={this.state.loading} tip="loading">
        <div className="videosBox">
      {
            this.state.data.length > 0 ? (
              <div className="videosContainer">
                {
                  this.state.data.map(video => {
                    return (
                      <div className="videoItem">
                        <div className="videoWraper">
                          <div className="videoContainer">
                            <video id={'demo-video' + video.id} className="video-js vjs-default-skin" controls preload="none" data-setup="{}">
                               <source id="demo" src={video.video} type="video/mp4" />
                            </video>
                          </div>
                        </div>
                        <div className="videoText1">
                            里程：<Input id={'lc' + video.id} value="0"/>
                            厚度：<Input id={'aa' + video.id} value="0"/>
                          {video.createTime ? moment(video.createTime * 1000).format('YYYY-MM-DD HH:mm:ss') : ''}
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            ) : (this.state.loading ? null : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
          }
               </div>
      </Spin>
    </div>
      </div>
    )
  }
}