import React, {useEffect, useState} from "react";
import {Button, Form, Input, InputNumber, message, Space} from "antd";
import moment from "moment";
import axios from "../../conf/axios";
import redux from "./redux";
import App from "../../libs/App";

export default ({project}) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const save = values => {
    if (saving) {
      return true;
    }
    setSaving(true);
    axios.post('/project/setProject', values)
      .finally(() => {
        setSaving(false);
      })
      .then(response => {
        redux.dispatch({
          type: 'type',
          data: values
        });
        message.success('保存成功');
      })
      .catch(error => {
        message.error(error.message);
      });
  }
  return (
    <div style={{width: 555}}>
      <Form className="ant-form-table" initialValues={project} onFinish={save}>
        <Form.Item name="id" label="id" hidden={true}>
          <Input placeholder="id"/>
        </Form.Item>
        <table className="table table-bordered">
          <tr>
            <td width="125">项目类型：</td>
            <td>{project.model ? project.model.name : null}</td>
          </tr>
          <tr>
            <td>项目名称：</td>
            <td>
              {
                !editing ? project.name : (
                  <Form.Item name="name">
                    <Input placeholder="项目名称" style={{width: '75%'}}/>
                  </Form.Item>
                )
              }
            </td>
          </tr>
          <tr>
            <td>设备名称：</td>
            <td>{project.device ? project.device.identifier : null}</td>
          </tr>
          <tr>
            <td>施工单位：</td>
            <td>{project.company ? project.company.name : null}</td>
          </tr>
          <tr>
            <td>施工地址：</td>
            <td>{project.constructAddress}</td>
          </tr>
          <tr>
            <td>施工时间：</td>
            <td>
              {project.constructTime ? moment(project.constructTime * 1000).format('YYYY-MM-DD HH:mm:ss') : ''}
            </td>
          </tr>
          <tr>
            <td>管线长度：</td>
            <td>
              {
                !editing ? project.pipelineLength : (
                  <Form.Item name="pipelineLength">
                    <InputNumber placeholder="管线长度" style={{width: 150}}/>
                  </Form.Item>
                )
              }
            </td>
          </tr>
          <tr>
            <td>管线直径：</td>
            <td>
              {
                !editing ? project.pipelineDiameter : (
                  <Form.Item name="pipelineDiameter">
                    <InputNumber placeholder="管线直径" style={{width: 150}}/>
                  </Form.Item>
                )
              }
            </td>
          </tr>
          <tr>
            <td>管线壁厚：</td>
            <td>
              {
                !editing ? project.pipelineThickness : (
                  <Form.Item name="pipelineThickness">
                    <InputNumber placeholder="管线壁厚" style={{width: 150}}/>
                  </Form.Item>
                )
              }
            </td>
          </tr>
          {
            project.modelId == 'SPLR' || project.modelId == 'SPSR' ? (
              <>
                <tr>
                  <td>涂层厚度：</td>
                  <td>
                    {
                      !editing ? project.coatThickness : (
                        <Form.Item name="coatThickness">
                          <InputNumber placeholder="涂层厚度" style={{width: 150}}/>
                        </Form.Item>
                      )
                    }
                  </td>
                </tr>
                <tr>
                  <td>补口宽度：</td>
                  <td>
                    {
                      !editing ? project.makeWidth : (
                        <Form.Item name="makeWidth">
                          <InputNumber placeholder="补口宽度" style={{width: 150}}/>
                        </Form.Item>
                      )
                    }
                  </td>
                </tr>
                <tr>
                  <td>补口总量：</td>
                  <td>
                    {
                      !editing ? project.makeTotal : (
                        <Form.Item name="makeTotal">
                          <InputNumber placeholder="补口总量" style={{width: 150}}/>
                        </Form.Item>
                      )
                    }
                  </td>
                </tr>
              </>
            ) : null
          }
        </table>
        <div style={{marginTop: 10, textAlign: 'center'}}>
          {
            !editing && App.hasAuth('project.setProject') ? (
              <Button type="primary" onClick={() => setEditing(true)}>编辑</Button>
            ) : null
          }
          {
            editing ? (
              <Space>
                <Button type="primary" htmlType="submit" loading={saving}>保存</Button>
                <Button type="ghost" onClick={() => setEditing(false)}>返回</Button>
              </Space>
            ) : null
          }
        </div>
      </Form>
    </div>
  );
};