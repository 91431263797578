import {AutoComplete, Avatar, Button, Card, DatePicker, Drawer, Form, Input, message, Popconfirm, Select, Skeleton, Space, Table} from "antd";
import moment from "moment";
import React, {useEffect, useState} from "react";
import axios from "../../conf/axios";
import {SearchOutlined} from '@ant-design/icons';
import App from "../../libs/App";

export default () => {
  const [whereForm] = Form.useForm();
  const [where, setWhere] = useState({
    pagination: {
      pageSize: 10,
      currentPage: 1,
    }
  });

  const whereChange = values => {
    values = whereForm.getFieldsValue();
    for (var i in values) {
      where[i] = values[i];
    }
    where.pagination.currentPage = 1;
    fetch();
  };

  const [modelsLoading, setModelsLoading] = useState(false);
  const [models, setModels] = useState([]);
  const [specificats, setSpecificats] = useState([]);
  useEffect(() => {
    setModelsLoading(true);
    axios.post('/deviceModel/getDeviceModels')
      .finally(() => {
        setModelsLoading(false);
      })
      .then(response => {
        setModels(response.data.data.deviceModels);
      })
      .catch(error => {
        message.error(error.message);
      });
  }, []);

  const [companyKwOptions, setCompanyKwOptions] = useState([]);
  const onCompanyKwSearch = value => {
    if (App.user.isAdmin) {
      axios.post('/company/getCompanys', {kw: value})
        .then(response => {
          const companys = response.data.data.companys.map(company => ({
            value: company.name,
          }));
          setCompanyKwOptions(companys);
        })
        .catch(error => {
          message.error(error.message);
        });
    }
  };

  const columns = [
    {
      title: '名称',
      dataIndex: 'identifier'
    },
    {
      title: '别名',
      dataIndex: 'name'
    },
    {
      title: '型号规格',
      dataIndex: 'model',
      width: 250,
      render: (value, record) => <>
        <Space>
          <Avatar src={record.model?.img} alt={record.model?.name} size={30}/>
          {record.model?.name} {record.specificat?.name}
        </Space>
      </>
    },
    {
      title: '单位',
      dataIndex: 'company',
      render: value => value.name
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 200,
      render: (value, record) => {
        return (
          <Select style={{width: 120}} value={value} onSelect={value => {
            save({id: record.id, status: value}, false);
          }}>
            <Select.Option value="use">使用中</Select.Option>
            <Select.Option value="disable">禁用</Select.Option>
            <Select.Option value="repair">维修</Select.Option>
          </Select>
        );
      }
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      width: 200,
      render: value => {
        return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '';
      }
    },
    {
      title: '操作',
      key: 'id',
      width: 200,
      render: (text, record) => (
        <Space>
          {
            App.hasAuth('device.setDevice') ? (
              <Button type="primary" size="small" onClick={() => edit(record)}>编辑</Button>
            ) : null
          }
          {
            App.hasAuth('device.deleteDevice') ? (
              <Popconfirm title="确定删除此设备吗" onConfirm={() => adelete(record)}>
                <Button type="primary" size="small">删除</Button>
              </Popconfirm>
            ) : null
          }
        </Space>
      ),
    },
  ];
  useEffect(() => {
    if (!App.user.isAdmin) {
      columns.splice(2, 1);
    }
    if (!App.hasOneAuths('device.setDevice', 'device.deleteDevice')) {
      columns.splice(columns.length - 1, 1);
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [pagination, setPagination] = useState([]);
  const tableChange = (pagination, filters, sorter) => {
    where.pagination = {
      pageSize: pagination.pageSize,
      currentPage: pagination.current,
    }
    fetch();
  };

  const fetch = (params = {}) => {
    setLoading(true);
    axios.post('/device/getDevices', where)
      .then(response => {
        let data = response.data.data;
        let pagination = data.pagination;
        setLoading(false);
        setDatas(data.devices);
        setPagination({
          current: pagination.currentPage,
          pageSize: pagination.perPage,
          total: pagination.totalCount,
        });
      })
      .catch(error => {
        message.error(error.message);
      });
  };
  useEffect(() => {
    fetch();
  }, []);

  const adelete = values => {
    axios.post('/device/deleteDevice', values)
      .then(response => {
        message.success('删除成功');
        fetch();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  const [editIs, setEditIs] = useState(false);
  const [editLoading, setEditLoading] = useState(true);
  const [record, setRecord] = useState({});
  const [editSubmitting, setEditSubmitting] = useState(false);
  const [editForm] = Form.useForm();
  const [companys, setCompanys] = useState([]);
  const [users, setUsers] = useState([]);
  const edit = values => {
    setRecord(values);
    setEditIs(true);
    setEditLoading(true);
    axios.post('/user/getUsers')
      .then(response => {
        setUsers(response.data.data.users);
        if (values) {
          if (values.modelId) {
            models.forEach(model => {
              if (model.id == values.modelId) {
                setSpecificats(model.specificats);
              }
            });
          }
          editForm.setFieldsValue(values);
        } else {
          editForm.resetFields();
        }
      })
      .then(response => {
        if (App.user.isAdmin) {
          return axios.post('/company/getCompanys')
            .then(response => {
              setCompanys(response.data.data.companys);
            });
        } else {
          return Promise.resolve();
        }
      })
      .finally(() => {
        setEditLoading(false);
      })
      .catch(error => {
        message.error(error.message);
      });
  }
  const editClose = () => {
    setEditIs(false);
    setEditLoading(false);
  }

  const save = (values, editor) => {
    if (editor) {
      if (editSubmitting) {
        return;
      }
      setEditSubmitting(true);
    }
    axios.post(values.id ? '/device/setDevice' : '/device/addDevice', values)
      .finally(() => {
        if (editor) {
          setEditSubmitting(false);
        }
      })
      .then(response => {
        if (editor) {
          setEditIs(false);
          setEditLoading(true);
          message.success('保存成功');
        }
        fetch();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  return (
    <Card title="设备管理" extra={App.hasAuth('device.addDevice') ? <Button type="primary" onClick={() => edit()}>添加</Button> : null}>
      <div className="where">
        <Form form={whereForm} onFinish={whereChange}>
          <div className="where-container">
            <div className="where-input">
              <Form.Item name="kw" label="关键字">
                <Input style={{width: 200}} placeholder="关键字" allowClear={true}/>
              </Form.Item>
              <Form.Item name="identifier" label="名称">
                <Input style={{width: 200}} placeholder="名称" allowClear={true}/>
              </Form.Item>
              <Form.Item name="name" label="别名">
                <Input style={{width: 200}} placeholder="别名" allowClear={true}/>
              </Form.Item>
              <Form.Item name="modelId" label="型号">
                <Select
                    style={{width: 150}}
                    defaultValue=""
                    placeholder="型号"
                    loading={modelsLoading}
                    onChange={(value, option) => {
                      if (option.key) {
                        let model = models[option.key - 1];
                        setSpecificats(model.specificats);
                      }
                    }}
                >
                  <Select.Option value="">全部型号</Select.Option>
                  {models.map((value, index) => <Select.Option value={value.id} key={index + 1}>{value.name}</Select.Option>)}
                </Select>
              </Form.Item>
              <Form.Item name="specificatId" label="规格">
                <Select style={{width: 125}} defaultValue="" placeholder="规格" loading={modelsLoading} onChange={whereChange}>
                  <Select.Option value="">全部规格</Select.Option>
                  {specificats.map(value => <Select.Option value={value.id}>{value.name}</Select.Option>)}
                </Select>
              </Form.Item>
              {
                App.user.isAdmin ? (
                    <Form.Item name="companyKw" label="单位">
                      <AutoComplete
                          options={companyKwOptions}
                          style={{width: 225}}
                          onSearch={onCompanyKwSearch}
                          placeholder="单位"
                          allowClear={true}
                          onSelect={whereChange}
                      />
                    </Form.Item>
                ) : null
              }
              <Form.Item name="status" label="状态">
                <Select style={{width: 100}} placeholder="状态">
                  <Select.Option value="">全部状态</Select.Option>
                  <Select.Option value="use">使用中</Select.Option>
                  <Select.Option value="disable">禁用</Select.Option>
                  <Select.Option value="repair">维修</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="time" label="创建时间">
                <DatePicker.RangePicker placeholder="起始" onChange={whereChange}/>
              </Form.Item>
            </div>
            <div className="where-operate">
              <Button type="primary" htmlType="submit" icon={<SearchOutlined/>} loading={loading}>搜索</Button>
            </div>
          </div>
        </Form>
      </div>
      <Table columns={columns} dataSource={datas} pagination={pagination} loading={loading} onChange={tableChange}/>
      <Drawer title="设备管理" width={444} placement="right" visible={editIs} onClose={editClose}>
        {
          editLoading ? <Skeleton active/> : (
            <Form
              bordered form={editForm}
              layout="vertical"
              initialValues={{
                companyId: App.user.company ? App.user.company.id : null,
                userId: App.user.id,
                status: 'use',
              }}
              onFinish={value => {
                save(value, true)
              }}
            >
              <Form.Item name="id" label="id" hidden={true}>
                <Input placeholder="id"/>
              </Form.Item>
              <Form.Item name="identifier" label="名称" rules={[{required: true}]}>
                <Input allowClear={true} placeholder="名称" disabled={!!record?.id}/>
              </Form.Item>
              <Form.Item name="modelId" label="型号" rules={[{required: true}]}>
                <Select
                  style={{width: 333}}
                  placeholder="选择型号"
                  onChange={(value, option) => {
                    if (option.key) {
                      let model = models[option.key - 1];
                      setSpecificats(model.specificats);
                    }
                  }}
                  disabled={!!record?.id}
                >
                  {
                    models.map((value, index) => {
                      return <Select.Option value={value.id} key={index + 1}>{value.name}</Select.Option>
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item name="specificatId" label="规格" rules={[{required: true}]}>
                <Select style={{width: 333}} placeholder="选择规格" disabled={!!record?.id}>
                  {
                    specificats.map(value => {
                      return <Select.Option value={value.id}>{value.name}</Select.Option>
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item name="name" label="别名" rules={[{required: true}]}>
                <Input allowClear={true} placeholder="别名"/>
              </Form.Item>
              {
                App.user.isAdmin ? (
                  <Form.Item name="companyId" label="单位" rules={[{required: true}]}>
                    <Select style={{width: 333}} placeholder="选择单位">
                      {
                        companys.map(company => {
                          return <Select.Option value={company.id}>{company.name}</Select.Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                ) : null
              }
              <Form.Item name="status" label="状态">
                <Select style={{width: 120}} placeholder="选择状态">
                  <Select.Option value="use">使用中</Select.Option>
                  <Select.Option value="disable">禁用</Select.Option>
                  <Select.Option value="repair">维修</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={editSubmitting}>保存</Button>
              </Form.Item>
            </Form>
          )
        }
      </Drawer>
    </Card>
  );
};