import React, {useState, useEffect} from "react";
import {Card, DatePicker, Menu, message, Radio, Select, Skeleton, Space, Tag} from "antd";
import {Link, Route, Switch} from "react-router-dom";
import {SettingFilled, UserOutlined} from "@ant-design/icons";
import {statisticsRoutes} from "../../conf/routes";
import axios from "../../conf/axios";
import app from "../../libs/App";
import moment from "moment";
import App from "../../libs/App";
import {v4 as uuidv4} from "uuid";

export default ({location}) => {
  const [routeForceRenderKey, setRouteForceRenderKey] = useState();
  let pathname = location.pathname;
  if (pathname == '/statistics') {
    pathname = '/statistics/device';
  }
  let current = [pathname];

  const [loading, setLoading] = useState(false);

  const [typesLoading, setTypesLoading] = useState(false);
  const [types, setTypes] = useState([]);
  useEffect(() => {
    setTypesLoading(true);
    axios.post('/deviceModel/getDeviceModels')
        .finally(() => {
          setTypesLoading(false);
        })
        .then(response => {
          const types = response.data.data.deviceModels.map(type => ({
            id: type.id,
            value: type.name,
          }));
          setTypes(types);
        })
        .catch(error => {
          message.error(error.message);
        });
  }, []);


  const [modelId, setModelId] = useState();
  const [companyId, setCompanyId] = useState(app.user.companyId);
  const [userId, setUserId] = useState(/*app.user.id*/);
  const [dataType, setDataType] = useState('deviceCount');
  const [date, setDate] = useState(moment());

  const changeDate = date => {
    setDate(date);
  }

  const [models, setModels] = useState([]);
  const [modelsLoading, setModelsLoading] = useState(true);
  useEffect(() => {
    axios.post('/deviceModel/getDeviceModels')
        .finally(() => {
          setModelsLoading(false);
        })
        .then(response => {
          const models =  response.data.data.deviceModels.map(type => ({
            id: type.id,
            value: type.name,
          }));
          setModels(models);
          if (loading) {
            setModelsLoading(false);
          }
        })
        .catch(error => {
          message.error(error.message);
        });
  }, []);

  const [companys, setCompanys] = useState([]);
  const [companysLoading, setCompanyLoading] = useState(true);
  useEffect(() => {
    if (App.user.isAdmin) {
      axios.post('/company/getCompanys')
        .finally(() => {
          setCompanyLoading(false);
        })
        .then(response => {
          const companys = response.data.data.companys.map(company => ({
            id: company.id,
            name: company.name,
          }));
          setCompanys(companys);
          if (loading) {
            setLoading(false);
          }
        })
        .catch(error => {
          message.error(error.message);
        });
    }
  }, []);

  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const usersLoad = () => {
    setUsersLoading(true);
    axios.get('/user/getUsers', {params: {companyId}})
      .finally(() => {
        setUsersLoading(false);
      })
      .then(response => {
        const users = response.data.data.users.map(user => ({
          id: user.id,
          name: user.name,
        }));
        setUsers(users);
      })
      .catch(error => {
        message.error(error.message);
      });
  }
  useEffect(() => {
    usersLoad();
  }, [companyId]);

  const [tagVisible, setTagVisible] = useState(true);

  return (
    <Card title="数据统计">
      {
        loading ? <Skeleton active/> : (
          <>
            <div style={{marginBottom: 15, marginTop: -10}}>
              <Menu mode="horizontal" defaultSelectedKeys={current}>
                <Menu.Item key="/statistics/device" icon={<SettingFilled/>}>
                  <Link to={'/statistics'} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>数据统计</Link>
                </Menu.Item>
                <Menu.Item key="/statistics/user" icon={<UserOutlined/>}>
                  <Link to={'/statistics/user'} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>按用户</Link>
                </Menu.Item>
              </Menu>
            </div>
            <div style={{marginTop: 10}}>
              <Space>
                <div>
                  型号：
                  <Select style={{width: 175}} placeholder="型号" value={modelId} onChange={modelId => setModelId(modelId)} loading={modelsLoading}>
                    <Select.Option value="">全部型号</Select.Option>
                    {models.map(value => <Select.Option value={value.id}>{value.value}</Select.Option>)}
                  </Select>
                </div>
                {
                  App.user.isAdmin ? (
                    <div>
                      单位：
                      <Select style={{width: 200}} placeholder="单位" value={companyId} onChange={companyId => setCompanyId(companyId)} loading={companysLoading}>
                        <Select.Option value="">所有单位</Select.Option>
                        {companys.map(company => <Select.Option value={company.id}>{company.name}</Select.Option>)}
                      </Select>
                    </div>
                  ) : null
                }
                {
                  location.pathname == '/statistics/user' ? (
                    <div>
                      显示类型：
                      <Radio.Group value={dataType} onChange={e => setDataType(e.target.value)}>
                        <Radio value="deviceCount">新增设备</Radio>
                        <Radio value="projectCount">新增工作</Radio>
                        <Radio value="mileage">新增里程</Radio>
                      </Radio.Group>
                    </div>
                  ) : (
                    <div>
                      用户：
                      <Select style={{width: 200}} placeholder="用户" value={userId} onChange={userId => setUserId(userId)} loading={usersLoading}>
                        <Select.Option value="">所有用户</Select.Option>
                        {users.map(user => <Select.Option value={user.id}>{user.name}</Select.Option>)}
                      </Select>
                    </div>
                  )
                }
                <div>
                  日期：
                  <DatePicker placeholder="日期" value={date} onChange={date => setDate(date)} picker="month" allowClear={false}/>
                </div>
                <Tag color="green" closable visible={tagVisible} onClose={() => setTagVisible(false)}>
                  tips: 点击图表的年月可以快捷切换时间
                </Tag>
              </Space>
            </div>
            <div style={{marginTop: 20}}>
              <Switch>
                {
                  statisticsRoutes.map(route => {
                    return (
                      <Route path={route.path} exact={route.exact} render={props => {
                        document.title = route.title;
                        return <route.component {...props} {...{modelId, companyId, userId, dataType, date, changeDate}} key={routeForceRenderKey}/>
                      }}/>
                    );
                  })
                }
              </Switch>
            </div>
          </>
        )
      }
    </Card>
  );
};