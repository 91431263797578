import {Avatar, Button, Card, Drawer, Form, Image, Input, InputNumber, message, Popconfirm, Select, Space, Spin, Switch, Table} from "antd";
import React, {useEffect, useState} from "react";
import axios from "../../conf/axios";
import {imgPlaceholder} from "../../conf/general";
import App from "../../libs/App";

export default () => {
  const [records, setRecords] = useState([]);
  const recordsColumns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: 300,
    },
    {
      title: '名称',
      dataIndex: 'name'
    },
    {
      title: '图片',
      dataIndex: 'img',
      width: 200,
      render: value => {
        return value ? <Avatar src={value} size={40}/> : null;
      }
    },
    {
      title: '排序',
      dataIndex: 'sort'
    },
    {
      title: '操作',
      key: 'id',
      width: 200,
      render: (text, record) => (
        <Space>
          {
            App.hasAuth('deviceModel.setDeviceModel') ? (
              <>
                <Button type="primary" size="small" onClick={() => recordEdit(record)}>编辑</Button>
                <Button type="primary" size="small" onClick={() => specificatEdit(null, record)}>添加规格</Button>
              </>
            ) : null
          }
          {
            App.hasAuth('deviceModel.deleteDeviceModel') ? (
              <Popconfirm title="确定删除此型号吗" onConfirm={() => recordDelete(record)}>
                <Button type="primary" size="small">删除</Button>
              </Popconfirm>
            ) : null
          }
        </Space>
      ),
    },
  ];
  if (!App.hasOneAuths('deviceModel.setDeviceModel', 'deviceModel.deleteDeviceModel')) {
    recordsColumns.splice(recordsColumns.length - 1, 1);
  }

  const [recordsLoading, setRecordsLoading] = useState(false);

  const recordsLoad = () => {
    setRecordsLoading(true);
    axios.post('/deviceModel/getDeviceModels')
      .then(response => {
        let deviceModels = response.data.data.deviceModels;
        deviceModels = deviceModels.map(deviceModel => {
          deviceModel.key = deviceModel.id;
          return deviceModel;
        });
        setRecordsLoading(false);
        setRecords(deviceModels);
      })
      .catch(error => {
        message.error(error.message);
      });
  };
  useEffect(() => {
    recordsLoad();
  }, []);
  const recordDelete = values => {
    axios.post('/deviceModel/deleteDeviceModel', values)
      .then(response => {
        message.success('删除成功');
        recordsLoad();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  const [record, setRecord] = useState({});

  const [recordEditing, setRecordEditing] = useState(false);
  const [recordLoading, setRecordLoading] = useState(true);
  const [recordSubmitting, setRecordSubmitting] = useState(false);
  const [recordForm] = Form.useForm();

  const recordEdit = record => {
    setRecordEditing(true);
    setRecordLoading(false);
    recordForm.resetFields();
    setRecord(record ? record : {});
    recordForm.setFieldsValue(record ? record : {});
  }

  const recordSave = values => {
    if (recordSubmitting) {
      return;
    }
    setRecordSubmitting(true);
    var formData = new FormData();
    for (let key in values) {
      let value = values[key];
      if (value && key != 'imgFile') {
        formData.append(key, value);
      }
    }
    let img = document.getElementById('recordImgFile').files[0];
    if (img) {
      formData.append('img', img);
    }
    axios.post(record.id ? '/deviceModel/setDeviceModel' : '/deviceModel/addDeviceModel', formData)
      .finally(() => {
        setRecordSubmitting(false);
      })
      .then(response => {
        message.success('保存成功');
        setRecordEditing(false);
        recordsLoad();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  const specificatsColumns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: 300,
    },
    {
      title: '名称',
      dataIndex: 'name'
    },
    {
      title: '图片',
      dataIndex: 'img',
      width: 200,
      render: value => {
        return value ? <Avatar src={value} size={40}/> : null;
      }
    },
    {
      title: '排序',
      dataIndex: 'sort'
    },
    {
      title: '操作',
      key: 'id',
      width: 200,
      render: (text, record) => (
        <Space>
          {
            App.hasAuth('deviceModel.setDeviceModel') ? (
              <Button type="primary" size="small" onClick={() => specificatEdit(record)}>编辑</Button>
            ) : null
          }
          {
            App.hasAuth('deviceModel.deleteDeviceModel') ? (
              <Popconfirm title="确定删除此规格吗" onConfirm={() => specificatDelete(record)}>
                <Button type="primary" size="small">删除</Button>
              </Popconfirm>
            ) : null
          }
        </Space>
      ),
    },
  ];
  if (!App.hasOneAuths('deviceModel.setDeviceModel', 'deviceModel.deleteDeviceModel')) {
    specificatsColumns.splice(specificatsColumns.length - 1, 1);
  }

  const [specificat, setSpecificat] = useState({});

  const [specificatEditing, setSpecificatEditing] = useState(false);
  const [specificatLoading, setSpecificatLoading] = useState(true);
  const [specificatSubmitting, setSpecificatSubmitting] = useState(false);
  const [specificatForm] = Form.useForm();

  const specificatDelete = values => {
    axios.post('/deviceModel/deleteDeviceSpecificat', values)
      .then(response => {
        message.success('删除成功');
        fetch();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  const specificatEdit = (record, model) => {
    setSpecificatEditing(true);
    setSpecificatLoading(false);
    specificatForm.resetFields();
    setSpecificat(record ? record : {});
    specificatForm.setFieldsValue(record ? record : {});
    if (model) {
      specificatForm.setFieldsValue({modelId: model.id});
    }
  }

  const specificatSave = values => {
    if (specificatSubmitting) {
      return;
    }
    setSpecificatSubmitting(true);
    var formData = new FormData();
    for (let key in values) {
      let value = values[key];
      if (value && key != 'imgFile') {
        formData.append(key, value);
      }
    }
    let img = document.getElementById('specificatImgFile').files[0];
    if (img) {
      formData.append('img', img);
    }
    axios.post(specificat.id ? '/deviceModel/setDeviceSpecificat' : '/deviceModel/addDeviceSpecificat', formData)
      .finally(() => {
        setSpecificatSubmitting(false);
      })
      .then(response => {
        message.success('保存成功');
        setSpecificatEditing(false);
        recordsLoad();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  return (
    <Card title="设备型号" extra={App.hasAuth('deviceModel.addDeviceModel') ? <Button type="primary" onClick={recordEdit}>添加</Button> : null}>
      <Table
        columns={recordsColumns}
        dataSource={records}
        pagination={false}
        loading={recordsLoading}
        expandable={{
          expandedRowRender: record => (
            <Table
              columns={specificatsColumns}
              showHeader={false}
              dataSource={record.specificats}
              pagination={false}
            />
          ),
        }}
      />
      <Drawer title="设备型号" width={444} placement="right" visible={recordEditing} onClose={() => setRecordEditing(false)}>
        <Spin spinning={recordLoading}/>
        <Form bordered form={recordForm} layout="vertical" onFinish={recordSave}>
          <Form.Item name="id" label="id" rules={[{required: true}]}>
            <Input placeholder="id" disabled={!!record.id}/>
          </Form.Item>
          <Form.Item name="name" label="名称" rules={[{required: true}]}>
            <Input placeholder="名称" allowClear={true}/>
          </Form.Item>
          {
            record.img ? (
              <Form.Item>
                <Image width={75} src={record.img} fallback={imgPlaceholder}/>
              </Form.Item>
            ) : null
          }
          <Form.Item name="imgFile" label="图片" rules={[
            {pattern: /.*(png|jpg|jpeg|bmp|gif)/i, message: '只能选择图片文件'},
          ]}>
            <input type="file" id="recordImgFile" placeholder="图片"/>
          </Form.Item>
          <Form.Item name="sort" label="排序">
            <InputNumber placeholder="排序" defaultValue={0}/>
          </Form.Item>
          {
            !record.id ? (
              <Form.Item name="specificat" label="初始化规格（9种）">
                <Switch defaultChecked/>
              </Form.Item>
            ) : null
          }
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={recordSubmitting}>保存</Button>
          </Form.Item>
        </Form>
      </Drawer>
      <Drawer title="设备规格" width={444} placement="right" visible={specificatEditing} onClose={() => setSpecificatEditing(false)}>
        <Spin spinning={specificatLoading}/>
        <Form bordered form={specificatForm} layout="vertical" onFinish={specificatSave}>
          <Form.Item name="id" label="id" hidden={true}>
            <Input placeholder="id"/>
          </Form.Item>
          <Form.Item name="modelId" label="型号" rules={[{required: true}]}>
            <Select style={{width: 175}} defaultValue="" placeholder="型号" disabled={!!specificat.id}>
              <Select.Option value="">全部型号</Select.Option>
              {records.map(value => <Select.Option value={value.id}>{value.id} {value.name}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item name="name" label="名称" rules={[{required: true}]}>
            <Input placeholder="名称" disabled={!!specificat.id}/>
          </Form.Item>
          {
            specificat.img ? (
              <Form.Item>
                <Image width={75} src={specificat.img} fallback={imgPlaceholder}/>
              </Form.Item>
            ) : null
          }
          <Form.Item name="imgFile" label="图片" rules={[
            {pattern: /.*(png|jpg|jpeg|bmp|gif)/i, message: '只能选择图片文件'},
          ]}>
            <input type="file" id="specificatImgFile" placeholder="图片"/>
          </Form.Item>
          <Form.Item name="sort" label="排序">
            <InputNumber placeholder="排序" defaultValue={0}/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={specificatSubmitting}>保存</Button>
          </Form.Item>
        </Form>
      </Drawer>
    </Card>
  );
};