class Auth {
  static getToken() {
    return localStorage.getItem('token');
  }

  static setToken(token) {
    localStorage.setItem('token', token);
  }

  static deleteToken() {
    localStorage.removeItem('token');
  }

  static hasToken() {
    return !!localStorage.getItem('token');
  }
}

export default Auth;