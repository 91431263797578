import {AutoComplete, Button, Card, DatePicker, Drawer, Form, Input, message, Popconfirm, Space, Spin, Table} from "antd";
import moment from "moment";
import React, {useEffect, useState} from "react";
import axios from "../../conf/axios";
import {SearchOutlined} from '@ant-design/icons';
import App from "../../libs/App";

export default () => {
  let where = {
    pagination: {
      /*pageSize: 2,
      currentPage: 1,*/
    }
  };

  const whereChange = values => {
    where = {...where, ...values};
    fetch();
  };

  const columns = [
    {
      title: '单位名称',
      dataIndex: 'name',
      width: 300,
    },
    {
      title: '单位地址',
      dataIndex: 'address'
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      width: 200,
      render: value => {
        return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '';
      }
    },
    {
      title: '操作',
      key: 'id',
      width: 200,
      render: (text, record) => (
        <Space>
          {
            App.hasAuth('company.setCompany') ? (
              <Button type="primary" size="small" onClick={() => edit(record)}>编辑</Button>
            ) : null
          }
          {
            App.hasAuth('company.deleteCompany') ? (
              <Popconfirm title="确定删除此单位吗" onConfirm={() => adelete(record)}>
                <Button type="primary" size="small">删除</Button>
              </Popconfirm>
            ) : null
          }
        </Space>
      ),
    },
  ];
  useEffect(() => {
    if (!App.hasOneAuths('company.setCompany', 'company.deleteCompany')) {
      columns.splice(columns.length - 1, 1);
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [pagination, setPagination] = useState([]);
  const tableChange = (pagination, filters, sorter) => {
    where.pagination = {
      pageSize: pagination.pageSize,
      currentPage: pagination.current,
    }
    fetch();
  };

  const fetch = (params = {}) => {
    setLoading(true);
    axios.post('/company/getCompanys', where)
      .then(response => {
        let data = response.data.data;
        let pagination = data.pagination;
        setLoading(false);
        setDatas(data.companys);
        setPagination({
          current: pagination.currentPage,
          pageSize: pagination.perPage,
          total: pagination.totalCount,
        });
      })
      .catch(error => {
        message.error(error.message);
      });
  };
  useEffect(() => {
    fetch();
  }, []);

  const adelete = values => {
    axios.post('/company/deleteCompany', values)
      .then(response => {
        message.success('删除成功');
        fetch();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  const [editIs, setEditIs] = useState(false);
  const [editLoading, setEditLoading] = useState(true);
  const [record, setRecord] = useState({});
  const [editSubmitting, setEditSubmitting] = useState(false);
  const [editForm] = Form.useForm();
  const edit = values => {
    setEditIs(true);
    if (values) {
      setEditLoading(true);
      setTimeout(() => {
        setEditLoading(false);
        editForm.setFieldsValue(values);
      }, 555);
    } else {
      setEditLoading(false);
      editForm.resetFields();
    }
  }
  const editClose = () => {
    setEditIs(false);
    setEditLoading(false);
  }

  const save = values => {
    if (editSubmitting) {
      return;
    }
    setEditSubmitting(true);
    axios.post(values.id ? '/company/setCompany' : '/company/addCompany', values)
      .finally(() => {
        setEditSubmitting(false);
      })
      .then(response => {
        setEditIs(false);
        setEditLoading(true);
        fetch();
        message.success('保存成功');
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  return (
    <Card title="单位信息" extra={App.hasAuth('company.addCompany') ? <Button type="primary" onClick={() => edit()}>添加</Button> : null}>
      <div className="where">
        <Form onFinish={whereChange}>
          <div className="where-container">
            <div className="where-input">
              <Form.Item name="kw" label="关键字">
                <Input style={{width: 200}} placeholder="关键字" allowClear={true}/>
              </Form.Item>
              <Form.Item name="name" label="单位名称">
                <Input style={{width: 200}} placeholder="单位名称" allowClear={true}/>
              </Form.Item>
              <Form.Item name="address" label="单位地址">
                <Input style={{width: 200}} placeholder="单位地址" allowClear={true}/>
              </Form.Item>
              <Form.Item name="time" label="创建时间">
                <DatePicker.RangePicker placeholder="起始" onChange={whereChange}/>
              </Form.Item>
            </div>
            <div className="where-operate">
              <Button type="primary" htmlType="submit" icon={<SearchOutlined/>} loading={loading}>搜索</Button>
            </div>
          </div>
        </Form>
      </div>
      <Table columns={columns} dataSource={datas} pagination={pagination} loading={loading} onChange={tableChange}/>
      <Drawer title="单位信息" width={444} placement="right" visible={editIs} onClose={editClose}>
        <Spin spinning={editLoading}/>
        {
          editLoading ? null : (
            <Form bordered form={editForm} layout="vertical" onFinish={save}>
              <Form.Item name="id" label="id" hidden={true}>
                <Input placeholder="id"/>
              </Form.Item>
              <Form.Item name="name" label="单位名称" rules={[{required: true}]}>
                <Input placeholder="单位名称" allowClear={true}/>
              </Form.Item>
              <Form.Item name="address" label="单位地址" rules={[{required: true}]}>
                <Input.TextArea rows={4} placeholder="单位地址"/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={editSubmitting}>保存</Button>
              </Form.Item>
            </Form>
          )
        }
      </Drawer>
    </Card>
  );
};