import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Row, Skeleton, Input, Divider, message, Select} from "antd";
import {Link} from "react-router-dom";
import axios from "../../conf/axios";
import App from "../../libs/App";

export default props => {

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [companys, setCompanys] = useState([]);
  const [devices, setDevices] = useState([]);
  const [record, setRecord] = useState({});

  const [form] = Form.useForm();

  useEffect(() => {
    axios.post('/device/getDevices')
      .then(response => {
        let devices = response.data.data.devices.map(device => ({
          id: device.id,
          value: device.identifier,
        }));
        setDevices(devices);
      })
      .then(response => {
        if (App.user.isAdmin) {
          return axios.post('/company/getCompanys')
            .then(response => {
              let companys = response.data.data.companys.map(company => ({
                id: company.id,
                value: company.name,
              }));
              setCompanys(companys);
            });
        } else {
          return Promise.resolve();
        }
      })
      .then(response => {
        setLoading(false);
      })
      .catch(error => {
        message.error(error.message);
      });
  }, []);

  const save = values => {
    if (saving) {
      return;
    }
    setSaving(true);
    axios.post(values.id ? '/project/setProject' : '/project/addProject', values)
      .finally(() => {
        setSaving(false);
      })
      .then(response => {
        message.success('保存成功');
        props.history.push('/project');
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  return (
    <Card title="项目工作" extra={<Button type="primary"><Link to="/project">返回列表</Link></Button>}>
      <div className="projectEdit">
        {
          loading ? <Skeleton active/> : (
            <>
              <Form form={form} onValuesChange={changedValues => {
                setRecord(Object.assign({}, record, changedValues));
              }} onFinish={save}>
                <Form.Item name="id" label="id" hidden={true}>
                  <Input placeholder="id"/>
                </Form.Item>
                <Divider plain>基本信息</Divider>
                <Row>
                  <Col span={8}>
                    <Form.Item label="名称" name="name" labelCol={{span: 5}} rules={[{required: true}]}>
                      <Input placeholder="名称"/>
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item label="区域" name="area" labelCol={{span: 3}} rules={[{required: true}]}>
                      <Input placeholder="区域"/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item label="管线长度" name="pipelineLength" labelCol={{span: 5}}>
                      <Input placeholder="管线长度"/>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="管线直径" name="pipelineDiameter" labelCol={{span: 5}}>
                      <Input placeholder="管线直径"/>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="管线壁厚" name="pipelineThickness" labelCol={{span: 5}}>
                      <Input placeholder="管线壁厚"/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  {
                    App.user.isAdmin ? (
                      <Col span={8}>
                        <Form.Item label="单位" name="companyId" labelCol={{span: 5}}>
                          <Select style={{width: '100%'}} defaultValue="" placeholder="单位">
                            <Select.Option value="">选择单位</Select.Option>
                            {companys.map(value => <Select.Option value={value.id}>{value.value}</Select.Option>)}
                          </Select>
                        </Form.Item>
                      </Col>
                    ) : null
                  }
                  <Col span={8}>
                    <Form.Item label="设备" name="deviceId" labelCol={{span: 5}}>
                      <Select style={{width: '100%'}} defaultValue="" placeholder="设备">
                        <Select.Option value="">选择设备</Select.Option>
                        {devices.map(value => <Select.Option value={value.id}>{value.value}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {
                  record.modelId == 'SPLR' || record.modelId == 'SPSR' ? (
                    <>
                      <Divider plain>补口信息</Divider>
                      <Row>
                        <Col span={8}>
                          <Form.Item label="涂层厚度" name="coatingThickness" labelCol={{span: 5}}>
                            <Input placeholder="涂层厚度"/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="补口宽度" name="jointWidth" labelCol={{span: 5}}>
                            <Input placeholder="补口宽度"/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="补口总量" name="jointTotal" labelCol={{span: 5}}>
                            <Input placeholder="补口总量"/>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : null
                }
                <div style={{textAlign: "center", marginTop: 15}}>
                  <Button type="primary" htmlType="submit" loading={saving}>保存</Button>
                </div>
              </Form>
            </>
          )
        }
      </div>
    </Card>
  );
};