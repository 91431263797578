import {Button, Card, Form, Input, message, Modal, Progress, Spin, Upload, Tooltip} from "antd";
import React, {useEffect, useState, useRef, useCallback} from "react";
import {DndProvider, useDrag, useDrop, createDndContext} from 'react-dnd';
import {PlusOutlined} from '@ant-design/icons';
import {HTML5Backend} from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import Chance from "chance";
import axios from "../../conf/axios";
import {v4 as uuidv4} from "uuid";

const RNDContext = createDndContext(HTML5Backend);
const DragableUploadListItem = ({originNode, moveRow, file, fileList}) => {
  const ref = React.useRef();
  const index = fileList.indexOf(file);
  const [{isOver, dropClassName}, drop] = useDrop({
    accept: 'DragableUploadList',
    collect: monitor => {
      const {index: dragIndex} = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: {type: 'DragableUploadList', index},
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = (
    <Tooltip title="Upload Error" getPopupContainer={() => document.body}>
      {originNode.props.children}
    </Tooltip>
  );
  return (
    <div ref={ref} className={`${isOver ? dropClassName : ''}`} style={{cursor: 'move', height: '100%'}}>
      {file.status === 'error' ? errorNode : originNode}
    </div>
  );
};

export default () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const load = () => {
    setLoading(true);
    axios.get('/appConfig/getAppConfig')
      .finally(() => {
        setLoading(false);
      })
      .then(response => {
        let appConfig = response.data.data.appConfig;
        setData(appConfig);
        form.setFieldsValue(appConfig);
        setUploadImgs(appConfig.imgs.map(img => ({uid: uuidv4().toString(), url: img, status: 'done'})));
        setUploadImgsKey(uuidv4().toString());
      })
      .catch(error => {
        message.error(error.message);
      });
  };
  useEffect(() => {
    load();
  }, []);

  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const save = values => {
    if (saving) {
      return;
    }
    setSaving(true);
    values.imgs = [];
    if (data.icon) {
      values.icon = data.icon;
    }
    if (uploadImgs.length > 0) {
      for (const uploadImg of uploadImgs) {
        if (uploadImg.status == 'done') {
          values.imgs.push(uploadImg.url);
        }
      }
    }
    axios.post('/appConfig/setAppConfig', values)
      .finally(() => {
        setSaving(false);
      })
      .then(response => {
        message.success('保存成功');
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImg, setPreviewImg] = useState();
  const handlePreview = async file => {
    setPreviewImg(file.url);
    setPreviewVisible(true);
  };

  const [iconUploading, setIconUploading] = useState(false);
  const [iconUploadProgress, setIconUploadProgress] = useState(0);
  const iconUpload = ({file}) => {
    setIconUploading(true);
    var formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'icon');
    setIconUploadProgress(Chance.Chance().floating({min: 1, max: 3, fixed: 2}));
    axios.post('/appConfig/upload', formData, {
      onUploadProgress(progress) {
        setIconUploadProgress(Math.round(progress.loaded / progress.total * 100));
        console.log(progress);
      }
    })
      .then(response => {
        setIconUploading(false);
        setData(data => ({...data, icon: response.data.data.file}));
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  const [uploadImgsKey, setUploadImgsKey] = useState('');
  const [uploadImgs, setUploadImgs] = useState([]);
  const imgUpload = ({file}) => {
    const update = obj => {
      setUploadImgs(uploadImgs => {
        return uploadImgs.map(uploadImg => {
          if (uploadImg.uid == file.uid) {
            uploadImg = {...uploadImg, ...obj};
          }
          return uploadImg;
        });
      });
    }
    var formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'img');
    update({
      status: 'uploading',
      percent: Chance.Chance().floating({min: 1, max: 3, fixed: 2}),
    });
    axios.post('/appConfig/upload', formData, {
      onUploadProgress(progress) {
        update({
          status: 'uploading',
          percent: Math.round(progress.loaded / progress.total * 100),
        });
      }
    })
      .then(response => {
        update({
          percent: 100,
          status: 'done',
          url: response.data.data.file
        });
      })
      .catch(error => {
        update({status: 'error'});
        message.error(error.message);
      });
  }
  const manager = useRef(RNDContext);
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = uploadImgs[dragIndex];
      setUploadImgs(
        update(uploadImgs, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [uploadImgs],
  );

  return (
    <Card title="app配置" className="appConfig">
      <Spin spinning={loading}>
        <Form bordered form={form} onFinish={save} className="ant-form-table">
          <table className="table table-bordered">
            <tr>
              <td width="100">名称</td>
              <td>
                <Form.Item name="name" rules={[{required: true}]}>
                  <Input placeholder="名称" style={{width: 250}}/>
                </Form.Item>
              </td>
            </tr>
            <tr>
              <td>图标</td>
              <td>
                <Form.Item name="icon">
                  <Upload
                    listType="picture-card"
                    showUploadList={false}
                    customRequest={iconUpload}
                    openFileDialogOnClick={!iconUploading}
                  >
                    <div className={'upload-card' + (iconUploading ? ' ing' : '')}>
                      {
                        data.icon && !iconUploading
                          ? <div className="upload-card-img"><img src={data.icon}/></div>
                          : <>
                            {
                              iconUploading
                                ? <Progress percent={iconUploadProgress} size="small" status="active"/>
                                : <PlusOutlined/>
                            }
                            <div className="upload-card-text">{iconUploading ? '上传中' : '上传'}</div>
                          </>
                      }
                    </div>
                  </Upload>
                </Form.Item>
              </td>
            </tr>
            <tr>
              <td>图片</td>
              <td>
                <div className="appConfig-img">
                  <Form.Item>
                    <DndProvider key={uploadImgsKey} manager={manager.current.dragDropManager}>
                      <Upload
                        multiple
                        listType="picture-card"
                        fileList={uploadImgs}
                        onPreview={handlePreview}
                        onChange={({fileList}) => setUploadImgs(fileList)}
                        customRequest={imgUpload}
                        itemRender={(originNode, file, currFileList) => (
                          <DragableUploadListItem
                            originNode={originNode}
                            file={file}
                            fileList={currFileList}
                            moveRow={moveRow}
                          />
                        )}
                      >
                        添加
                      </Upload>
                    </DndProvider>
                  </Form.Item>
                </div>
              </td>
            </tr>
            <tr>
              <td>简介</td>
              <td>
                <Form.Item name="intro" rules={[{required: true}]}>
                  <Input.TextArea rows={4} placeholder="简介"/>
                </Form.Item>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <Button type="primary" htmlType="submit" loading={saving}>保存</Button>
              </td>
            </tr>
          </table>
        </Form>
      </Spin>
      <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)} className="max">
        <div className="preview-container">
          <img src={previewImg}/>
        </div>
      </Modal>
    </Card>
  );
};