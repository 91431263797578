import React, {useState, useEffect} from 'react';
import {Button, Card, Form, Image, Input, message, Skeleton} from "antd";
import axios from "../../conf/axios";
import {imgPlaceholder} from "../../conf/general";

export default () => {
  const [readying, setReadying] = useState(false);
  const [record, setRecord] = useState({});
  useEffect(() => {
    axios.post('/getMy')
      .then(response => {
        if (response.data.status === 0) {
          setRecord(response.data.data);
          setReadying(true);
        }
      })
      .catch(error => {
        message.error(error.message);
      });
  }, []);
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const onFinish = values => {
    if (submitting) {
      return true;
    }
    setSubmitting(true);
    axios.post('/setMy', {
      name: values.name,
      email: values.email,
    })
      .then(response => {
        var avatar = document.getElementById('avatar').files[0];
        if (avatar) {
          var formData = new FormData();
          for (let key in values) {
            let value = values[key];
            if (key == 'name') {
              formData.append(key, value);
            }
          }
          formData.append('avatar', document.getElementById('avatar').files[0]);
          return axios.post('/uploadAvatar', formData).then(response => {
            record.avatar = response.data.data.avatar;
          });
        }
      })
      .finally(() => {
        setSubmitting(false);
      })
      .then(response => {
        record.name = values.name;
        record.email = values.email;
        form.resetFields();
        message.success('修改成功');
      })
      .catch(error => {
        message.error(error.message);
      });
  };
  return (
    <Card title="基本资料">
      <div style={{width: 444}}>
        {
          !readying ? <Skeleton active/> : (
            <Form form={form} layout="vertical" initialValues={record} onFinish={onFinish}>
              <Form.Item label="姓名" name="name" rules={[{required: true}]}>
                <Input/>
              </Form.Item>
              <Form.Item label="邮箱" name="email" rules={[{required: true}, {type: 'email'}]}>
                <Input/>
              </Form.Item>
              <Form.Item label="头像" name="avatar">
                {
                  record.avatar ? <div style={{marginBottom: 10}}>
                    <Image
                      width={100}
                      height={100}
                      src={record.avatar}
                      fallback={imgPlaceholder}
                    />
                  </div> : ''
                }
                <input type="file" id="avatar"/>
              </Form.Item>
              <Form.Item label="电话" name="phone">
                {record.phone}
              </Form.Item>
              <Form.Item label="公司" name="company">
                {record.company ? record.company.name : '-'}
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={submitting}>保存</Button>
              </Form.Item>
            </Form>
          )
        }
      </div>
    </Card>
  );
};