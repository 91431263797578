import React from 'react';
import {Alert, Button, Card, DatePicker, Drawer, Form, Input, message, Popconfirm, Space, Spin, Switch, Table} from 'antd';
import moment from 'moment';
import axios from '../../conf/axios';
import App from "../../libs/App";

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          title: '版本号3',
          dataIndex: 'number'
        },
        {
          title: '版本名称',
          dataIndex: 'name',
        },
        {
          title: '更新内容',
          dataIndex: 'content',
        },
        {
          title: '大小',
          dataIndex: 'size',
          render: value => {
            return value ? ((value / 1024 / 1024).toFixed(2) + ' MB') : ' ';
          }
        },
        {
          title: '文件',
          dataIndex: 'file',
          render: value => {
            return <a href={value} target="_blank">文件</a>
          }
        },
        {
          title: '更新时间',
          dataIndex: 'time',
          render: value => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          }
        },
        {
          title: '强制更新',
          dataIndex: 'isForce',
          render: (value, record) => (
            <Switch checked={value} disabled={!App.hasAuth('appVersion.setAppVersion')} onChange={value => {
              this.save({id: record.id, is_force: value})
            }}/>
          ),
        },
        {
          title: '最新版本',
          dataIndex: 'isLatest',
          render: (value, record) => (
            <Switch checked={value} disabled={!App.hasAuth('appVersion.setAppVersion')} onChange={value => {
              this.save({id: record.id, is_latest: value}, true)
            }}/>
          ),
        },
        {
          title: '操作',
          key: 'id',
          render: (text, record) => (
            <Space>
              {
                App.hasAuth('appVersion.setAppVersion') ? (
                  <Button type="primary" size="small" onClick={() => this.drawerEdit(record)}>编辑</Button>
                ) : null
              }
              {
                App.hasAuth('appVersion.deleteAppVersion') ? (
                  <Popconfirm title="确定删除此版本吗" onConfirm={value => {
                    this.delete({id: record.id})
                  }}>
                    <Button type="primary" size="small">删除</Button>
                  </Popconfirm>
                ) : null
              }
            </Space>
          ),
        },
      ],
      data: [],
      pagination: {},
      loading: false,
      record: {},
      visible: false,
      editLoading: false,
      submitting: false,
      buttonText: '保存',
      default: {time: moment(), isForce: true, isLatest: true}
    };

    if (!App.hasOneAuths('appVersion.setAppVersion', 'appVersion.deleteAppVersion')) {
      this.state.columns.splice(this.state.columns.length - 1, 1);
    }
  }

  where = {
    pagination: {
      current: 1,
      pageSize: 10,
    }
  };

  formRef = React.createRef();

  componentDidMount() {
    this.fetch();
  }

  tableChange = (pagination, filters, sorter) => {
    this.where.pagination = pagination
    this.fetch();
  };

  fetch = (params = {}) => {
    this.setState({loading: true});
    axios.post('/appVersion/getAppVersions', {
      currentPage: this.where.pagination.current,
      pageSize: this.where.pagination.pageSize,
    })
      .then(response => {
        let data = response.data.data;
        let pagination = data.pagination;
        this.setState({
          loading: false,
          data: data.appVersions,
          pagination: {
            current: pagination.currentPage,
            pageSize: pagination.perPage,
            total: pagination.totalCount,
          },
        });
      })
      .catch(error => {
        message.error(error.message);
      });
  };

  close = () => {
    this.formRef.current.resetFields();
    this.setState({visible: false});
    this.setState({record: {}});
  };

  onChange = e => {
    this.setState({
      placement: e.target.value,
    });
  };

  save = (values, refresh) => {
    return axios.post(values.id ? '/appVersion/asetAppVersion' : '/appVersion/addAppVersion', values)
      .then(() => {
        if (refresh) {
          this.fetch();
        }
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  drawerEdit = values => {
    this.setState({visible: true});
    if (values) {
      let record = JSON.parse(JSON.stringify(values));
      this.setState({editLoading: true});
      delete record.file;
      record.time = record.time ? moment(record.time * 1000) : '';
      setTimeout(() => {
        this.setState({editLoading: false});
        this.setState({record: values});
        this.formRef.current.setFieldsValue(record);
      }, 555);
    }
  };

  drawerSave = values => {
    if (this.state.submitting) {
      return true;
    }
    this.setState({submitting: true, buttonText: '保存中'});
    var formData = new FormData();
    for (let key in values) {
      let value = values[key];
      if (key == 'id' && !value) {
        continue;
      }
      if (key == 'isForce' || key == 'isLatest') {
        value = value ? 1 : 0;
      }
      if (key != 'file') {
        formData.append(key, value);
      }
    }
    formData.delete('file');
    let file = document.getElementById('file').files[0];
    if (file) {
      formData.append('file', file);
    }
    axios.post(values.id ? '/appVersion/setAppVersion' : '/appVersion/addAppVersion', formData)
      .finally(() => {
        this.setState({submitting: false, buttonText: '保存'});
      })
      .then(response => {
        message.success('保存成功');
        this.close();
        this.fetch();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  delete = values => {
    axios.post('/appVersion/deleteAppVersion', values)
      .then(response => {
        this.fetch();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  render() {
    return (
      <Card title="app 版本更新" extra={
        <Space>
          {App.hasAuth('appVersion.addAppVersion') ? <Button type="primary" onClick={() => this.drawerEdit()}>添加</Button> : null}
          <Button type="primary" onClick={() => this.fetch()}>刷新</Button>
        </Space>
      }>
        <Table columns={this.state.columns} rowKey={record => record.id} dataSource={this.state.data} pagination={this.state.pagination} loading={this.state.loading} onChange={this.tableChange}/>
        <Drawer title="app版本" width={555} placement="right" visible={this.state.visible} onClose={this.close}>
          <Spin spinning={this.state.editLoading}>
            <Form layout="vertical" ref={this.formRef} initialValues={this.state.default} onFinish={this.drawerSave}>
              <Form.Item name="id" label="id" hidden={true}>
                <Input placeholder="id"/>
              </Form.Item>
              <Form.Item name="name" label="版本名称">
                <Input placeholder="版本名称" allowClear={true}/>
              </Form.Item>
              <Form.Item name="content" label="更细内容">
                <Input.TextArea rows={4} placeholder="更细内容"/>
              </Form.Item>
              <Form.Item name="time" label="更新时间">
                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss"/>
              </Form.Item>
              {
                this.state.record.file ? <Alert message="已上传的文件" description={'大小：' + (this.state.record.size / 1024 / 1024).toFixed(2) + ' MB md5：' + this.state.record.md5} type="success"/> : ''
              }
              <Form.Item label="文件" name="file" rules={[
                {required: !this.state.record.file, message: '请选择更新文件'},
                {pattern: /.*apk/, message: '只能选择apk文件'},
              ]}>
                <input type="file" id="file"/>
              </Form.Item>
              {
                this.state.record.id ? (
                  <>
                    <Form.Item name="number" label="版本号">
                      <Input placeholder="版本号"/>
                    </Form.Item>
                  </>
                ) : ''
              }
              <Form.Item name="isForce" label="强制更新" valuePropName="checked">
                <Switch/>
              </Form.Item>
              <Form.Item name="isLatest" label="最新版本" valuePropName="checked">
                <Switch/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={this.state.submitting}>{this.state.buttonText}</Button>
              </Form.Item>
            </Form>
          </Spin>
        </Drawer>
      </Card>
    );
  }
}

export default Index;