import React, {useEffect, useState} from "react";
import {Button, Card, Drawer, Form, Image, Input, InputNumber, message, Popconfirm, Space, Spin, Switch, Table} from 'antd';
import axios from '../../conf/axios';
import {imgPlaceholder} from "../../conf/general";
import App from "../../libs/App";

export default () => {
  const [records, setRecords] = useState([]);
  const recordsColumns = [
    {
      title: '图片',
      dataIndex: 'img',
      render: value => {
        return <Image width={75} src={value} fallback={imgPlaceholder}/>
      }
    },
    {
      title: '标题',
      dataIndex: 'title'
    },
    {
      title: '排序',
      dataIndex: 'sort',
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (value, record) => (
        <Switch defaultChecked={value} disabled={!App.hasAuth('banner.setBanner')} onChange={value => {
          record.status = value;
          recordQuickSave({id: record.id, status: value});
        }}/>
      ),
    },
    {
      title: '操作',
      key: 'id',
      width: 200,
      render: (text, record) => (
        <Space>
          {
            App.hasAuth('banner.setBanner') ? (
              <Button type="primary" size="small" onClick={() => recordEdit(record)}>编辑</Button>
            ) : null
          }
          {
            App.hasAuth('banner.deleteBanner') ? (
              <Popconfirm title="确定删除此记录吗" onConfirm={() => recordDelete(record)}>
                <Button type="primary" size="small">删除</Button>
              </Popconfirm>
            ) : null
          }
        </Space>
      ),
    },
  ];

  if (!App.hasOneAuths('banner.setBanner', 'banner.deleteBanner')) {
    recordsColumns.splice(recordsColumns.length - 1, 1);
  }

  const [recordsLoading, setRecordsLoading] = useState(false);

  const recordsLoad = (params = {}) => {
    setRecordsLoading(true);
    axios.post('/banner/getBanners')
      .then(response => {
        setRecordsLoading(false);
        setRecords(response.data.data.banners);
      })
      .catch(error => {
        message.error(error.message);
      });
  };
  useEffect(() => {
    recordsLoad();
  }, []);
  const recordDelete = values => {
    axios.post('/banner/deleteBanner', values)
      .then(response => {
        recordsLoad();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  const [record, setRecord] = useState({});

  const recordQuickSave = values => {
    axios.post(values.id ? '/banner/setBanner' : '/banner/addBanner', values)
      .catch(error => {
        message.error(error.message);
      });
  }

  const [recordEditing, setRecordEditing] = useState(false);
  const [recordLoading, setRecordLoading] = useState(true);
  const [recordSubmitting, setRecordSubmitting] = useState(false);
  const [recordForm] = Form.useForm();

  const recordEdit = record => {
    setRecordEditing(true);
    setRecordLoading(false);
    recordForm.resetFields();
    setRecord(record ? record : {});
    recordForm.setFieldsValue(record ? record : {});
  };

  const recordSave = values => {
    if (recordSubmitting) {
      return true;
    }
    setRecordSubmitting(true);
    var formData = new FormData();
    for (let key in values) {
      let value = values[key];
      if (key == 'id' && !value) {
        continue;
      }
      if (key != 'img' && value) {
        formData.append(key, value);
      }
    }
    let img = document.getElementById('recordImgFile').files[0];
    if (img) {
      formData.append('img', img);
    }
    axios.post(values.id ? '/banner/setBanner' : '/banner/addBanner', formData)
      .finally(() => {
        setRecordSubmitting(false);
      })
      .then(response => {
        message.success('保存成功');
        setRecordEditing(false);
        recordsLoad();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  return (
    <Card title="轮播图" extra={App.hasAuth('banner.addBanner') ? <Button type="primary" onClick={recordEdit}>添加</Button> : null}>
      <Table columns={recordsColumns} rowKey={record => record.id} loading={recordsLoading} dataSource={records} pagination={false}/>
      <Drawer title="轮播图" width={444} placement="right" visible={recordEditing} onClose={() => setRecordEditing(false)}>
        <Spin spinning={recordLoading}>
          <Form layout="vertical" form={recordForm} onFinish={recordSave}>
            <Form.Item name="id" label="id" hidden={true}>
              <Input placeholder="id"/>
            </Form.Item>
            <Form.Item name="title" label="标题">
              <Input placeholder="标题" allowClear={true}/>
            </Form.Item>
            {
              record.img ? (
                <Form.Item>
                  <Image width={75} src={record.img} fallback={imgPlaceholder}/>
                </Form.Item>
              ) : null
            }
            <Form.Item label="图片" name="imgFile" rules={[
              {required: !record.img, message: '请选择图片'},
              {pattern: /.*(png|jpg|jpeg|bmp|gif)/i, message: '只能选择图片文件'},
            ]}>
              <input type="file" id="recordImgFile"/>
            </Form.Item>
            <Form.Item name="sort" label="排序">
              <InputNumber defaultValue="0"/>
            </Form.Item>
            <Form.Item name="status" label="状态" valuePropName="checked">
              <Switch defaultChecked={true}/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" size="large" htmlType="submit" loading={recordSubmitting}>保存</Button>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </Card>
  );
};