import React, {useState, useEffect} from "react";
import {Spin} from "antd";
import axios from "../../conf/axios";

export default ({project}) => {
  const [loading, setLoading] = useState(true);
  const [preview, setPreview] = useState();
  useEffect(async () => {
    axios.get('/project/getDataChartPreview', {params: {id: project.id}})
      .finally(() => {
        setLoading(false);
      })
      .then(result => {
        setPreview(result.data.data.preview);
      });
  }, []);

  return (
    <Spin spinning={loading} tip="Loading...">
      <div style={{textAlign: "center", minHeight: 333, padding: '25px 0'}}>
        <img
          src={preview}
          alt="数据图表预览"
          style={{display: "block", margin: "auto", maxWidth: 1448}}
        />
      </div>
    </Spin>
  );
};