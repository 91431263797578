import React, {useEffect, useState} from "react";
import {message, Spin} from "antd";
import axios from "../../conf/axios";

export default () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios.post('/getLocations')
      .finally(() => {
        setLoading(false);
      })
      .then(response => {
        const projects = response.data.data.projects;
        try {
          var map = new AMap.Map('amap', {
            viewMode: '3D',
            zoom: 12.5,
            layers: [
              new AMap.TileLayer.Satellite()
            ]
          });

          var object3Dlayer = new AMap.Object3DLayer({zIndex: 10});
          map.add(object3Dlayer);
          for (var i in projects) {
            var project = projects[i];

            var center = [(project.startLocation[0] * 1 + project.endLocation[0] * 1) / 2, (project.startLocation[1] * 1 + project.endLocation[1] * 1) / 2];
            var marker = new AMap.Marker({
              icon: '/resources/mark_' + (project.isData ? 'a' : 'b') + '.png',
              position: center,
            });
            map.add(marker);
            marker.content = '<div>'
              + '项目名称：' + project.name + '<br>'
              + '项目地址：' + project.area + '<br>'
              + '项目类型：' + project.model?.name + '<br>'
              + '开始坐标：' + project.startLocation + '<br>'
              + '结束坐标：' + project.endLocation + '<br>'
              + '<a href="/project/detail/map?id=' + project.id + '">详情></a>'
              + '</div>';
            var infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(0, 0)});
            marker.on('mouseover', (e) => {
              infoWindow.setContent(e.target.content);
              infoWindow.open(map, e.target.getPosition());
            });

            var path = [];
            for (let j = 0; j <= 1; j++) {
              var infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(0, 0)});
              var location = j ? project.endLocation : project.startLocation;
              var center = new AMap.LngLat(location[0], location[1]);
              var circleMarker = new AMap.CircleMarker({
                center: center,
                radius: 3,
                strokeColor: 'white',
                strokeWeight: 2,
                strokeOpacity: 1,
                fillColor: (project.isData ? '#f00' : '#0000ff'),
                fillOpacity: 1,
                zIndex: 10,
                bubble: true,
                cursor: 'pointer',
                clickable: true
              });
              circleMarker.setMap(map);
              path.push(center);
              circleMarker.content = '<div>经度：' + location[0] + '<br>纬度：' + location[1] + '</div>';
              circleMarker.on('mouseover', (e) => {
                infoWindow.setContent(e.target.content);
                infoWindow.open(map, e.target.getCenter());
              });
            }
            object3Dlayer.add(new AMap.Object3D.ThinLine({
              path: path,
              color: (project.isData ? '#f00' : '#0000ff')
            }));
          }
        } catch (e) {

        }
      })
      .catch(error => {
        message.error(error.message);
      });
  }, []);

  return <div className="index-map">
    <div className={'amap' + (loading ? ' loading' : '')} id="amap">
      {loading ? <Spin/> : null}
      {loading ? <Spin/> : <div className="state">
        <div className="title">状态</div>
        <div className="success">已完成</div>
        <div className="ing">进行中</div>
      </div>}
    </div>
  </div>;
};