import {Button, Card, Form, Input, message} from "antd";
import axios from "../../conf/axios";
import {useState} from "react";

export default () => {
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const onFinish = values => {
    setSubmitting(true);
    axios.post('/setMy', values)
      .finally(() => {
        setSubmitting(false);
      })
      .then(response => {
        form.resetFields();
        message.success('修改成功');
      })
      .catch(error => {
        message.error(error.message);
      });
  };
  return (
    <Card title="修改密码">
      <div style={{width: 444}}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="旧密码" name="oldPassword" rules={[{required: true}]}>
            <Input.Password/>
          </Form.Item>
          <Form.Item label="新密码" name="password" hasFeedback rules={[{required: true}]}>
            <Input.Password/>
          </Form.Item>
          <Form.Item label="重复输入" name="repeatPassword" hasFeedback rules={[
            {required: true},
            ({getFieldValue}) => ({
              validator(rule, value) {
                if (getFieldValue('password') == value) {
                  return Promise.resolve();
                }
                return Promise.reject('两次输入的密码不一致');
              },
            })
          ]}>
            <Input.Password/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={submitting}>提交</Button>
          </Form.Item>
        </Form>
      </div>
    </Card>
  );
};