import {AutoComplete, Avatar, Button, Card, DatePicker, Form, Input, message, Popconfirm, Select, Space, Table} from "antd";
import moment from "moment";
import React, {useEffect, useState} from "react";
import axios from "../../conf/axios";
import {SearchOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";
import App from "../../libs/App";

export default () => {
  const [whereForm] = Form.useForm();
  const where = {
    pagination: {
      /*pageSize: 2,
      currentPage: 1,*/
    }
  };

  const whereChange = values => {
    values = whereForm.getFieldsValue();
    for (var i in values) {
      where[i] = values[i];
    }
    where.pagination.currentPage = 1;
    fetch();
  };

  const [rolesLoading, setRolesLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    setRolesLoading(true);
    axios.post('/userRole/getUserRoles')
      .finally(() => {
        setRolesLoading(false);
      })
      .then(response => {
        const userRoles = response.data.data.userRoles.map(type => ({
          id: type.id,
          value: type.name,
        }));
        setRoles(userRoles);
      })
      .catch(error => {
        message.error(error.message);
      });
  }, []);

  const [companyKwOptions, setCompanyKwOptions] = useState([]);
  const onCompanyKwSearch = value => {
    if (App.user.isAdmin) {
      axios.post('/company/getCompanys', {kw: value})
        .then(response => {
          const companys = response.data.data.companys.map(company => ({
            value: company.name,
          }));
          setCompanyKwOptions(companys);
        })
        .catch(error => {
          message.error(error.message);
        });
    }
  };

  const columns = [
    {
      title: '姓名',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: '头像',
      dataIndex: 'avatar',
      width: 100,
      render: value => {
        return <Avatar src={value} alt="头像" size={35}/>;
      }
    },
    {
      title: '手机号码',
      dataIndex: 'phone'
    },
    {
      title: '角色',
      dataIndex: 'role',
      render: value => value?.name
    },
    {
      title: '单位',
      dataIndex: 'company',
      render: value => value?.name
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      width: 200,
      render: value => {
        return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
      }
    },
    {
      title: '操作',
      key: 'id',
      width: 200,
      render: (text, record) => (
        <Space>
          {
            App.hasAuth('user.setUser') ? (
              <Button type="primary" size="small"><Link to={'/user/edit?id=' + record.id}>编辑</Link></Button>
            ) : null
          }
          {
            App.hasAuth('user.deleteUser') ? (
              <Popconfirm title="确定删除此人员吗" onConfirm={() => adelete(record)}>
                <Button type="primary" size="small">删除</Button>
              </Popconfirm>
            ) : null
          }
        </Space>
      ),
    },
  ];
  if (!App.user.isAdmin) {
    columns.splice(1, 1);
  }
  if (!App.hasOneAuths('user.setUser', 'user.deleteUser')) {
    columns.splice(columns.length - 1, 1);
  }

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [pagination, setPagination] = useState([]);
  const tableChange = (pagination, filters, sorter) => {
    where.pagination = {
      pageSize: pagination.pageSize,
      currentPage: pagination.current,
    }
    fetch();
  };

  const fetch = (params = {}) => {
    setLoading(true);
    axios.post('/user/getUsers', where)
      .then(response => {
        let data = response.data.data;
        let pagination = data.pagination;
        setLoading(false);
        setDatas(data.users);
        setPagination({
          current: pagination.currentPage,
          pageSize: pagination.perPage,
          total: pagination.totalCount,
        });
      })
      .catch(error => {
        message.error(error.message);
      });
  };
  useEffect(() => {
    fetch();
  }, []);

  const adelete = values => {
    axios.post('/user/deleteUser', values)
      .then(response => {
        message.success('删除成功');
        fetch();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  return (
    <Card title="人员信息" extra={App.hasAuth('user.addUser') ? <Button type="primary"><Link to="/user/add">添加</Link></Button> : null}>
      <div className="where">
        <Form form={whereForm} onFinish={whereChange}>
          <div className="where-container">
            <div className="where-input">
              <Form.Item name="kw" label="关键字">
                <Input style={{width: 200}} placeholder="关键字" allowClear={true}/>
              </Form.Item>
              <Form.Item name="name" label="姓名">
                <Input style={{width: 200}} placeholder="姓名" allowClear={true}/>
              </Form.Item>
              <Form.Item name="phone" label="手机号码">
                <Input style={{width: 200}} placeholder="手机号码" allowClear={true}/>
              </Form.Item>
              <Form.Item name="roleId" label="角色">
                <Select style={{width: 175}} defaultValue="" placeholder="角色" loading={rolesLoading} onChange={whereChange}>
                  <Select.Option value="">全部角色</Select.Option>
                  {roles.map(value => <Select.Option value={value.id}>{value.value}</Select.Option>)}
                </Select>
              </Form.Item>
              {
                App.user.isAdmin ? (
                    <Form.Item name="companyKw" label="单位">
                      <AutoComplete
                          options={companyKwOptions}
                          style={{width: 300}}
                          onSearch={onCompanyKwSearch}
                          placeholder="单位"
                          allowClear={true}
                          onSelect={whereChange}
                      />
                    </Form.Item>
                ) : null
              },
              <Form.Item name="time" label="创建时间">
                <DatePicker.RangePicker placeholder="起始" onChange={whereChange}/>
              </Form.Item>
            </div>
            <div className="where-operate">
              <Button type="primary" htmlType="submit" icon={<SearchOutlined/>} loading={loading}>搜索</Button>
            </div>
          </div>
        </Form>
      </div>
      <Table columns={columns} dataSource={datas} pagination={pagination} loading={loading} onChange={tableChange}/>
    </Card>
  );
};