import {AutoComplete, Avatar, Button, Card, DatePicker, Form,Upload, Input, message, Popconfirm, Select, Space, Table, Tooltip, notification} from "antd";
import moment from "moment";
import React, {useEffect, useState} from "react";
import axios from "../../conf/axios";
import {SearchOutlined} from '@ant-design/icons';
import App from "../../libs/App";
import {Link} from "react-router-dom";
import ProjectSchemeInterface from "../projectScheme/Interface";

export default () => {
  const [whereForm] = Form.useForm();
  const [where, setWhere] = useState({
    pagination: {
      pageSize: 10,
      currentPage: 1,
    }
  });

  const whereChange = values => {
    values = whereForm.getFieldsValue();
    for (var i in values) {
      where[i] = values[i];
    }
    where.pagination.currentPage = 1;
    fetch();
  };

  const [typesLoading, setTypesLoading] = useState(false);
  const [types, setTypes] = useState([]);
  useEffect(() => {
    setTypesLoading(true);
    axios.post('/deviceModel/getDeviceModels')
      .finally(() => {
        setTypesLoading(false);
      })
      .then(response => {
        const types = response.data.data.deviceModels.map(type => ({
          id: type.id,
          value: type.name,
        }));
        setTypes(types);
      })
      .catch(error => {
        message.error(error.message);
      });
  }, []);

  const [deviceKwOptions, setDeviceKwOptions] = useState([]);
  const onDeviceKwSearch = value => {
    if (App.user.isAdmin) {
      axios.get('/device/getDevices', {params: {kw: value}})
        .then(response => {
          const devices = response.data.data.devices.map(device => ({
            value: device.name,
          }));
          setDeviceKwOptions(devices);
        })
        .catch(error => {
          message.error(error.message);
        });
    }
  };

  const [userKwOptions, setUserKwOptions] = useState([]);
  const onUserKwSearch = value => {
    axios.post('/user/getUsers', {kw: value})
        .then(response => {
          const users = response.data.data.users.map(user => ({
            value: user.name ? user.name : user.phone,
          }));
          setUserKwOptions(users);
        })
        .catch(error => {
          message.error(error.message);
        });
  };

  const [companyKwOptions, setCompanyKwOptions] = useState([]);
  const onCompanyKwSearch = value => {
    if (App.user.isAdmin) {
      axios.post('/company/getCompanys', {kw: value})
        .then(response => {
          const companys = response.data.data.companys.map(company => ({
            value: company.name,
          }));
          setCompanyKwOptions(companys);
        })
        .catch(error => {
          message.error(error.message);
        });
    }
  };
 const [videoUploadVideos, setImgUploadVideos] = useState([]);
 const [videos, setVideos] = useState([]);
  useEffect(() => {
    setImgUploadVideos(videoUploadVideos => {
      let tmp = videoUploadVideos.filter(videoUploadImg => {
        return videoUploadImg.status != 'done';
      });
      return tmp.length != videoUploadVideos.length ? tmp : videoUploadVideos;
    });
  }, [videoUploadVideos]);
    const imgAdd = ({file,data}) => {
    setImgUploadVideos(videoUploadVideos => {
      return videoUploadVideos.filter(videoUploadImg => {
        if (videoUploadImg.originFileObj.uid == file.uid) {
          videoUploadImg = {...videoUploadImg, percent: 77, status: 'uploading'};
        }
        return videoUploadImg;
      });
    });
    var url=window.URL.createObjectURL(file);
    var audioElement = new Audio(url);
    var duration=0;
    audioElement.addEventListener("loadedmetadata",function(_event){
       duration=audioElement.duration;
       var formData = new FormData();
       formData.append('id', data.id);
       formData.append('videos[0]', file);
       formData.append('duration',duration);
        axios.post('/project/addVideo', formData)
      .then(response => {
        let [img] = response.data.data.videos;
        setImgUploadVideos(videoUploadVideos => {
          return videoUploadVideos.map(videoUploadImg => {
            if (videoUploadImg.originFileObj.uid == file.uid) {
              videoUploadImg = {...videoUploadImg, percent: 100, status: 'done', url: img.img};
            }
            return videoUploadImg;
          });
        });
        setVideos(videos => {
          videos = [img, ...videos];
          if (videos.length > 20) {
            videos.pop();
          }
          return videos;
        });
        notification.success({
          message: file.name,
          description: '上传成功',
          placement: 'bottomRight',
          duration: 2.5
        });
      })
      .catch(error => {
        notification.error({
          message: file.name,
          description: '上传失败：' + error.message,
          placement: 'bottomRight',
        });
      });
    })
   
   
  }

  const [fileUploadFiles, setFileUploadFiles] = useState([]);
 const [files, setFiles] = useState([]);
 useEffect(() => {
    setFileUploadFiles(fileUploadFiles => {
      let tmp = fileUploadFiles.filter(fileUploadImg => {
        return fileUploadImg.status != 'done';
      });
      return tmp.length != fileUploadFiles.length ? tmp : fileUploadFiles;
    });
  }, [fileUploadFiles]);
  const fileAdd = ({file,data}) => {
    setFileUploadFiles(fileUploadFiles => {
      return fileUploadFiles.filter(fileUploadImg => {
        if (fileUploadImg.originFileObj.uid == file.uid) {
          fileUploadImg = {...fileUploadImg, percent: 77, status: 'uploading'};
        }
        return fileUploadImg;
      });
    });
    var formData = new FormData();
    formData.append('id', data.id);
    formData.append('files[0]', file);
    axios.post('/project/addData', formData)
      .then(response => {
        let [img] = response.data.data.videos;
        setFileUploadFiles(fileUploadFiles => {
          return fileUploadFiles.map(fileUploadImg => {
            if (fileUploadImg.originFileObj.uid == file.uid) {
              fileUploadImg = {...fileUploadImg, percent: 100, status: 'done', url: img.img};
            }
            return fileUploadImg;
          });
        });
        setVideos(files => {
          files = [img, ...files];
          if (files.length > 20) {
            files.pop();
          }
          return files;
        });
        notification.success({
          message: file.name,
          description: '上传成功',
          placement: 'bottomRight',
          duration: 2.5
        });
      })
      .catch(error => {
        notification.error({
          message: file.name,
          description: '上传失败：' + error.message,
          placement: 'bottomRight',
        });
      });
  }

  const [columns, setColumns] = useState([]);
  const allColumns = [
    {
      title: '类型',
      dataIndex: 'model',
      width: 150,
      render: value => value.name
    },
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '区域',
      dataIndex: 'area',
    },
    {
      title: '设备',
      dataIndex: 'device',
      width: 200,
      render: (value, record) => <>
        <Space>
          <Avatar src={record.model?.img} alt={record.model?.name} size={30}/>
          {record.device?.identifier}
        </Space>
      </>
    },
    {
      title: '单位',
      dataIndex: 'company',
      render: value => value?.name
    },
    {
      title: '用户',
      dataIndex: 'user',
      render: value => value?.name
    },
    {
      title: '管线',
      dataIndex: 'isPipeline',
      key: 'pipeline',
      align: 'center',
      render: (value, record) => {
        if (value || App.user.isAdmin) {
          return <Link to={'/project/detail/pipeline?id=' + record.id}>{value ? '查看' : '设置'}</Link>
        } else {
          return '无';
        }
      }
    },
    {
      title: '方案',
      dataIndex: 'isScheme',
      key: 'scheme',
      render: (value, record) => {
        if (value) {
          return <a href={record.scheme.file} target="_blank">查看</a>
        } else {
          return (
            <>
              <Tooltip title={record.isPipeline ? '' : '请先设置管线数据'}>
                <Button type="link" disabled={!record.isPipeline} onClick={() => {
                  setData(record);
                  setOnSelect(() => {
                    return scheme => {
                      /*record.isScheme = true;
                      record.scheme = scheme;*/
                      fetch();
                    }
                  });
                  setProjectSchemeVisible(true);
                }}>选择</Button>
              </Tooltip>
            </>
          )
        }
      }
    },
    {
      title: '标定',
      dataIndex: 'isCalibrate',
      align: 'center',
      key: 'calibrate',
      render: (value, record) => {
        if (value || App.user.isAdmin) {
          return <Link to={'/project/detail/calibrate?id=' + record.id}>{value ? '查看' : '设置'}</Link>
        } else {
          return '无';
        }
      }
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      width: 175,
      render: value => {
        return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '';
      }
    },
    {
      title: '上传',
      dataIndex:'isUpload',
      key: 'upload',
      width: 150,
      render: (text, record) => (
         <Space>
         <div className="videoUpload">
            <div className="videoUploadContainer">
              <Upload
                listType="picture"
                data={record}
                fileList={videoUploadVideos}
                onChange={({fileList}) => setImgUploadVideos(fileList)}
                customRequest={imgAdd}
              >
                <Button type="primary">上传视频</Button>
              </Upload>
            </div>
          </div>
         <Upload
                multiple
                data={record}
                fileList={fileUploadFiles}
                onChange={({fileList}) => setFileUploadFiles(fileList)}
                customRequest={fileAdd}
              >
                <Button type="primary">上传数据</Button>
              </Upload>
        </Space>
      ),
    },
    {
      title: '查看',
      key: 'id',
      width: 150,
      render: (text, record) => (
        <Space>
          {
            record.modelId == 'SICR' ?
              <>
                <Link to={'/project/detail/dataChart?id=' + record.id}>数据</Link>
                <Link to={'/project/detail/misspoint?id=' + record.id}>漏点</Link>
              </>
              : <>
                <Link to={'/project/detail/img?id=' + record.id}>图片</Link>
                <Link to={'/project/detail/video?id=' + record.id}>视频</Link>
              </>
          }
        </Space>
      ),
    },
    {
      title: '操作',
      key: 'id',
      width: 150,
      align: 'center',
      render: (text, record) => (
        <Space>
          <Button type="primary" size="small"><Link to={'/project/detail?id=' + record.id}>查看</Link></Button>
          {
            App.hasAuth('project.deleteProject') ? (
              <Popconfirm title="确定删除此工作吗" onConfirm={() => adelete(record)}>
                <Button type="primary" size="small">删除</Button>
              </Popconfirm>
            ) : null
          }
        </Space>
      ),
    },
  ];
  if (!App.user.isAdmin) {
    allColumns.splice(4, 1);
  }

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [pagination, setPagination] = useState([]);
  const tableChange = (pagination, filters, sorter) => {
    where.pagination = {
      pageSize: pagination.pageSize,
      currentPage: pagination.current,
    }
    fetch();
  };

  const fetch = () => {
    let columns = [];
    allColumns.forEach((column, index) => {
      let is = true;
      if (where.modelId != 'SICR') {
        if (column.key == 'pipeline'
          || column.key == 'calibrate'
          || column.key == 'scheme') {
          is = false;
        }
      }
      if (where.modelId != 'S3VR' && where.modelId != 'SOVR' && where.modelId != 'SVVR') {
        if (column.key == 'upload') {
          is = false;
        }
      }
      if (is) {
        columns.push(column);
      }
    });
    setLoading(true);
    axios.post('/project/getProjects', where)
      .then(response => {
        let data = response.data.data;
        let pagination = data.pagination;
        setLoading(false);
        setDatas(data.projects);
        setPagination({
          current: pagination.currentPage,
          pageSize: pagination.perPage,
          total: pagination.totalCount,
        });
        setColumns(columns);
      })
      .catch(error => {
        message.error(error.message);
      });
  };
  useEffect(() => {
    fetch();
  }, []);

  const adelete = values => {
    axios.post('/project/deleteDevice', {id: values.id})
      .then(response => {
        message.success('删除成功');
        fetch();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  const [data, setData] = useState({});
  const [onSelect, setOnSelect] = useState();
  const [projectSchemeVisible, setProjectSchemeVisible] = useState(false);

  return (
    <Card title="项目工作" extra={App.hasAuth('project.addProject') ? <Button type="primary"><Link to="/project/add">添加</Link></Button> : null}>
      <div className="where">
        <Form form={whereForm} onFinish={whereChange}>
          <div className="where-container">
            <div className="where-input">
              <Form.Item name="kw" label="关键字">
                <Input style={{width: 200}} placeholder="关键字" allowClear={true}/>
              </Form.Item>
              <Form.Item name="name" label="名称">
                <Input style={{width: 200}} placeholder="名称" allowClear={true}/>
              </Form.Item>
              <Form.Item name="area" label="区域">
                <Input style={{width: 200}} placeholder="区域" allowClear={true}/>
              </Form.Item>
              <Form.Item name="modelId" label="类型">
                <Select style={{width: 175}} defaultValue="" placeholder="类型" loading={typesLoading} onChange={whereChange}>
                  <Select.Option value="">全部类型</Select.Option>
                  {types.map(value => <Select.Option value={value.id}>{value.value}</Select.Option>)}
                </Select>
              </Form.Item>
              <Form.Item name="deviceKw" label="设备">
                <AutoComplete
                    options={deviceKwOptions}
                    style={{width: 200}}
                    onSearch={onDeviceKwSearch}
                    placeholder="设备"
                    allowClear={true}
                    onSelect={whereChange}
                />
              </Form.Item>
              {
                App.user.isAdmin ? (
                    <Form.Item name="companyKw" label="单位">
                      <AutoComplete
                          options={companyKwOptions}
                          style={{width: 250}}
                          onSearch={onCompanyKwSearch}
                          placeholder="单位"
                          allowClear={true}
                          onSelect={whereChange}
                      />
                    </Form.Item>
                ) : null
              }
              <Form.Item name="userKw" label="用户">
                <AutoComplete
                    options={userKwOptions}
                    style={{width: 125}}
                    onSearch={onUserKwSearch}
                    placeholder="用户"
                    allowClear={true}
                    onSelect={whereChange}
                />
              </Form.Item>
              <Form.Item name="time" label="创建时间">
                <DatePicker.RangePicker placeholder="起始" onChange={whereChange}/>
              </Form.Item>
            </div>
            <div className="where-operate">
              <Button type="primary" htmlType="submit" icon={<SearchOutlined/>} loading={loading}>搜索</Button>
            </div>
          </div>
        </Form>
      </div>
      <Table columns={columns} dataSource={datas} pagination={pagination} loading={loading} onChange={tableChange}/>
      <ProjectSchemeInterface
        visible={projectSchemeVisible}
        data={data}
        onCancel={() => setProjectSchemeVisible(false)}
        onSelect={onSelect}
      />
    </Card>
  );
};


