import React, {useEffect} from "react";

export default ({project}) => {
  useEffect(() => {
    try {
      if (!(project.startLocation.length && project.endLocation.length)) {
        return;
      }
      var map = new AMap.Map('amap', {
        center: [(project.startLocation[0] * 1 + project.endLocation[0] * 1) / 2, (project.startLocation[1] * 1 + project.endLocation[1] * 1) / 2],
        viewMode: '3D',
        zoom: 15,
        layers: [
          new AMap.TileLayer.Satellite()
        ]
      });
      var object3Dlayer = new AMap.Object3DLayer({zIndex: 10});
      map.add(object3Dlayer);
      var path = [];
      var infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(0, 0)});
      for (let j = 0; j <= 1; j++) {
        var location = j ? project.endLocation : project.startLocation;
        var center = new AMap.LngLat(location[0], location[1]);
        var circleMarker = new AMap.CircleMarker({
          center: center,
          radius: 3,
          strokeColor: 'white',
          strokeWeight: 2,
          strokeOpacity: 1,
          fillColor: (project.isData ? '#f00' : '#0000ff'),
          fillOpacity: 1,
          zIndex: 10,
          bubble: true,
          cursor: 'pointer',
          clickable: true
        })
        circleMarker.setMap(map)
        path.push(center)
        circleMarker.content = '<div>经度：' + location[0] + '<br>纬度：' + location[1] + '</div>';
        circleMarker.on('mouseover', (e) => {
          infoWindow.setContent(e.target.content);
          infoWindow.open(map, e.target.getCenter());
        });
      }
      object3Dlayer.add(new AMap.Object3D.ThinLine({
        path: path,
        color: (project.isData ? '#f00' : '#0000ff')
      }));
    } catch (e) {

    }
  }, []);

  return <div className="amap" id="amap"/>;
};