import React from "react";
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {Modal} from "antd";
import Auth from "./libs/Auth";
import app from "./libs/App";
import 'antd/dist/antd.css';
import './css/ant.css';
import axios from "./conf/axios";
import {routes} from "./conf/routes";
import redux from "./conf/redux";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      isLogin: false,
    }
  }

  componentWillMount() {
    redux.subscribe(() => {
      this.setState({isLogin: redux.getState().isLogin});
    });
    if (Auth.hasToken()) {
      axios.post('/getMy')
        .then(response => {
          app.user = response.data.data;
          app.user.isAdmin = !!app.user.role && app.user.role.id == 'admin';
          this.setState({isReady: true, isLogin: true});
        })
        .catch(error => {
          if (error.code != 1000) {
            Modal.error({
              title: '错误',
              content: error.message,
              onOk: (() => {
                this.setState({isReady: true});
              })
            });
          } else {
            this.setState({isReady: true});
          }
        });
    } else {
      this.setState({isReady: true});
    }
  }

  render() {
    let {isReady, isLogin} = this.state
    if (isReady) {
      if (isLogin) {
        return (
          <Router>
            <Switch>
              {
                routes.map(route => {
                  return (
                    <Route key={route.path} path={route.path} render={props => {
                      document.title = route.title;
                      return <route.component {...props} />
                    }}/>
                  );
                })
              }
            </Switch>
          </Router>
        );
      } else {
        return (
          <Router>
            <Switch>
              {
                [routes[0]].map(route => {
                  return (
                    <Route key={route.path} path={route.path} render={props => {
                      document.title = route.title;
                      return <route.component {...props} />
                    }}/>
                  );
                })
              }
              <Redirect to='/login'/>
            </Switch>
          </Router>
        );
      }
    } else {
      return null;
    }
  }
}

export default App;