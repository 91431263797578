import React, {useEffect, useState} from "react";
import {projectDetailRoutes} from "../../conf/routes";
import {Link, Route, Switch} from "react-router-dom";
import {Button, Card, Menu, message, Skeleton, Space} from "antd";
import {DatabaseOutlined, LineOutlined, SettingOutlined, HeatMapOutlined, AimOutlined, LineChartOutlined, PictureOutlined, VideoCameraOutlined, FundOutlined} from '@ant-design/icons';
import URI from "urijs";
import axios from "../../conf/axios";
import App from "../../libs/App";
import redux from "./redux";
import {v4 as uuidv4} from "uuid";

export default props => {
  const [routeForceRenderKey, setRouteForceRenderKey] = useState();
  let pathname = props.location.pathname;
  if (pathname == '/project/detail') {
    pathname = '/project/detail/base';
  }
  let current = [pathname];

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState({});
  let params = URI().search(true);
  useEffect(() => {
    let unsubscribe;
    axios.post('/project/getProject', {id: params.id})
      .then(response => {
        let {project} = response.data.data;
        setProject(project);
        redux.dispatch({
          type: 'type',
          data: project
        });
        unsubscribe = redux.subscribe(() => {
          setProject(redux.getState());
        })
      })
      .then(response => {
        setLoading(false);
      })
      .catch(error => {
        message.error(error.message);
      });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return (
    <Card className="projectDetail" title="项目工作" extra={<Button type="primary"><Link to="/project">返回列表</Link></Button>}>
      <div className="projectDetailBox">
        <div className="projectDetailTab">
          <Menu mode="horizontal" defaultSelectedKeys={current}>
            <Menu.Item key="/project/detail/base" icon={<DatabaseOutlined/>}>
              <Link to={'/project/detail?id=' + project.id} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>基本信息</Link>
            </Menu.Item>
            <Menu.Item key="/project/detail/map" icon={<DatabaseOutlined/>}>
              <Link to={'/project/detail/map?id=' + project.id} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>地图</Link>
            </Menu.Item>
            {
              project.modelId ? (
                <>
                  {
                    project.modelId == 'SICR' ? (
                      <>
                        <Menu.Item key="/project/detail/pipeline" icon={<LineOutlined/>} disabled={!project.isPipeline && !App.user.isAdmin}>
                          <Link to={'/project/detail/pipeline?id=' + params.id} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>管线</Link>
                        </Menu.Item>
                        <Menu.Item key="/project/detail/scheme" icon={<SettingOutlined/>} disabled={!project.isPipeline}>
                          <Link to={'/project/detail/scheme?id=' + project.id} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>方案</Link>
                        </Menu.Item>
                        <Menu.Item key="/project/detail/calibrate" icon={<HeatMapOutlined/>} disabled={!project.isCalibrate && !App.user.isAdmin}>
                          <Link to={'/project/detail/calibrate?id=' + params.id} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>标定</Link>
                        </Menu.Item>
                      </>
                    ) : null
                  }
                  {
                    project.modelId == 'SCTR' || project.modelId == 'SLMR' ? (
                      <>
                        <Menu.Item key="/project/detail/rawData" icon={<LineOutlined/>} >
                          <Link to={'/project/detail/rawData?id=' + params.id} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>原始记录表</Link>
                        </Menu.Item>
                        <Menu.Item key="/project/detail/report" icon={<LineOutlined/>} >
                          <Link to={'/project/detail/report?id=' + params.id} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>检测报告</Link>
                        </Menu.Item>
                        <Menu.Item key="/project/detail/scheme" icon={<SettingOutlined/>} disabled={!project.isPipeline}>
                          <Link to={'/project/detail/scheme?id=' + project.id} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>数据报告</Link>
                        </Menu.Item>
                      </>
                    ) : null
                  }
                  {
                    project.modelId == 'SICR' ? (
                      <>
                        <Menu.Item key="/project/detail/dataChartPreview" icon={<FundOutlined/>}>
                          <Link to={'/project/detail/dataChartPreview?id=' + params.id} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>数据图表预览</Link>
                        </Menu.Item>
                        <Menu.Item key="/project/detail/dataChart" icon={<LineChartOutlined/>}>
                          <Link to={'/project/detail/dataChart?id=' + params.id} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>数据图表</Link>
                        </Menu.Item>
                        {
                          project.isReport || project.isChart ? (
                            <Space>
                              （
                              {project.isReport ? <a href={project.reportFile} target="_blank">查看报表</a> : null}
                              {project.isReport && project.isChart ? '|' : null}
                              {project.isChart ? <a href={project.chartFile} target="_blank">查看图表</a> : null}
                              ）
                            </Space>
                          ) : null
                        }
                        <Menu.Item key="/project/detail/misspoint" icon={<AimOutlined />}>
                          <Link to={'/project/detail/misspoint?id=' + params.id} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>漏点</Link>
                        </Menu.Item>
                      </>
                    ) : (
                      <>
                        <Menu.Item key="/project/detail/img" icon={<PictureOutlined/>}>
                          <Link to={'/project/detail/img?id=' + params.id} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>图片</Link>
                        </Menu.Item>
                        <Menu.Item key="/project/detail/video" icon={<VideoCameraOutlined/>}>
                          <Link to={'/project/detail/video?id=' + params.id} onClick={() => setRouteForceRenderKey(uuidv4().toString())}>视频</Link>
                        </Menu.Item>
                      </>
                    )
                  }
                  
                </>
              ) : null
            }
          </Menu>
        </div>
        <div className="projectDetailMain">
          {
            loading ? <Skeleton active/> : (
              <Switch>
                {
                  projectDetailRoutes.map(route => {
                    return (
                      <Route path={route.path} exact={route.exact} render={props => {
                        document.title = route.title;
                        return <route.component {...props} project={project} key={routeForceRenderKey}/>
                      }}/>
                    );
                  })
                }
              </Switch>
            )
          }
        </div>
      </div>
    </Card>
  );
};