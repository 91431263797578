import {Button, message, Spin, Image, Upload, Pagination, Empty, notification} from "antd";
import React, {useEffect, useState} from "react";
import axios from "../../conf/axios";
import {imgPlaceholder} from "../../conf/general";
import moment from "moment";
import App from "../../libs/App";
import {useUpdateEffect} from "react-use";

export default ({project}) => {
  const [imgsLoading, setImgsLoading] = useState(true);
  const [imgs, setImgs] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 24,
    current: 1,
    total: 0,
  });
  const imgsLoad = () => {
    setImgsLoading(true);
    axios.post('/project/getImgs', {
      id: project.id,
      pageSize: pagination.pageSize,
      currentPage: pagination.current,
    })
      .then(response => {
        let {pagination, imgs} = response.data.data;
        setImgs(imgs);
        setPagination(oldPagination => {
          oldPagination.pageSize = pagination.perPage;
          oldPagination.current = pagination.currentPage;
          oldPagination.total = pagination.totalCount;
          return oldPagination;
        });
        setImgsLoading(false);
      })
      .catch(error => {
        message.error(error.message);
      });
  }
  useEffect(() => {
    imgsLoad();
  }, []);
  useUpdateEffect(() => {
    imgsLoad();
  }, [pagination]);

  const [imgUploadImgs, setImgUploadImgs] = useState([]);
  useEffect(() => {
    setImgUploadImgs(imgUploadImgs => {
      let tmp = imgUploadImgs.filter(imgUploadImg => {
        return imgUploadImg.status != 'done';
      });
      return tmp.length != imgUploadImgs.length ? tmp : imgUploadImgs;
    });
  }, [imgUploadImgs]);
  const imgAdd = ({file}) => {
    setImgUploadImgs(imgUploadImgs => {
      return imgUploadImgs.filter(imgUploadImg => {
        if (imgUploadImg.originFileObj.uid == file.uid) {
          imgUploadImg = {...imgUploadImg, percent: 77, status: 'uploading'};
        }
        return imgUploadImg;
      });
    });
    var formData = new FormData();
    formData.append('id', project.id);
    formData.append('imgs[0]', file);
    axios.post('/project/addImg', formData)
      .then(response => {
        let [img] = response.data.data.imgs;
        setImgUploadImgs(imgUploadImgs => {
          return imgUploadImgs.map(imgUploadImg => {
            if (imgUploadImg.originFileObj.uid == file.uid) {
              imgUploadImg = {...imgUploadImg, percent: 100, status: 'done', url: img.img};
            }
            return imgUploadImg;
          });
        });
        setImgs(imgs => {
          imgs = [img, ...imgs];
          if (imgs.length > 20) {
            imgs.pop();
          }
          return imgs;
        });
        notification.success({
          message: file.name,
          description: '上传成功',
          placement: 'bottomRight',
          duration: 2.5
        });
      })
      .catch(error => {
        notification.error({
          message: file.name,
          description: '上传失败：' + error.message,
          placement: 'bottomRight',
        });
      });
  }

  return (
    <div className="projectImg">
      {
        App.hasAuth('project.setProject') ? (
          <div className="imgUpload">
            <div className="imgUploadContainer">
              <Upload
                multiple
                listType="picture"
                fileList={imgUploadImgs}
                onChange={({fileList}) => setImgUploadImgs(fileList)}
                customRequest={imgAdd}
              >
                <Button type="primary">添加</Button>
              </Upload>
            </div>
          </div>
        ) : null
      }
      <Spin spinning={imgsLoading} tip="loading">
        <div className="imgsBox">
          {
            imgs.length > 0 ? (
              <div className="imgsContainer">
                {
                  imgs.map(img => {
                    return (
                      <div className="imgItem">
                        <div className="imgWraper">
                          <div className="imgContainer">
                            <Image src={img.img} fallback={imgPlaceholder}/>
                          </div>
                        </div>
                        <div className="imgText">
                          {img.createTime ? moment(img.createTime * 1000).format('YYYY-MM-DD HH:mm:ss') : ''}
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            ) : (imgsLoading ? null : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
          }
          <div style={{marginTop: 15, textAlign: "center"}}>
            <Pagination
              {...pagination}
              hideOnSinglePage
              onChange={(page, pageSize) => setPagination({...pagination, current: page, pageSize: pageSize})}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
};