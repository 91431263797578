import React, {useEffect, useState} from "react";
import {Form, Input, Card, Switch, Radio, Button, message, Image, Skeleton, Space, Select, DatePicker} from "antd";
import axios from "../../conf/axios";
import {imgPlaceholder} from "../../conf/general";
import FileInput from "../../components/ant/FileInput";
import redux from "./redux";
import moment from "moment";
import App from "../../libs/App";

export default ({project}) => {
  const [loading, setLoading] = useState(true);
  const [rawData, setRawData] = useState({});
  const [jcData, setJcData] = useState({});
  const load = () => {
    setLoading(true);
    var data1=localStorage.getItem('rawData');
    console.log(project)
    if(data1){
      var data2=JSON.parse(data1);
      var expireTime=data2.time+360000;
      var curTime1=new Date().getTime();
       if(curTime1>expireTime){
       axios.post('/project/getRawData', {id: project.id})
      .then(response => {
        let {rawData,jcData} = response.data.data;
        setRawData(rawData);
        setJcData(jcData);
        setData(rawData);
        setLoading(false);
      })
      .catch(error => {
        message.error(error.message);
      });
    }else{
      var data3=new Object();
      data3.rawData=data2.data;;
      let {rawData} = data3
      console.log(data3);
      setRawData(rawData);
      setData(rawData);
      setLoading(false);
    }
    }else{
       axios.post('/project/getRawData', {id: project.id})
      .then(response => {
        let {rawData,jcData} = response.data.data;
        setRawData(rawData);
        setData(rawData);
        setJcData(jcData);
        setLoading(false);
      })
      .catch(error => {
        message.error(error.message);
      });
    }
    
  }
  useEffect(() => {
    load();
  }, []);

  const load1 = () => {
    console.log(jcData)
  }
useEffect(() => {
    load1();
  }, []);
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const save = values => {
    if (saving) {
      return;
    }
    setSaving(true);
    var curTime=new Date().getTime();
    localStorage.setItem('rawData',JSON.stringify({data:values,time:curTime}));
    redux.dispatch({
          type: 'type',
          data: values
    });
    message.success('保存成功');
    setSaving(false);
    location.reload();
  }

   const handleExport = () => {
     axios.post('/project/rawdata', {id: project.id,modelId:project.modelId,data:rawData})
      .then(response => {
        message.success('导出成功', 0.5, () => {
          setTimeout(() => {
             window.open(response.data.data.pdf);
          }, 500);
        });
      })
      .catch(error => {
        message.error(error.message);
      });
   }

  return (
    <Card className="projectDetail" title="原始记录表" extra={<Button type="primary"  onClick={handleExport}>导出原始数据</Button>}>
    <div className="projectPipeline" style={{width: 1000}}>
      {
        loading ? <Skeleton active/> : (
          <Form
            className="ant-form-table"
            form={form}
            onValuesChange={changedValues => setData(data => {
              return {...data, ...changedValues}
            })}
            onFinish={save}
          >
            <table className="table table-bordered">
           <tr>
              <td colspan="9">涂层厚度原始记录表</td>
           </tr>
           <tr>
              <td colspan="2">{data.no}</td>  
              <td></td>  
              <td></td>  
              <td></td>  
              <td></td>  
              <td></td>
              <td colspan="2">共1页：第1页</td>  
           </tr>
           <tr>
              <td>产品名称</td> 
              <td colspan="2">{
                !editing ? data.productName : (
                  <Form.Item name="productName">
                    <Input placeholder="产品名称" />
                  </Form.Item>
                )
              }</td>  
              <td>规格型号</td> 
              <td colspan="2">
                {
                !editing ? data.Specifications : (
                  <Form.Item name="Specifications">
                    <Input placeholder="规格型号" />
                  </Form.Item>
                )
              }
              </td>
              <td>样品编号</td> 
              <td colspan="2">
               {
                !editing ? data.No : (
                  <Form.Item name="No">
                    <Input placeholder="样品编号" />
                  </Form.Item>
                )
              }
              </td> 
           </tr>
           <tr>
              <td>委托单位</td> 
              <td colspan="2">
              {
                !editing ? data.entrustedUnit : (
                  <Form.Item name="entrustedUnit">
                    <Input placeholder="委托单位" />
                  </Form.Item>
                )
              }
              </td>  
              <td>生产单位</td> 
              <td colspan="2">
              {
                !editing ? data.proCom : (
                  <Form.Item name="proCom">
                    <Input placeholder="生产单位" />
                  </Form.Item>
                )
              }
              </td>
              <td>样品状态</td> 
              <td colspan="2">
              {
                !editing ? data.status : (
                  <Form.Item name="status">
                    <Input placeholder="样品状态" />
                  </Form.Item>
                )
              }
              </td> 
           </tr>
           <tr>
              <td>防腐类型</td> 
              <td colspan="2">
               {
                !editing ? data.type : (
                  <Form.Item name="type">
                    <Input placeholder="防腐类型" />
                  </Form.Item>
                )
              }
              </td>  
              <td>依据标准</td> 
              <td colspan="2">
              {
                !editing ? data.standard : (
                  <Form.Item name="standard">
                    <Input placeholder="依据标准" />
                  </Form.Item>
                )
              }
              </td>
              <td>检验地点</td> 
              <td colspan="2">
              {
                !editing ? data.testAdr : (
                  <Form.Item name="testAdr">
                    <Input placeholder="检验地点" />
                  </Form.Item>
                )
              }
              </td> 
           </tr>
           <tr>
              <td>检验方法</td> 
              <td colspan="2">
              {
                !editing ? data.testWay : (
                  <Form.Item name="testWay">
                    <Input placeholder="检验方法" />
                  </Form.Item>
                )
              }
              </td>  
              <td>检验设备</td> 
              <td colspan="2">
              {
                !editing ? data.testDev : (
                  <Form.Item name="testDev">
                    <Input placeholder="检验设备" />
                  </Form.Item>
                )
              }
              </td>
              <td>设备编号</td> 
              <td colspan="2">
              {
                !editing ? data.devNo : (
                  <Form.Item name="devNo">
                    <Input placeholder="设备编号" />
                  </Form.Item>
                )
              }
              </td> 
           </tr>
           <tr>
              <td>环境条件</td> 
              <td colspan="2">
              {
                !editing ? data.encondition : (
                  <Form.Item name="encondition">
                    <Input placeholder="环境条件" />
                  </Form.Item>
                )
              }
              </td>  
              <td>检测日期</td> 
              <td colspan="2">
              {
                !editing ? data.testdate : (
                  <Form.Item name="testdate">
                    <Input placeholder="检测日期" />
                  </Form.Item>
                )
              }
              </td>
              <td>管道长度</td> 
              <td colspan="2">
              {
                !editing ? data.length : (
                  <Form.Item name="length">
                    <Input placeholder="管道长度" />
                  </Form.Item>
                )
              }
              </td> 
           </tr>
            {
              project.modelId ? (
                <>
                  {
                    project.modelId == 'SCTR' ? (
                      <>
                         <tr>
              <td colspan="9">检测结果统计数据</td>
           </tr>
           <tr>
              <td colspan="5">涂层厚度最大值</td>
              <td colspan="4">涂层厚度最小值</td>
           </tr>
           <tr>
              <td colspan="2">位置(m)</td>
              <td colspan="3">涂层厚度(um)</td>
              <td colspan="2">位置(m)</td>
              <td colspan="2">涂层厚度(um)</td>
           </tr>
           <tr>
              <td colspan="2">{
                !editing ? data.location1 : (
                  <Form.Item name="location1">
                    <Input placeholder="位置(m)" />
                  </Form.Item>
                )
              }</td>
              <td colspan="3">
              {
                !editing ? data.thickness1 : (
                  <Form.Item name="thickness1">
                    <Input placeholder="涂层厚度(um)" />
                  </Form.Item>
                )
              }
              </td>
              <td colspan="2">
              {
                !editing ? data.location2 : (
                  <Form.Item name="location2">
                    <Input placeholder="位置(m)" />
                  </Form.Item>
                )
              }
              </td>
              <td colspan="2">
              {
                !editing ? data.thickness2 : (
                  <Form.Item name="thickness2">
                    <Input placeholder="涂层厚度(um)" />
                  </Form.Item>
                )
              }
              </td>
           </tr>
           <tr>
              <td colspan="2">涂层厚度检测总点数</td>
              <td>
              {
                !editing ? data.qualified : (
                  <Form.Item name="qualified">
                    <Input placeholder="涂层厚度检测总点数" />
                  </Form.Item>
                )
              }
              </td>
              <td colspan="2">涂层厚度检测不合格点数</td>
              <td>
              {
                !editing ? data.hunqualified : (
                  <Form.Item name="hunqualified">
                    <Input placeholder="涂层厚度检测不合格点数" />
                  </Form.Item>
                )
              }
              </td>
              <td colspan="2">不合格点比例</td>
              <td>
              {
                !editing ? data.unqualified : (
                  <Form.Item name="unqualified">
                    <Input placeholder="不合格点比例" />
                  </Form.Item>
                )
              }
              </td>
           </tr>
           <tr>
              <td colspan="2">涂层平均厚度(um)</td>
              <td colspan="3">
              {
                !editing ? data.averh : (
                  <Form.Item name="averh">
                    <Input placeholder="涂层平均厚度" />
                  </Form.Item>
                )
              }
              </td>
              <td colspan="2">涂层厚度检测点距离(mm)</td>
              <td colspan="2">
              {
                !editing ? data.hdistance : (
                  <Form.Item name="hdistance">
                    <Input placeholder="涂层厚度检测点距离" />
                  </Form.Item>
                )
              }
              </td>
           </tr>
           <tr>
              <td colspan="9">数据整体预览图</td>
           </tr>
           <tr>
              <td colspan="9">
               <Image  src={data.imgsrc} />
              </td>
           </tr>
           

                      </>
                    ) : null
                  }
                  {
                    project.modelId == 'SLMR'  ? (
                      <>
                        <tr>
                         <td colspan="2">漏点数量合计</td>
              <td colspan="7">
              {
                !editing ? data.pointAll : (
                  <Form.Item name="pointAll">
                    <Input placeholder="漏点数量合计" />
                  </Form.Item>
                )
              }
              </td>
                        </tr>

                      </>
                    ) : null
                  }
                  {
                    project.modelId == 'SICR' ? (
                      <>
                       <tr>
                         <td colspan="2">外观描述</td>
              <td colspan="7">
              {
                !editing ? data.description : (
                  <Form.Item name="description">
                    <Input placeholder="外观描述" />
                  </Form.Item>
                )
              }
              </td>
                        </tr>
                        <tr>
                        </tr>
                      </>
                    ) : null
                  }
                  
                </>
              ) : null
            }
            <tr>
              <td>检测人</td>
              <td>{data.testPerson}</td>
              <td colspan="5"></td>
              <td>复审人</td>
              <td>{data.review}</td>
            </tr>
        </table>
            <div style={{marginTop: 15, textAlign: 'center'}}>
 
              {
            !editing && App.hasAuth('project.setProject') ? (
              <Button type="primary" onClick={()  => {
                    form.setFieldsValue({...rawData, productionTime: moment(rawData.productionTime * 1000)});
                    setEditing(true);
                  }}>编辑</Button>
            ) : null
          }
              {
                editing ? (
                  <Space>
                    <Button type="primary" htmlType="submit" loading={saving}>保存</Button>
                    <Button type="ghost" onClick={() => setEditing(false)}>返回</Button>
                  </Space>
                ) : null
              }
            </div>
          </Form>
        )
      }
    </div>
    </Card>
  );
};

