import React from 'react';
import {Button, Card, DatePicker, Drawer, Form, Input, message, Popconfirm, Select, Space, Spin, Table} from 'antd';
import moment from 'moment';
import axios from '../../conf/axios';
import App from "../../libs/App";
import {SearchOutlined} from "@ant-design/icons";

const types = [{
  id: 'exception',
  name: '功能异常',
}, {
  id: 'experience',
  name: '体验问题',
}, {
  id: 'future',
  name: '新功能建议',
}, {
  id: 'other',
  name: '其他',
}];

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          title: '类型',
          dataIndex: 'typeId',
          width: 100,
          render: (value, record) => {
            let type;
            types.forEach(item => {
              if (item.id == value) {
                type = item;
              }
            });
            return type ? type.name : '';
          }
        },
        {
          title: '用户姓名',
          dataIndex: 'user',
          width: 100,
          render: (value, record) => value.name,
        },
        {
          title: '单位',
          dataIndex: 'company',
          width: 200,
          render: (value, record) => value.name,
        },
        {
          title: '反馈内容',
          dataIndex: 'content',
          width: 300,
        },
        {
          title: '时间',
          dataIndex: 'time',
          width: 200,
          render: value => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '';
          }
        },
        {
          title: '处理状态',
          dataIndex: 'is_reply',
          width: 300,
          render: (value, record) => {
            if (record.isReply) {
              return <div>
                <div>已回复</div>
                <div>时间：{moment(record.replyTime * 1000).format('YYYY-MM-DD HH:mm:ss')}</div>
                <div>内容：{record.replyContent}</div>
                <div>用户：{record.replyUser?.name}</div>
              </div>;
            } else {
              return '';
            }
          }
        },
        {
          title: '操作',
          key: 'id',
          width: 200,
          render: (text, record) => (
              <Space>
                {
                  App.hasAuth('feedback.setFeedback') ? (
                      <Button type="primary" size="small" onClick={() => this.drawerReply(record)}>回复</Button>
                  ) : null
                }
                {
                  App.hasAuth('feedback.deleteFeedback') ? (
                      <Popconfirm title="确定删除此版本吗" onConfirm={value => {
                        this.delete({id: record.id})
                      }}>
                        <Button type="primary" size="small">删除</Button>
                      </Popconfirm>
                  ) : null
                }
              </Space>
          ),
        },
      ],
      data: [],
      pagination: {},
      loading: false,
      record: {
        user: {},
        company: {},
        replyUser: {},
      },
      visible: false,
      editLoading: false,
      submitting: false,
      buttonText: '回复',
      default: {time: moment(), is_force: true, is_latest: true},
      user: [],
      users: [],
      userFetching: false,
      company: [],
      companys: [],
      companyFetching: false,
    };

    if (!App.hasOneAuths('feedback.setFeedback', 'feedback.deleteFeedback')) {
      this.state.columns.splice(1, 1);
      this.state.columns.splice(this.state.columns.length - 1, 1);
    }
  }

  where = {
    pagination: {
      current: 1,
      pageSize: 10,
    }
  };

  whereFormRef = React.createRef();

  formRef = React.createRef();

  componentDidMount() {
    this.fetch();
    this.userFetch();
    this.companyFetch();
  }

  tableChange = (pagination, filters, sorter) => {
    this.where.pagination = pagination
    this.fetch();
  };

  fetch = (params = {}) => {
    this.setState({loading: true});
    axios.post('/feedback/getFeedbacks', this.where)
        .then(response => {
          let data = response.data.data;
          let pagination = data.pagination;
          this.setState({
            loading: false,
            data: data.feedbacks,
            pagination: {
              current: pagination.currentPage,
              pageSize: pagination.perPage,
              total: pagination.totalCount,
            },
          });
        })
        .catch(error => {
          message.error(error.message);
        });
  };

  close = () => {
    this.formRef.current.resetFields();
    this.setState({visible: false});
    this.setState({
      record: {
        user: {},
        company: {},
        replyUser: {},
      }
    });
  };

  drawerReply = values => {
    this.setState({visible: true});
    if (values) {
      let record = JSON.parse(JSON.stringify(values));
      this.setState({editLoading: true});
      setTimeout(() => {
        this.setState({editLoading: false});
        this.setState({record: values});
        this.formRef.current.setFieldsValue(record);
      }, 555);
    }
  };

  drawerSave = values => {
    if (this.state.submitting) {
      return true;
    }
    this.setState({submitting: true, buttonText: '回复中'});
    axios.post('/feedback/replyFeedback', values)
        .finally(() => {
          this.setState({submitting: false, buttonText: '回复'});
        })
        .then(response => {
          message.success('回复成功');
          this.close();
          this.fetch();
        })
        .catch(error => {
          message.error(error.message);
        });
  }

  delete = values => {
    axios.post('/feedback/deleteFeedback', values)
        .then(response => {
          this.fetch();
        })
        .catch(error => {
          message.error(error.message);
        });
  }

  whereChange = values => {
    values = this.whereFormRef.current.getFieldsValue();
    for (let key in values) {
      let value = values[key];
      if (key == 'time' && value) {
        value = value.map(val => parseInt(val.valueOf() / 1000));
      }
      if (key) {
        this.where[key] = value;
      }
      if (key == 'isReply' && value === '') {
        delete this.where[key];
      }
    }
    this.fetch();
  }

  lastUserFetchId = 0;

  userFetch = value => {
    this.lastUserFetchId += 1;
    const fetchId = this.lastUserFetchId;
    this.setState({users: [], userFetching: true});
    axios.post('/user/getUsers', {kw: value})
        .then(response => {
          if (fetchId != this.lastUserFetchId) {
            return;
          }
          let data = response.data.data;
          const users = data.users.map(user => ({
            text: user.phone + (user.name ? (' - ' + user.name) : ''),
            value: user.id,
          }));
          this.setState({
            userFetching: false,
            users: users,
          });
        })
        .catch(error => {
          message.error(error.message);
        });
  };

  lastCompanyFetchId = 0;

  companyFetch = value => {
    if (App.user.isAdmin) {
      this.lastCompanyFetchId += 1;
      const fetchId = this.lastCompanyFetchId;
      this.setState({users: [], userFetching: true});
      axios.post('/company/getCompanys', {kw: value})
          .then(response => {
            if (fetchId != this.lastCompanyFetchId) {
              return;
            }
            let data = response.data.data;
            const companys = data.companys.map(company => ({
              text: company.name,
              value: company.id,
            }));
            this.setState({
              companyFetching: false,
              companys: companys,
            });
          })
          .catch(error => {
            message.error(error.message);
          });
    }
  };

  render() {
    return (
        <Card title="反馈">
          <div className="where">
            <Form ref={this.whereFormRef} onFinish={this.whereChange}>
              <div className="where-container">
                <div className="where-input">
                  <Form.Item name="kw" label="关键字">
                    <Input style={{width: 300}} placeholder="关键字（反馈内容、处理状态）" allowClear={true}/>
                  </Form.Item>
                  <Form.Item name="type" label="类型">
                    <Select defaultValue="" style={{width: 100}} onChange={this.whereChange}>
                      <Select.Option value="">全部类型</Select.Option>
                      {types.map(type => <Select.Option value={type.id}>{type.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  <Form.Item name="userId" label="用户">
                    <Select
                        showSearch
                        value={this.state.user}
                        placeholder="用户"
                        notFoundContent={this.state.userFetching ? <Spin/> : null}
                        filterOption={false}
                        onSearch={this.userFetch}
                        style={{width: 200}}
                    >
                      {this.state.users.map(user => <Select.Option key={user.value}>{user.text}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  {
                    App.user.isAdmin ? (
                        <Form.Item name="companyId" label="单位">
                          <Select
                              showSearch
                              value={this.state.company}
                              placeholder="单位"
                              notFoundContent={this.state.companyFetching ? <Spin/> : null}
                              filterOption={false}
                              onSearch={this.companyFetch}
                              style={{width: 250}}
                          >
                            {this.state.companys.map(company => <Select.Option key={company.value}>{company.text}</Select.Option>)}
                          </Select>
                        </Form.Item>
                    ) : null
                  }
                  <Form.Item name="createTime" label="时间">
                    <DatePicker.RangePicker/>
                  </Form.Item>
                  <Form.Item name="isReply" label="是否回复">
                    <Select defaultValue="" style={{width: 100}} onChange={this.whereChange}>
                      <Select.Option value="">全部</Select.Option>
                      <Select.Option value="0">未回复</Select.Option>
                      <Select.Option value="1">已回复</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="replyTime" label="回复时间">
                    <DatePicker.RangePicker placeholder="起始" onChange={this.whereChange}/>
                  </Form.Item>
                </div>
                <div className="where-operate">
                  <Button type="primary" htmlType="submit" icon={<SearchOutlined/>} loading={this.state.loading}>搜索</Button>
                </div>
              </div>
            </Form>
          </div>
          <Table columns={this.state.columns} rowKey={record => record.id} dataSource={this.state.data} pagination={this.state.pagination} loading={this.state.loading} onChange={this.tableChange}/>
          <Drawer title="app版本" width={555} placement="right" visible={this.state.visible} onClose={this.close}>
            <Spin spinning={this.state.editLoading}>
              <Form ref={this.formRef} initialValues={this.state.default} onFinish={this.drawerSave}>
                <Form.Item name="id" label="id" hidden={true}>
                  <Input placeholder="id"/>
                </Form.Item>
                <Form.Item label="用户姓名">{this.state.record.user.name}</Form.Item>
                <Form.Item label="单位">{this.state.record.company.name}</Form.Item>
                <Form.Item label="反馈内容">{this.state.record.content}</Form.Item>
                <Form.Item label="时间">{this.state.record.time}</Form.Item>
                <Form.Item label="回复状态">{this.state.record.isReply ? '已回复' : '未回复'}</Form.Item>
                {this.state.record.replyTime ? <Form.Item label="回复时间">{moment(this.state.record.replyTime * 1000).format('YYYY-MM-DD HH:mm:ss')}</Form.Item> : ''}
                {this.state.record.replyUser ? <Form.Item label="回复用户">{this.state.record.replyUser.name}</Form.Item> : ''}
                <Form.Item name="replyContent" label="回复内容">
                  <Input.TextArea rows={5} placeholder="回复内容"/>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={this.state.submitting}>{this.state.buttonText}</Button>
                </Form.Item>
              </Form>
            </Spin>
          </Drawer>
        </Card>
    );
  }
}

export default Index;