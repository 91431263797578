import {Button, Card, Drawer, Form, Image, Input, InputNumber, message, Popconfirm, Select, Space, Spin, Switch, Table} from "antd";
import React, {useEffect, useState} from "react";
import axios from "../../conf/axios";
import {imgPlaceholder} from "../../conf/general";
import App from "../../libs/App";

export default () => {
  const [records, setRecords] = useState([]);
  const recordsColumns = [
    {
      title: '图片',
      dataIndex: 'img',
      render: value => {
        return <Image width={75} src={value} fallback={imgPlaceholder}/>
      }
    },
    {
      title: '排序',
      dataIndex: 'sort',
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (value, record) => (
        <Switch defaultChecked={value} disabled={!App.hasAuth('ad.setAd')} onChange={value => {
          record.status = value;
          recordQuickSave({id: record.id, status: value});
        }}/>
      ),
    },
    {
      title: '操作',
      key: 'id',
      width: 200,
      render: (text, record) => (
        <Space>
          {
            App.hasAuth('ad.setAd') ? (
              <Button type="primary" size="small" onClick={() => recordEdit(record)}>编辑</Button>
            ) : null
          }
          {
            App.hasAuth('ad.deleteAd') ? (
              <Popconfirm title="确定删除此单位吗" onConfirm={() => recordDelete(record)}>
                <Button type="primary" size="small">删除</Button>
              </Popconfirm>
            ) : null
          }
        </Space>
      ),
    },
  ];
  if (!App.hasOneAuths('ad.setAd', 'ad.deleteAd')) {
    recordsColumns.splice(recordsColumns.length - 1, 1);
  }

  const [recordsLoading, setRecordsLoading] = useState(false);

  const recordsLoad = () => {
    setRecordsLoading(true);
    axios.post('/ad/getAds')
      .then(response => {
        setRecordsLoading(false);
        setRecords(response.data.data.ads);
      })
      .catch(error => {
        message.error(error.message);
      });
  };
  useEffect(() => {
    recordsLoad();
  }, []);
  const recordDelete = values => {
    axios.post('/ad/deleteAd', values)
      .then(response => {
        message.success('删除成功');
        recordsLoad();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  const [record, setRecord] = useState({});

  const recordQuickSave = values => {
    axios.post('/ad/setAd', values)
      .catch(error => {
        message.error(error.message);
      });
  }

  const [recordEditing, setRecordEditing] = useState(false);
  const [recordLoading, setRecordLoading] = useState(true);
  const [recordSubmitting, setRecordSubmitting] = useState(false);
  const [recordForm] = Form.useForm();

  const recordEdit = record => {
    setRecordEditing(true);
    setRecordLoading(false);
    recordForm.resetFields();
    setRecord(record ? record : {});
    recordForm.setFieldsValue(record ? record : {});
  }

  const recordSave = values => {
    if (recordSubmitting) {
      return;
    }
    setRecordSubmitting(true);
    var formData = new FormData();
    for (let key in values) {
      let value = values[key];
      if (typeof value !== 'undefined' && !(!value && typeof value != "undefined" && value != 0)) {
        formData.append(key, value);
      }
    }
    var img = document.getElementById('recordImgFile').files[0];
    if (img) {
      formData.append('img', img);
    }
    axios.post(values.id ? '/ad/setAd' : '/ad/addAd', formData)
      .finally(() => {
        setRecordSubmitting(false);
      })
      .then(response => {
        message.success('保存成功');
        setRecordEditing(false);
        recordsLoad();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  return (
    <Card title="广告图" extra={App.hasAuth('ad.addAd') ? <Button type="primary" onClick={recordEdit}>添加</Button> : null}>
      <Table columns={recordsColumns} rowKey={record => record.id} loading={recordsLoading} dataSource={records} pagination={false}/>
      <Drawer title="广告图" width={444} placement="right" visible={recordEditing} onClose={() => setRecordEditing(false)}>
        <Spin spinning={recordLoading}/>
        <Form bordered form={recordForm} layout="vertical" onFinish={recordSave}>
          <Form.Item name="id" label="id" hidden={true}>
            <Input placeholder="id"/>
          </Form.Item>
          {
            record.img ? (
              <Form.Item>
                <Image width={75} src={record.img} fallback={imgPlaceholder}/>
              </Form.Item>
            ) : null
          }
          <Form.Item label="图片" name="imgFile" rules={[
            {required: !record.img, message: '请选择图片'},
            {pattern: /.*(png|jpg|jpeg|bmp|gif)/i, message: '只能选择图片文件'},
          ]}>
            <input type="file" id="recordImgFile"/>
          </Form.Item>
          <Form.Item label="类型" name="type" rules={[{required: true}]}>
            <Select style={{width: 200}} placeholder="选择类型">
              <Select.Option value="webview">webview</Select.Option>
              <Select.Option value="productDetails">产品详情</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="位置" name="position" rules={[{required: true}]}>
            <Select style={{width: 200}} placeholder="选择位置">
              <Select.Option value="top">top</Select.Option>
              <Select.Option value="list">list</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="值" name="value" rules={[{required: true}]}>
            <Input placeholder="值"/>
          </Form.Item>
          <Form.Item name="sort" label="排序">
            <InputNumber defaultValue="0"/>
          </Form.Item>
          <Form.Item name="status" label="状态" valuePropName="checked">
            <Switch defaultChecked={true}/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={recordSubmitting}>保存</Button>
          </Form.Item>
        </Form>
      </Drawer>
    </Card>
  );
};