import {AutoComplete, Avatar, Button, Card, DatePicker, Drawer, Form, Input, message, Popconfirm, Select, Skeleton, Space, Table, Tooltip} from "antd";
import moment from "moment";
import React, {useEffect, useState} from "react";
import axios from "../../conf/axios";
import {SearchOutlined} from '@ant-design/icons';
import App from "../../libs/App";

const types = [{
  id: 'coil',
  name: '线圈',
}, {
  id: 'power',
  name: '电源',
}, {
  id: 'probe',
  name: '探头',
}, {
  id: 'other',
  name: '其他',
}];

const status = [{
  id: 'pending',
  name: '待处理',
  style: 'secondary',
}, {
  id: 'processing',
  name: '处理中',
  style: 'primary',
}, {
  id: 'recovered',
  name: '已经修复',
  style: 'success',
}, {
  id: 'failed',
  name: '不予处理',
  style: 'warning',
}];

export default () => {
  const [whereForm] = Form.useForm();
  const [where, setWhere] = useState({
    pagination: {
      pageSize: 10,
      currentPage: 1,
    }
  });
  const whereChange = values => {
    values = whereForm.getFieldsValue();
    for (var i in values) {
      where[i] = values[i];
    }
    where.pagination.currentPage = 1;
    load();
  };

  const [devicesLoading, setDevicesLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    setDevicesLoading(true);
    axios.post('/device/getDevices')
      .finally(() => {
        setModelsLoading(false);
      })
      .then(response => {
        setDevices(response.data.data.devices);
      })
      .catch(error => {
        message.error(error.message);
      });
  }, []);

  const [modelsLoading, setModelsLoading] = useState(false);
  const [models, setModels] = useState([]);
  const [specificats, setSpecificats] = useState([]);
  useEffect(() => {
    setModelsLoading(true);
    axios.post('/deviceModel/getDeviceModels')
      .finally(() => {
        setModelsLoading(false);
      })
      .then(response => {
        setModels(response.data.data.deviceModels);
      })
      .catch(error => {
        message.error(error.message);
      });
  }, []);

  const [companyKwOptions, setCompanyKwOptions] = useState([]);
  const onCompanyKwSearch = value => {
    if (App.user.isAdmin) {
      axios.post('/company/getCompanys', {kw: value})
        .then(response => {
          const companys = response.data.data.companys.map(company => ({
            value: company.name,
          }));
          setCompanyKwOptions(companys);
        })
        .catch(error => {
          message.error(error.message);
        });
    }
  };

  const [userKwOptions, setUserKwOptions] = useState([]);
  const onUserKwSearch = value => {
    axios.post('/user/getUsers', {kw: value})
      .then(response => {
        const users = response.data.data.users.map(user => ({
          value: user.name ? user.name : user.phone,
        }));
        setUserKwOptions(users);
      })
      .catch(error => {
        message.error(error.message);
      });
  };

  const columns = [
    {
      title: '类型',
      dataIndex: 'typeId',
      width: 100,
      render: (value, record) => {
        let type;
        types.forEach(item => {
          if (item.id == value) {
            type = item;
          }
        });
        return type ? type.name : '';
      }
    },
    {
      title: '设备',
      dataIndex: 'device',
      width: 200,
      render: (value, record) => <>
        <Space>
          <Avatar src={record.model?.img} alt={record.model?.name} size={30}/>
          {value?.identifier}
        </Space>
      </>
    },
    {
      title: '型号规格',
      dataIndex: 'specificatId',
      width: 150,
    },
    {
      title: '单位',
      dataIndex: 'company',
      width: 200,
      render: value => value?.name
    },
    {
      title: '用户',
      dataIndex: 'user',
      width: 150,
      render: value => value?.name
    },
    {
      title: '故障描述',
      dataIndex: 'desc'
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 150,
      render: (value, record) => {
        let state;
        status.forEach(item => {
          if (item.id == value) {
            state = item;
          }
        });
        return state ? (
          <Tooltip title={record.explain}>
            <div className={'badge badge-' + state.style}>{state.name}</div>
          </Tooltip>
        ) : '';
      }
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      width: 200,
      render: value => {
        return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '';
      }
    },
    {
      title: '操作',
      key: 'id',
      width: 225,
      render: (text, record) => (
        <Space>
          {
            App.hasAuth('deviceFault.setDeviceFault') ? (
              <Button type="primary" size="small" onClick={() => operate(record, true)}>处理</Button>
            ) : null
          }
          <Button type="primary" size="small" onClick={() => operate(record)}>查看</Button>
          {
            App.hasAuth('deviceFault.deleteDeviceFault') ? (
              <Popconfirm title="确定删除此故障吗" onConfirm={() => remove(record)}>
                <Button type="primary" size="small">删除</Button>
              </Popconfirm>
            ) : null
          }
        </Space>
      ),
    },
  ];
  if (!App.user.isAdmin) {
    columns.splice(2, 1);
  }

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [pagination, setPagination] = useState([]);
  const tableChange = (pagination, filters, sorter) => {
    where.pagination = {
      pageSize: pagination.pageSize,
      currentPage: pagination.current,
    }
    load();
  };

  const load = () => {
    setLoading(true);
    axios.post('/deviceFault/getDeviceFaults', where)
      .then(response => {
        let data = response.data.data;
        let pagination = data.pagination;
        setLoading(false);
        setDatas(data.deviceFaults);
        setPagination({
          current: pagination.currentPage,
          pageSize: pagination.perPage,
          total: pagination.totalCount,
        });
      })
      .catch(error => {
        message.error(error.message);
      });
  };
  useEffect(() => {
    load();
  }, []);

  const remove = values => {
    axios.post('/deviceFault/deleteDeviceFault', values)
      .then(response => {
        message.success('删除成功');
        load();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  const [adding, setAdding] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [addForm] = Form.useForm();
  const add = () => {
    setAdding(true);
    addForm.resetFields();
  }
  const [saving, setSaving] = useState(false);
  const save = values => {
    setSaving(true);
    axios.post('/deviceFault/addDeviceFault', values)
      .finally(() => {
        setSaving(false);
      })
      .then(response => {
        message.success('保存成功');
        setAdding(false);
        load();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  const [record, setRecord] = useState({});
  const [operateing, setOperateing] = useState(false);
  const [operateHandle, setOperateHandle] = useState(false);
  const [operateForm] = Form.useForm();
  const operate = (record, handle) => {
    setRecord(record);
    setOperateHandle(handle);
    setOperateing(true);
    operateForm.resetFields();
    operateForm.setFieldsValue(record);
  }
  const [handleing, setHandleing] = useState(false);
  const handle = values => {
    setHandleing(true);
    axios.post('/deviceFault/setDeviceFault', values)
      .finally(() => {
        setHandleing(false);
      })
      .then(response => {
        message.success('删除成功');
        setOperateing(false);
        load();
      })
      .catch(error => {
        message.error(error.message);
      });
  }

  return (
    <Card title="设备故障" extra={App.hasAuth('deviceFault.addDeviceFault') ? <Button type="primary" onClick={add}>提交故障</Button> : null}>
      <div className="deviceFault">
        <div className="where">
          <Form form={whereForm} onFinish={whereChange}>
            <div className="where-container">
              <div className="where-input">
                <Form.Item name="kw" label="关键字">
                  <Input style={{width: 300}} placeholder="关键字（故障描述、处理说明）"/>
                </Form.Item>
                <Form.Item name="type" label="类型">
                  <Select style={{width: 100}} placeholder="类型" onChange={whereChange}>
                    <Select.Option value="">全部类型</Select.Option>
                    {types.map(type => <Select.Option value={type.id}>{type.name}</Select.Option>)}
                  </Select>
                </Form.Item>
                <Form.Item name="modelId" label="型号">
                  <Select style={{width: 150}} defaultValue="" placeholder="型号" loading={modelsLoading} onChange={(value, option) => {
                    if (option.key) {
                      let model = models[option.key - 1];
                      setSpecificats(model.specificats);
                    }
                  }}>
                    <Select.Option value="">全部型号</Select.Option>
                    {models.map((value, index) => <Select.Option value={value.id} key={index + 1}>{value.name}</Select.Option>)}
                  </Select>
                </Form.Item>
                <Form.Item name="specificatId" label="规格">
                  <Select style={{width: 125}} defaultValue="" placeholder="规格" loading={modelsLoading} onChange={whereChange}>
                    <Select.Option value="">全部规格</Select.Option>
                    {specificats.map(value => <Select.Option value={value.id}>{value.name}</Select.Option>)}
                  </Select>
                </Form.Item>
                {
                  App.user.isAdmin ? (
                      <Form.Item name="companyKw" label="单位">
                        <AutoComplete
                            options={companyKwOptions}
                            style={{width: 225}}
                            onSearch={onCompanyKwSearch}
                            placeholder="单位"
                            allowClear={true}
                            onSelect={whereChange}
                        />
                      </Form.Item>
                  ) : null
                }
                <Form.Item name="userKw" label="用户">
                  <AutoComplete
                      options={userKwOptions}
                      style={{width: 125}}
                      onSearch={onUserKwSearch}
                      placeholder="用户"
                      allowClear={true}
                      onSelect={whereChange}
                  />
                </Form.Item>
                <Form.Item name="status" label="状态">
                  <Select style={{width: 100}} placeholder="状态" onChange={whereChange}>
                    <Select.Option value="">全部状态</Select.Option>
                    {status.map(value => <Select.Option value={value.id}>{value.name}</Select.Option>)}
                  </Select>
                </Form.Item>
                <Form.Item name="createTime" label="创建时间">
                  <DatePicker.RangePicker placeholder="起始" onChange={whereChange}/>
                </Form.Item>
                <Form.Item name="handleTime" label="处理时间">
                  <DatePicker.RangePicker placeholder="起始" onChange={whereChange}/>
                </Form.Item>
              </div>
              <div className="where-operate">
                <Button type="primary" htmlType="submit" icon={<SearchOutlined/>} loading={loading}>搜索</Button>
              </div>
            </div>
          </Form>
        </div>
        <Table columns={columns} dataSource={datas} pagination={pagination} loading={loading} onChange={tableChange}/>
        <Drawer title="提交故障" width={444} visible={adding} onClose={() => setAdding(false)}>
          {
            addLoading ? <Skeleton active/> : (
              <Form form={addForm} layout="vertical" onFinish={save}>
                <Form.Item name="typeId" label="故障类型" rules={[{required: true}]}>
                  <Select placeholder="故障类型">
                    {types.map(type => <Select.Option value={type.id}>{type.name}</Select.Option>)}
                  </Select>
                </Form.Item>
                <Form.Item name="deviceId" label="故障设备" rules={[{required: true}]}>
                  <Select placeholder="故障设备">
                    {devices.map(device => <Select.Option value={device.id}>
                      {device.identifier}{device.name ? (' - ' + device.name) : ''}
                    </Select.Option>)}
                  </Select>
                </Form.Item>
                <Form.Item name="desc" label="故障描述" rules={[{required: true}]}>
                  <Input.TextArea rows={5} allowClear={true} placeholder="故障描述"/>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={saving}>提交</Button>
                </Form.Item>
              </Form>
            )
          }
        </Drawer>
        <Drawer title={(operateHandle ? '处理' : '查看') + '故障'} width={555} visible={operateing} onClose={() => setOperateing(false)}>
          <div className="deviceFaultDetail">
            {
              addLoading ? <Skeleton active/> : (
                <Form form={operateForm} layout="vertical" className="ant-form-table" onFinish={handle}>
                  <Form.Item name="id" label="id" hidden={true}>
                    <Input placeholder="id"/>
                  </Form.Item>
                  <table style={{width: '100%'}}>
                    <tr>
                      <td width={85}>类型</td>
                      <td>{(() => types.find(type => type.id == record.typeId)?.name)()}</td>
                    </tr>
                    <tr>
                      <td width={85}>设备</td>
                      <td>{record.device ? record.device.identifier : ''}</td>
                    </tr>
                    <tr>
                      <td>单位</td>
                      <td>{record.company ? record.company.name : ''}</td>
                    </tr>
                    <tr>
                      <td>提交时间</td>
                      <td>
                        {record.createTime ? moment(record.createTime * 1000).format('YYYY-MM-DD HH:mm:ss') : ''}
                      </td>
                    </tr>
                    <tr>
                      <td>提交用户</td>
                      <td>
                        {record.user ? record.user.name : ''}
                      </td>
                    </tr>
                    <tr>
                      <td>故障描述</td>
                      <td>{record.desc}</td>
                    </tr>
                    {
                      operateHandle ? (
                        <>
                          <tr>
                            <td>处理状态</td>
                            <td>
                              <Form.Item name="status" rules={[{required: true}]}>
                                <Select style={{width: 150}} placeholder="处理状态">
                                  {
                                    status.map(value => {
                                      return <Select.Option value={value.id}>{value.name}</Select.Option>;
                                    })
                                  }
                                </Select>
                              </Form.Item>
                            </td>
                          </tr>
                          <tr>
                            <td>处理说明</td>
                            <td>
                              <Form.Item name="explain" rules={[{required: true}]}>
                                <Input.TextArea rows={5} allowClear={true} placeholder="处理说明"/>
                              </Form.Item>
                            </td>
                          </tr>
                          <tr>
                            <td style={{textAlign: "center"}} colSpan={2}>
                              <Space>
                                <Button type="primary" htmlType="submit" loading={handleing}>保存</Button>
                                <Button onClick={() => operate(record)}>查看</Button>
                              </Space>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          <tr>
                            <td>处理状态</td>
                            <td>{record.status}</td>
                          </tr>
                          <tr>
                            <td>处理说明</td>
                            <td>{record.explain}</td>
                          </tr>
                          <tr>
                            <td>处理时间</td>
                            <td>
                              {record.handleTime ? moment(record.handleTime * 1000).format('YYYY-MM-DD HH:mm:ss') : ''}
                            </td>
                          </tr>
                          <tr>
                            <td>处理用户</td>
                            <td>
                              {record.handleUser ? record.handleUser.name : ''}
                            </td>
                          </tr>
                          <tr>
                            <td style={{textAlign: "center"}} colSpan={2}>
                              <Button loading={handleing} onClick={() => operate(record, true)}>处理</Button>
                            </td>
                          </tr>
                        </>
                      )
                    }
                  </table>
                </Form>
              )
            }
          </div>
        </Drawer>
      </div>
    </Card>
  );
};