import React, {useEffect, useState} from "react";
import {Button, List, message, Modal, Spin} from "antd";
import axios from "../../conf/axios";

export default props => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    if (props.visible === true && datas.length === 0) {
      setLoading(true);
      axios.post('/projectScheme/getProjectSchemes')
        .then(response => {
          setLoading(false);
          setDatas(response.data.data.projectSchemes);
        })
        .catch(error => {
          message.error(error.message);
        });
    } else {
      setLoading(false);
    }
  }, [props.visible]);

  const onSelect = record => {
    if (saving) {
      return;
    }
    Modal.confirm({
      title: '确认选择吗',
      onOk() {
        setSaving(true);
        axios.post('/project/setInspect', {id: props.data.id, schemeId: record.id})
          .finally(() => {
            setSaving(false);
          })
          .then(response => {
            message.success('保存成功');
            props.onCancel();
            props.onSelect(record);
          })
          .catch(error => {
            message.error(error.message);
          });
      }
    });
  }

  return (
    <Modal
      title="选择方案"
      visible={props.visible}
      centered
      width={777}
      onCancel={props.onCancel}
      maskClosable={false}
      footer={null}
    >
      {
        loading ? (
          <div style={{textAlign: 'center'}}>
            <Spin size="large"/>
          </div>
        ) : (
          <List
            dataSource={datas}
            renderItem={item => (
              <div>
                <List.Item style={{display: 'flex'}}>
                  <a href={item.file} target="_blank" style={{flex: 1, textAlign: 'left'}}>{item.name}</a>
                  <Button type="primary" onClick={() => onSelect(item)} loading={saving}>选择</Button>
                </List.Item>
              </div>
            )}
          />
        )
      }
    </Modal>
  );
};